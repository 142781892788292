import { memo, useCallback, useMemo } from 'react';
import { AnnotationContextGroup } from '../../../contexts/AnnotationContext';
import { T } from '../../../translation/src';
import { PropertiesSection, Property } from './PropertiesSection';
import { HideLabelProperty } from './HideLabel';
import { EditableColorProperty } from './ColorProperties';
import { ClipMethods, AnnotationLabels, useProjectUpdateClippingMethodMutation } from '../../../types/graphqlTypes';
import { ClipMethodProperty } from './ClipMethodProperties';
import { SelectProps } from '../../../components/inputs/Select';

interface GroupPropertiesProps {
  projectId: string;
  annotation: AnnotationContextGroup;
}
const $GroupProperties: React.FC2<GroupPropertiesProps> = ({ projectId, annotation }) => {
  const pointAnnotationsInGroup = annotation.annotations.filter((annotation) => annotation.type === 'POINT');
  const distanceAnnotationsInGroup = annotation.annotations.filter((annotation) => annotation.type === 'DISTANCE');
  const areaAnnotationsInGroup = annotation.annotations.filter((annotation) => annotation.type === 'AREA');
  const boxAnnotationsInGroup = annotation.annotations.filter((annotation) => annotation.type === 'BOX');

  // Can expand this to more annotation types later
  const filterAnnotationsInGroup = boxAnnotationsInGroup;
  const filterAnnotationIdentifiers = filterAnnotationsInGroup.map((annotation) => annotation.identifier);
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();

  const getClipMethod = () => {
    if (filterAnnotationsInGroup.length === 0) return undefined;

    const firstFilter =
      filterAnnotationsInGroup[0].__typename === 'BoxAnnotation'
        ? filterAnnotationsInGroup[0].annotationFilter?.clipMethod
        : undefined;

    const sameFilter = filterAnnotationsInGroup.every(
      (annotation) =>
        annotation.__typename === 'BoxAnnotation' && annotation.annotationFilter?.clipMethod === firstFilter
    );
    return sameFilter ? firstFilter : undefined;
  };
  const clipMethod = getClipMethod();

  const onChangeClippingMethod: SelectProps<ClipMethods>['onChange'] = useCallback(
    (event) => {
      updateClippingMethod({
        variables: {
          projectId,
          annotationIdentifiers: filterAnnotationIdentifiers,
          clipMethod: event.target.value,
        },
      });
    },
    [filterAnnotationIdentifiers, projectId, updateClippingMethod]
  );

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="group" swc />
        </Property>
        <Property title={<T _str="annotations" swc />}>{annotation.annotations.length}</Property>
      </PropertiesSection>
      {annotation.annotations.length > 0 && (
        <PropertiesSection title={<T _str="appearance" swc />}>
          {pointAnnotationsInGroup.length > 0 && (
            <HideLabelProperty annotations={pointAnnotationsInGroup} label={AnnotationLabels.Coordinate} />
          )}
          {(distanceAnnotationsInGroup.length > 0 || areaAnnotationsInGroup.length > 0) && (
            <HideLabelProperty
              annotations={[...distanceAnnotationsInGroup, ...areaAnnotationsInGroup]}
              label={AnnotationLabels.Length}
            />
          )}
          {areaAnnotationsInGroup.length > 0 && (
            <>
              <HideLabelProperty annotations={areaAnnotationsInGroup} label={AnnotationLabels.Area} />
              <HideLabelProperty annotations={areaAnnotationsInGroup} label={AnnotationLabels.Volume} />
            </>
          )}
          <EditableColorProperty annotations={annotation.annotations} />
        </PropertiesSection>
      )}
      {filterAnnotationsInGroup.length > 0 && (
        <PropertiesSection title={<T _str="filters" swc />}>
          <ClipMethodProperty onChange={onChangeClippingMethod} clipMethod={clipMethod} />
        </PropertiesSection>
      )}
    </div>
  );
};

export const GroupProperties = memo($GroupProperties);
