import moment from 'moment';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useT } from '../../../translation/src';
import { useBillingInvoicesQuery } from '../../../types/graphqlTypes';
import { useDeviceSize } from '../../../hooks/useDeviceSize';

interface OrderRowProps {
  date: Date;
  description: string;
  price: { currency: string; value: number };
  url?: string | null;
}
const OrderRow: React.FC2<OrderRowProps> = ({ date, description, price, url }) => {
  const translations = useTranslations();
  return (
    <div className={'flex items-center w-full p-3 dark:bg-[#242424]'}>
      <div className="flex w-36 ">{moment(date).format('DD/MM/YYYY')}</div>
      <div className="flex text-gray-500 dark:text-gray-400 w-72">{description}</div>
      <div className="flex flex-grow text-gray-500 dark:text-gray-400">
        {price.value} {price.currency}
      </div>
      {url ? (
        <a className="font-bold text-french-violet-500 hover:text-french-violet-400 dark:text-neon-green-500 dark:hover:text-neon-green-400" href={url} target="_blank" rel="noreferrer">
          {translations.viewReceipt}
        </a>
      ) : null}
    </div>
  );
};

const useTranslations = () => {
  const billingHistory = useT('billing history', { swc: true });
  const date = useT('date', { swc: true });
  const description = useT('description', { swc: true });
  const amount = useT('amount', { swc: true });
  const viewReceipt = useT('view receipt', { swc: true });
  return { billingHistory, description, amount, date, viewReceipt };
};

const $BillingHistory: React.FC2 = () => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const { data: billingInvoicesData } = useBillingInvoicesQuery({ variables: { organisationId } });
  const invoices = billingInvoicesData?.billingInvoices || [];
  const { xsDevice } = useDeviceSize();

  return (
    <div className="flex flex-col overflow-auto border rounded shadow">
      <div className={'flex items-center w-full p-3 bg-gray-50 font-bold border-b border-gray-300 dark:bg-[#242424]'}>
        <div className="flex w-36 ">{translations.date}</div>
        { !xsDevice &&
          <div className="flex w-72">{translations.description}</div>
        }
        <div className="flex flex-grow">{translations.amount}</div>
        <div className="flex w-40"></div>
      </div>
      <div className="flex flex-col overflow-auto">
        {invoices.map((invoice, index) => (
          <OrderRow key={index} {...invoice} />
        ))}
      </div>
    </div>
  );
};

export const BillingHistory = memo($BillingHistory);
