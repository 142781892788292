import { useEffect, useState } from 'react';
import { CONTEXT_MENU_TRANSITION_LEAVE_TIME } from '../../../components/ContextMenu';
import { useCustomSelector } from '../../../redux/store';

export const useIsMultiSelect = () => {
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);
  const [isMultiSelectState, setIsMultiSelect] = useState(selectedAnnotations.length > 1);

  useEffect(() => {
    const isMultiSelect = selectedAnnotations.length > 1;
    // This prevents flickering of the context menu when closing
    if (isMultiSelectState !== isMultiSelect)
      setTimeout(() => {
        setIsMultiSelect(isMultiSelect);
      }, CONTEXT_MENU_TRANSITION_LEAVE_TIME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotations.length]);

  return isMultiSelectState;
};
