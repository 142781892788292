import { memo, useMemo } from 'react';
import { Popover } from '.';
import { Color } from '../../types/graphqlTypes';

interface ColorPopoverProps {
  value: Color;
  onChange: (value: Color) => void;
}
const $ColorPopover: React.FC2<ColorPopoverProps> = ({ value, onChange }) => {
  const buttonChild = useMemo(() => {
    return (
      <div className="w-5 h-5 overflow-hidden border border-gray-500 rounded cursor-pointer"
        style={{backgroundColor: `rgba(${value.r},${value.g},${value.b},${value.a})`,}}/>
    );
  }, [value]);

  const colors = [
    {r: 50, g: 0, b: 0, a: 1}, {r: 100, g: 0, b: 0, a: 1}, {r: 150, g: 0, b: 0, a: 1}, {r: 200, g: 0, b: 0, a: 1}, 
    {r: 250, g: 0, b: 0, a: 1}, {r: 250, g: 50, b: 0, a: 1}, {r: 250, g: 100, b: 0, a: 1}, {r: 250, g: 150, b: 0, a: 1}, 
    {r: 250, g: 200, b: 0, a: 1}, {r: 250, g: 250, b: 0, a: 1}, {r: 200, g: 250, b: 0, a: 1}, {r: 150, g: 250, b: 0, a: 1},
    {r: 100, g: 250, b: 0, a: 1}, {r: 50, g: 250, b: 0, a: 1}, {r: 0, g: 250, b: 0, a: 1}, {r: 0, g: 250, b: 50, a: 1}, 
    {r: 0, g: 250, b: 100, a: 1}, {r: 0, g: 250, b: 150, a: 1}, {r: 0, g: 250, b: 200, a: 1}, {r: 0, g: 250, b: 250, a: 1}, 
    {r: 0, g: 200, b: 250, a: 1}, {r: 0, g: 150, b: 250, a: 1}, {r: 0, g: 100, b: 250, a: 1}, {r: 0, g: 50, b: 250, a: 1},
    {r: 0, g: 0, b: 250, a: 1}, {r: 0, g: 0, b: 200, a: 1}, {r: 0, g: 0, b: 150, a: 1}, {r: 0, g: 0, b: 100, a: 1}, 
    {r: 0, g: 0, b: 50, a: 1}, {r: 0, g: 0, b: 0, a: 1}
  ];

  return (
    <Popover buttonChild={buttonChild} panelClassName="w-auto">
      {({ open, close }) => (
        <div className="grid w-32 grid-cols-4 gap-2">
          {colors.map((color) => {
            return (
              <div
                key={`${color.r}-${color.g}-${color.b}`}
                className="w-6 h-6 overflow-hidden border border-gray-500 rounded cursor-pointer"
                onClick={() => {
                  close();
                  onChange(color);
                }}
              >
                <svg width="1.5rem" height="1.5rem">
                  <rect width="100%" height="100%" fill={`rgb(${color.r}, ${color.g}, ${color.b})`}></rect>
                </svg>
              </div>
            );
          })}
        </div>
      )}
    </Popover>
  );
};

export const ColorPopover = memo($ColorPopover);
