import { useApolloClient } from '@apollo/client';
import { FormikConfig } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { InfoContent } from '../../../components/InfoContent';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { T, useT } from '../../../translation/src';
import {
  User,
  UsersDocument,
  useProjectAddGuestMutation,
  useProjectRemoveGuestMutation,
  useProjectsQuery,
} from '../../../types/graphqlTypes';

const useTranslations = () => {
  const manageAccess = useT('manage access', { swc: true });
  const projects = useT('projects', { swc: true });
  return { manageAccess, projects };
};

interface FormValues {
  projectIds: string[];
}

interface ProjectAccessModalProps {
  onClose: () => void;
  open?: boolean;
  user?: Pick<User, 'id' | 'email' | 'allowedProjectIds'>;
}
const $ProjectAccessModal: React.FC2<ProjectAccessModalProps> = ({ onClose, open, user }) => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const { value: isSubmitting, setValue: setIsSubmitting } = useBoolean(false);
  const [addProjectGuest] = useProjectAddGuestMutation();
  const [removeProjectGuest] = useProjectRemoveGuestMutation();
  const apolloClient = useApolloClient();
  const { data: projectsData } = useProjectsQuery({ variables: { organisationId } });
  const projects = projectsData?.projectsByOrganisationId || [];

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values) => {
      if (!user) return;
      setIsSubmitting(true);
      const removedProjectIds = user.allowedProjectIds.filter((id) => !values.projectIds.includes(id));
      const addedProjectIds = values.projectIds.filter((id) => !user.allowedProjectIds.includes(id));
      await Promise.all([
        ...removedProjectIds.map(async (projectId) => {
          await removeProjectGuest({ variables: { projectId, userId: user.id } });
        }),
        ...addedProjectIds.map(async (projectId) => {
          await addProjectGuest({ variables: { projectId, email: user.email } });
        }),
      ]);
      await apolloClient.refetchQueries({ include: [UsersDocument] });
      setIsSubmitting(false);
      onClose();
    },
    [addProjectGuest, apolloClient, onClose, removeProjectGuest, setIsSubmitting, user]
  );

  const formik: FormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: { projectIds: user?.allowedProjectIds || [] },
      onSubmit,
    };
  }, [onSubmit, user?.allowedProjectIds]);

  if (!user) return null;

  return (
    <CreateModal<FormValues>
      open={open}
      onClose={onClose}
      title={translations.manageAccess}
      formik={formik}
      isSubmitting={isSubmitting}
    >
      <InfoContent type="info" title={<T _str="Add or remove projects for this guest" />} className="mb-6" />
      <FormikSelect name="projectIds" multiple label={translations.projects}>
        {projects.map((project) => (
          <Option key={project.id} value={project.id}>
            {project.name}
          </Option>
        ))}
      </FormikSelect>
    </CreateModal>
  );
};

export const ProjectAccessModal = memo($ProjectAccessModal);
