export interface RGB {
  r: number;
  g: number;
  b: number;
}

export function stringToColor(str: string): RGB {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to an RGB color
  const r = (hash >> 16) & 0xff;
  const g = (hash >> 8) & 0xff;
  const b = hash & 0xff;

  return { r, g, b };
}
