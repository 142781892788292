import { T } from '../../../translation/src';
import { useProjectAddCustomClassMutation } from '../../../types/graphqlTypes';
import { memo, useCallback, useMemo } from 'react';
import { Property } from './PropertiesSection';
import { useClassificationTranslations } from '../../../hooks/translations/useClassifications';
import { IClassification, useClassifications } from '../../../hooks/potree/useRenderer';
import { ComboBox, Divider, Option } from '../../../components/inputs/ComboBox';
import { stringToColor } from '../../../utils/stringToColor';
import { CogIcon } from '@heroicons/react/24/outline';
import useOpen from '../../../hooks/useOpen';
import { ClassificationSettingsModal } from '../../user/ClassificationSettingsModal';

export const MINIMUM_CLASS_INDEX = 64; // Lower indices are reserved in las/laz

interface ClassPropertyComponentProps {
  label: string;
  value: IClassification;
  onEdit: (value: IClassification) => void;
}
const $ClassPropertyComponent: React.FC2<ClassPropertyComponentProps> = ({ label, value, onEdit }) => {
  const isCustomClass = value.custom;

  return (
    <div className="flex items-center w-full overflow-hidden">
      <Option key={value.code} value={value} label={value.name} className="flex flex-grow">
        <div className="flex items-center w-full">
          <div
            className="flex-shrink-0 w-4 h-4 mr-1 border border-gray-500 rounded"
            style={{
              backgroundColor: `rgb(${value.color.map((c: number) => c * 255).join(', ')})`,
            }}
          />
          <div className="truncate">{label}</div>
        </div>
      </Option>
      {isCustomClass && (
        <div className="flex-shrink-0 m-2">
          <CogIcon
            className="w-4 h-4 text-[#6F7173] hover:text-black dark:hover:text-neon-green-300"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(value);
            }}
          />
        </div>
      )}
    </div>
  );
};
const ClassPropertyComponent = memo($ClassPropertyComponent);

type classificationPropertyProps = {
  classification: number;
  onChange: (event: { target: { value: IClassification; name: string } }) => void;
  projectId: string;
};

const $ClassifyProperty: React.FC<classificationPropertyProps> = ({ classification, onChange, projectId }) => {
  const comboBoxName = 'filter';

  const [classifications] = useClassifications();
  const [addCustomClass] = useProjectAddCustomClassMutation();
  const classificationTranslations = useClassificationTranslations();

  const {
    onOpenWithValue: openClassificationSettingsModal,
    onClose: closeClassificationSettingsModal,
    open: ClassificationSettingsModalOpen,
    value: customClassification,
  } = useOpen<IClassification>();

  const { classificationOptions, customClassificationOptions } = useMemo(() => {
    const classificationOptions = classifications.classifications.filter((c) => !c.custom);
    const customClassificationOptions = classifications.classifications.filter((c) => c.custom);
    return { classificationOptions, customClassificationOptions };
  }, [classifications]);

  const allClassificationOptions = useMemo(() => {
    return [...classificationOptions, ...customClassificationOptions];
  }, [classificationOptions, customClassificationOptions]);

  const currentValue = allClassificationOptions.find((option) => Number(option.code) === classification);

  const onCustomClass = useCallback(
    async (customClassName: string) => {
      const customClassResponse = await addCustomClass({
        variables: {
          projectId: projectId,
          color: { ...stringToColor(customClassName), a: 1 },
          name: customClassName,
        },
      });
      const customClass = customClassResponse.data?.projectAddCustomClass.customClasses?.find(
        (customClass) => customClass.name === customClassName
      );
      if (!customClass) return;
      onChange({
        target: {
          name: comboBoxName,
          value: {
            visible: true,
            name: customClassName,
            // @ts-ignore
            color: { ...customClass?.color, a: 1 },
            code: customClass?.code,
          },
        },
      });
    },
    [addCustomClass, projectId, onChange]
  );

  return (
    <Property title={<T _str="Classification" swc />}>
      <ComboBox<IClassification>
        className="w-full"
        onChange={onChange}
        value={currentValue}
        name={comboBoxName}
        onCustomValue={onCustomClass}
      >
        {classificationOptions.map((value) => (
          <ClassPropertyComponent
            label={classificationTranslations[value.name]}
            value={value}
            onEdit={openClassificationSettingsModal}
          />
        ))}
        {customClassificationOptions.length > 0 && <Divider />}
        {customClassificationOptions.map((customClass) => (
          <ClassPropertyComponent
            label={customClass.name}
            value={customClass}
            onEdit={openClassificationSettingsModal}
          />
        ))}
      </ComboBox>
      {customClassification && (
        <ClassificationSettingsModal
          onClose={closeClassificationSettingsModal}
          open={ClassificationSettingsModalOpen}
          projectId={projectId}
          value={customClassification}
        />
      )}
    </Property>
  );
};
export const ClassifyProperty = memo($ClassifyProperty);
