import { CadObject, CadObjectGroup, DeleteCadItemMultiActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { deleteCadObject, undoDeleteCadObject } from './DeleteCadObjectAction';
import { deleteCadObjectGroup, undoDeleteCadObjectGroup } from './DeleteCadObjectGroupAction';

export class DeleteCadItemMultiAction implements IAction<DeleteCadItemMultiActionValue> {
  _removedCadObjects: CadObject[] = [];
  _removedCadObjectGroups: CadObjectGroup[] = [];
  public identifier: string;
  constructor(public value: DeleteCadItemMultiActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    this.value.cadObjectIdentifiers.forEach((cadItemIdentifier) => {
      const removedCadObject = deleteCadObject({ cadObjectIdentifier: cadItemIdentifier }, { state });
      if (removedCadObject) {
        this._removedCadObjects.push(removedCadObject);
      } else {
        const removedCadObjectGroup = deleteCadObjectGroup({ cadObjectGroupIdentifier: cadItemIdentifier }, { state });
        if (removedCadObjectGroup) this._removedCadObjectGroups.push(removedCadObjectGroup);
      }
    });
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedCadObjectGroups && !this._removedCadObjects) return;
    this._removedCadObjectGroups.forEach((removedCadObjectGroup) => {
      undoDeleteCadObjectGroup({ removedCadObjectGroup: removedCadObjectGroup }, { state });
    });
    this._removedCadObjects.forEach((removedCadObject) => {
      undoDeleteCadObject({ removedCadObject: removedCadObject }, { state });
    });
    this._removedCadObjects = [];
    this._removedCadObjectGroups = [];
  }
}
