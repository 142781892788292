import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSelectedCadItem } from '../../redux/rendererReducer';

type onClickCadObjectFn = (
  args1: { identifier: string; type: 'cadLayer' | 'cadObjectGroup' | 'cadObject' },
  args2: { isCtrl?: boolean; isShift?: boolean }
) => void;

export const useSelectCadItem = () => {
  const dispatch = useDispatch();

  const onClickCadItem: onClickCadObjectFn = useCallback(
    (selectedCadItem) => dispatch(toggleSelectedCadItem({ ...selectedCadItem })),
    [dispatch]
  );
  return { onClickCadItem };
};
