import classNames from 'classnames';
import React, { HTMLAttributes, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NOTIFICATIONS } from '../../../graphql/notification';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { Tabs } from '../../../modules/renderer/RendererRightPanel';
import { T } from '../../../translation/src';
import {
  GeneralStatus,
  PointCloudCalculationDoneNotification as PointCloudCalculationDoneNotificationType,
  PointCloudConvertDoneNotification as PointCloudConvertDoneNotificationType,
  PointCloudExportDoneNotification,
  UploadStatus,
  UserAcceptedInvitationNotification as UserAcceptedInvitationNotificationType,
  useNotificationsMarkAsReadMutation,
  useNotificationsQuery,
} from '../../../types/graphqlTypes';
import { createURLParams } from '../../../utils/URLSearchParams';
import { Modal } from '../../../components/Modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Checkmark from '../../../assets/icons/checkmark.svg?react';
import { Button } from '../../../components/Button';
import { useDeviceSize } from '../../../hooks/useDeviceSize';

export interface NotificationsModalProps {
  onClose: () => void;
  open?: boolean;
}

const useReadNotification = () => {
  const [readNotification] = useNotificationsMarkAsReadMutation();
  const onReadNotification = useCallback(
    ({ notification }: { notification: { id: string; __typename?: string } }) => {
      readNotification({
        variables: { ids: [notification.id] },
        update: (cache) => {
          cache.modify({
            id: cache.identify(notification),
            fields: {
              isRead() {
                return true;
              },
            },
          });
        },
      });
    },
    [readNotification]
  );
  return onReadNotification;
};

const NotificationDate: React.FC<{ date: Date }> = ({ date }) => {
  const { formatDateAndTime } = useFormatDate();
  return <span className="text-xs">{formatDateAndTime(date)}</span>;
};

interface NotificationContainerProps extends React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
  isRead?: boolean;
}
const NotificationContainer: React.FC<NotificationContainerProps> = ({ children, isRead, ...props }) => {
  return (
    <div
      className={classNames(
        'w-full cursor-pointer flex flex-row py-4 px-4 justify-between items-center hover:bg-neon-green-300 hover:dark:bg-black relative',
        !isRead && 'dark:bg-purple-700'
      )}
      {...props}
    >
      <div className="flex flex-col">{children}</div>
    </div>
  );
};

const $PointCloudCalculationDoneNotification: React.FC<{
  notification: PointCloudCalculationDoneNotificationType;
}> = ({ notification }) => {
  const readNotification = useReadNotification();
  const navigate = useNavigate();

  const link = `/organisations/${notification.organisationId}/projects/${notification.meta.projectId}${
    !notification.meta.annotationIdentifier
      ? ''
      : '?' +
        createURLParams({
          params: {
            rightPanelTab: Tabs.CALCULATIONS,
            annotationIdentifier: notification.meta.annotationIdentifier,
          },
        })
  }`;

  const onClick = useCallback(() => {
    readNotification({ notification });
    if (link) {
      navigate(link);
    }
  }, [readNotification, notification, link, navigate]);

  const status = notification.meta.status;

  return (
    <NotificationContainer onClick={onClick} isRead={!!notification.isRead}>
      <span>
        <T _str="pointcloud calculation on" /> <span className="font-bold">{notification.meta.projectName}</span>{' '}
        {status && status === GeneralStatus.Success ? <T _str="succeeded" /> : <T _str="failed" />}
      </span>
      <NotificationDate date={notification.createdAt} />
    </NotificationContainer>
  );
};
const PointCloudCalculationDoneNotification = memo($PointCloudCalculationDoneNotification);

const $PointCloudConvertDoneNotification: React.FC<{
  notification: PointCloudConvertDoneNotificationType & {
    meta: PointCloudConvertDoneNotificationType['meta'] & { uploadStatus?: UploadStatus | null };
  };
}> = ({ notification }) => {
  const readNotification = useReadNotification();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    readNotification({ notification });
    navigate(`/organisations/${notification.organisationId}/projects/${notification.meta.projectId}`);
  }, [readNotification, notification, navigate]);

  return (
    <NotificationContainer onClick={onClick} isRead={!!notification.isRead}>
      <span>
        <T _str="pointcloud convert on" /> <span className="font-bold">{notification.meta.projectName}</span>{' '}
        {notification.meta.uploadStatus === UploadStatus.Success ? <T _str="succeeded" /> : <T _str="failed" />}
      </span>
      <NotificationDate date={notification.createdAt} />
    </NotificationContainer>
  );
};
const PointCloudConvertDoneNotification = memo($PointCloudConvertDoneNotification);

const $UserAcceptedInvitationNotification: React.FC<{
  notification: UserAcceptedInvitationNotificationType;
}> = ({ notification }) => {
  const readNotification = useReadNotification();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    readNotification({ notification });
    navigate(`/organisations/${notification.organisationId}/users`);
  }, [readNotification, notification, navigate]);

  return (
    <NotificationContainer onClick={onClick} isRead={!!notification.isRead}>
      <span>
        <span className="font-bold">{notification.meta.userName}</span> <T _str="joined organisation" />{' '}
        <span className="font-bold">{notification.meta.organisationName}</span>
      </span>
      <NotificationDate date={notification.createdAt} />
    </NotificationContainer>
  );
};
const UserAcceptedInvitationNotification = memo($UserAcceptedInvitationNotification);

const $PointcloudExportDoneNotification: React.FC<{
  notification: PointCloudExportDoneNotification;
}> = ({ notification }) => {
  const readNotification = useReadNotification();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    readNotification({ notification });
    navigate(`/organisations/${notification.organisationId}/projects/${notification.meta.projectId}`);
  }, [readNotification, notification, navigate]);

  return (
    <NotificationContainer onClick={onClick} isRead={!!notification.isRead}>
      <span>
        <T _str="export done on " /> <span className="font-bold">{notification.meta.projectName}</span>
      </span>
      <NotificationDate date={notification.createdAt} />
    </NotificationContainer>
  );
};
const PointcloudExportDoneNotification = memo($PointcloudExportDoneNotification);

const $NotificationsModal: React.FC2<NotificationsModalProps> = ({ onClose, open }) => {
  const notificationsQuery = useNotificationsQuery();
  const notifications = notificationsQuery.data?.notifications || [];
  const { xsDevice } = useDeviceSize();
  const [readNotifications, { loading: readNotificationsLoading }] = useNotificationsMarkAsReadMutation();

  const markNotificationsAsRead = useCallback(() => {
    readNotifications({ refetchQueries: [NOTIFICATIONS], awaitRefetchQueries: true });
  }, [readNotifications]);

  return (
    <Modal.Container open={open} panelClassName={'max-w-full w-auto h-3/4 z-50 overflow-auto flex flex-col'}>
      <Modal.Header className="justify-between text-xl z">
        <T _str="notifications" swc />
        <div className="flex space-x-1">
          <XMarkIcon
            onClick={onClose}
            className="w-6 h-6 hover:bg-neon-green-300 hover:dark:bg-black hover:dark:text-white"
          />
        </div>
      </Modal.Header>
      <Modal.Body className="flex w-full h-full overflow-auto">
        {/* Why can this div not fit in the modal, header height isn't taken into account? 
          h-5/6 is a temporary fix, not ideal. */}
        <div className="flex flex-col w-full h-full overflow-auto">
          <div className="flex flex-col bg-[white] border border-gray-300 rounded dark:bg-[#242424] divide-y w-full">
            {notifications.map((notification) => {
              if (notification.__typename === 'PointCloudConvertDoneNotification')
                return <PointCloudConvertDoneNotification key={notification.id} notification={notification} />;
              if (notification.__typename === 'PointCloudCalculationDoneNotification')
                return <PointCloudCalculationDoneNotification key={notification.id} notification={notification} />;
              if (notification.__typename === 'UserAcceptedInvitationNotification')
                return <UserAcceptedInvitationNotification key={notification.id} notification={notification} />;
              if (notification.__typename === 'PointCloudExportDoneNotification')
                return <PointcloudExportDoneNotification key={notification.id} notification={notification} />;
              return null;
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="ml-auto">
          <Button variant="primary" onClick={markNotificationsAsRead} loading={readNotificationsLoading}>
            {xsDevice ? <Checkmark /> : <T _str="mark notifications as read" swc />}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  );
};

export const NotificationsModal = memo($NotificationsModal);
