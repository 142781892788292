import { AddWmsLayerActionValue } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';
import { addOrderedIdentifier } from '../helpers/addOrderedIdentifier';

export class AddWmsLayerAction implements IAction<AddWmsLayerActionValue> {
  public identifier: string;
  constructor(public value: AddWmsLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const value = { ...this.value, identifier: this.value.layerId };
    state.wmsLayers = state.wmsLayers ? state.wmsLayers.concat(value) : [value];
    addOrderedIdentifier(state, {
      identifier: value.identifier,
      itemIdentifierKey: 'wmsLayers',
      orderedIdentifiersKey: 'wmsLayerOrderedIdentifiers',
    });
  }
  undo() {
    return;
  }
}
