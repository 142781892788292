import { useCondMeterToFeet } from './useFormatNumber';

export const useConvertedMeasurementInputProps = () => {
  const { condMeterToFeet } = useCondMeterToFeet();

  const getProps = ({ defaultValue }: { defaultValue: number }) => {
    return {
      step: '0.01',
      type: 'number',
      defaultValue: condMeterToFeet(defaultValue).toFixed(2),
    };
  };

  return getProps;
};
