import * as React from "react";
const SvgVectorLine = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 25 25", width: 25, height: 25, ...props }, /* @__PURE__ */ React.createElement("rect", { fill: "none", height: 25, width: 25, style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 3.906, cy: 19.531, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 9.375, cy: 9.375, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 15.625, cy: 15.625, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 21.094, cy: 5.469, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 8.262, x2: 5.02, y1: 11.436, y2: 17.471, style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 13.965, x2: 11.035, y1: 13.965, y2: 11.035, style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 19.98, x2: 16.738, y1: 7.529, y2: 13.564, style: {
  strokeWidth: "1.5px"
} }));
export default SvgVectorLine;
