import { createContext } from 'react';
import { ProjectByIdQuery } from '../types/graphqlTypes';
import { DecompressedCadLayer } from '../pages/projects/helpers/decompressCadLayers';

export type CadLayersContext = ProjectByIdQuery['projectById']['state']['cadLayers'];

export type CadLayersContextType = {
  cadLayers: DecompressedCadLayer[];
};

export const CadObjectContext = createContext<CadLayersContextType>({
  cadLayers: [],
});
