import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { fixOrderIndices } from './fixOrderIndices';

export const removeOrderedIdentifier = (
  state: PointCloudCommandManagerState,
  {
    identifier,
    orderedIdentifiersKey,
  }: { identifier: string; orderedIdentifiersKey: 'orderedIdentifiers' | 'wmsLayerOrderedIdentifiers' }
) => {
  const removedIndex = state[orderedIdentifiersKey].find((orderInfo) => identifier === orderInfo.identifier)?.index;
  state[orderedIdentifiersKey] = state[orderedIdentifiersKey].filter(
    (orderInfo) => identifier !== orderInfo.identifier
  );
  fixOrderIndices(state);
  return removedIndex;
};
