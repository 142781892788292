import { useApolloClient } from '@apollo/client';
import Logo from '../../assets/logo-same-color.svg?react';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { FormikCheckBox } from '../../components/formik/FormCheckBox';
import { FormikInput } from '../../components/formik/FormInput';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { useLogin } from '../../hooks/modules/user/useLogin';
import useQueryParams from '../../hooks/useQueryParams';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';
import {
  CurrentUserDocument,
  useCurrentUserQuery,
  useUserAcceptInvitationMutation,
  useUserInviteInfoQuery,
} from '../../types/graphqlTypes';

const logo = <Logo className="w-auto mx-auto" />;

const useValidationSchema = ({ isNewUser }: { isNewUser: boolean }) => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      isNewUser &&
      yup.object().shape({
        firstName: yup.string().trim().required(translations.isRequired),
        lastName: yup.string().trim().required(translations.isRequired),
        agreeTerms: yup.bool().oneOf([true], translations.agreeTerms),
        agreeReceiveNews: yup.bool().oneOf([true, false]),
        password: yup.string().trim().required(translations.isRequired),
        confirmPassword: yup
          .string()
          .required(translations.isRequired)
          .oneOf([yup.ref('password')], translations.passwordsMustMatch),
      }),
    [translations, isNewUser]
  );

  return schema;
};

interface FormValues {
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  agreeTerms: boolean;
  agreeReceiveNews: boolean;
}

const useTranslations = () => ({
  firstName: useT('first name', { swc: true }),
  lastName: useT('last name', { swc: true }),
  email: useT('email', { swc: true }),
  password: useT('password', { swc: true }),
  confirmPassword: useT('confirm password', { swc: true }),
  alreadyExists: useT('already exists', { swc: true }),
});

const $Invitation: React.FC2 = () => {
  const translations = useTranslations();
  const { data: userData } = useCurrentUserQuery();
  const currentUser = userData?.currentUser;
  const { token = '', organisationId = '', projectId = '' } = useQueryParams(['token', 'projectId', 'organisationId']);
  const navigate = useNavigate();
  const {
    data: userInviteData,
    loading: userInviteDataLoading,
    error: userInviteDataError,
  } = useUserInviteInfoQuery({ variables: { token } });
  const apolloClient = useApolloClient();
  const login = useLogin();
  const isNewUser = userInviteData?.userInviteInfo.isNewUser;
  const validationSchema = useValidationSchema({ isNewUser: !!userInviteData?.userInviteInfo?.isNewUser });
  const [acceptInvitation, { loading: acceptInvitationLoading }] = useUserAcceptInvitationMutation();

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async ({ confirmPassword, ...values }) => {
      if (!userInviteData) return;
      const { email, inviteId } = userInviteData.userInviteInfo;
      if (!inviteId) return;
      await acceptInvitation({ variables: { inviteId, userEmail: email, user: values } });
      if (isNewUser && !currentUser) {
        // Try to automatically login the new user
        await login({ email, password: values.password, remember: false });
      }
      await apolloClient.refetchQueries({ include: [CurrentUserDocument] });
      navigate(`/organisations/${organisationId}${projectId ? `/projects/${projectId}` : ''}`);
    },
    [acceptInvitation, apolloClient, currentUser, isNewUser, login, navigate, organisationId, projectId, userInviteData]
  );

  const initialValues: FormikConfig<FormValues>['initialValues'] = useMemo(
    () => ({
      password: '',
      firstName: '',
      lastName: '',
      confirmPassword: '',
      agreeReceiveNews: false,
      agreeTerms: false,
    }),
    []
  );

  useEffect(() => {
    if (userInviteDataError?.message === 'ALREADY_ACCEPTED') navigate(`/organisations/${organisationId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInviteDataError?.message, organisationId]);

  const loadingScreen = (
    <div className="flex items-center justify-center w-full h-full">
      <EarthLoader />
    </div>
  );

  if (userInviteDataLoading) return loadingScreen;

  if (userInviteDataError) {
    if (userInviteDataError.message === 'ALREADY_ACCEPTED') return loadingScreen;
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        {logo}
        <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
          <T _str="Oops! something is wrong with your invitation" />
        </h2>
        <div>
          <T _str="Please contact your administrator" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full min-h-full px-4 py-12 sm:px-6 lg:px-8 dark:bg-[#313131] dark:text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          {logo}
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900 dark:text-gray-200">
            <T
              _str="You have been invited to {name}"
              args={{ name: userInviteData?.userInviteInfo.organisationName || '' }}
            />
          </h2>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} {...(validationSchema && { validationSchema })}>
          {/* @ts-ignore  TODO fix when formik update*/}
          <Form className="mt-8">
            {isNewUser && (
              <>
                <FormikInput name="firstName" label={translations.firstName} placeholder={translations.firstName} />
                <FormikInput name="lastName" label={translations.lastName} placeholder={translations.lastName} />
                <FormikInput
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  label={translations.password}
                />
                <FormikInput name="confirmPassword" type="password" label={translations.confirmPassword} />
                <FormikCheckBox
                  name="agreeTerms"
                  label={
                    <span>
                      <T _str="I agree to Pointorama's" />{' '}
                      <a>
                        <T _str="Terms and Privacy Policy" />
                      </a>
                    </span>
                  }
                />
                <FormikCheckBox
                  name="agreeReceiveNews"
                  label={<T _str="I agree to receive Pointorama's news and updates " />}
                />
              </>
            )}
            <div className="mt-6">
              <Button
                variant="primary"
                type="submit"
                className="items-center justify-center w-full"
                loading={acceptInvitationLoading}
              >
                <T _str="accept invitation" swc />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export const Invitation = memo($Invitation);
