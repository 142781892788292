import { gql } from '@apollo/client';

export const BILLING_PRODUCTS = gql`
  query billingProducts($organisationId: ID!) {
    billingProducts {
      name
      description
      prices {
        value
        currency
        period
        id
      }
      isActive(organisationId: $organisationId)
      endDate(organisationId: $organisationId)
    }
  }
`;

export const BILLING_INVOICES = gql`
  query billingInvoices($organisationId: ID!) {
    billingInvoices(organisationId: $organisationId) {
      date
      description
      price {
        value
        currency
      }
      url
    }
  }
`;

const PAYMENT_DETAILS_FRAGMENT = gql`
  fragment PaymentDetails on PaymentDetails {
    address {
      postalCode
      country
      line1
      line2
      city
    }
    name
    VAT
    email
    paymentType
    cardBrand
    cardLast4
  }
`;

export const BILLING_PAYMENT_DETAILS = gql`
  ${PAYMENT_DETAILS_FRAGMENT}
  query billingPaymentDetails($organisationId: ID!) {
    billingPaymentDetails(organisationId: $organisationId) {
      ...PaymentDetails
    }
  }
`;

export const CHECKOUT_URL = gql`
  mutation billingCheckoutUrl($organisationId: ID!, $priceId: ID!) {
    billingCheckoutUrl(organisationId: $organisationId, priceId: $priceId)
  }
`;

export const BILLING_SETUP_INTENT_CARD_CLIENT_SECRET = gql`
  mutation billingSetupIntentCardClientSecret($organisationId: ID!) {
    billingSetupIntentCardClientSecret(organisationId: $organisationId)
  }
`;

export const BILLING_UPDATE_CUSTOMER = gql`
  ${PAYMENT_DETAILS_FRAGMENT}
  mutation billingUpdateCustomer($organisationId: ID!, $customer: InputBillingCustomer!) {
    billingUpdateCustomer(organisationId: $organisationId, customer: $customer) {
      ...PaymentDetails
    }
  }
`;
