import { EditBoxActionValue } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';

export class EditBoxAction implements IAction<EditBoxActionValue> {
  public identifier: string;
  _previousValues?: {
    Position: { x: number; y: number; z: number };
    Scale: { x: number; y: number; z: number };
    Rotation: { x: number; y: number; z: number };
  };

  constructor(public value: EditBoxActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation || annotation.type !== 'BOX') return;
    this._previousValues = {
      Position: annotation.position,
      Scale: annotation.scale,
      Rotation: annotation.rotation,
    };
    const { position, scale, rotation } = this.value;
    return Object.assign(annotation, { position, scale, rotation });
  }
  undo(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation || annotation.type !== 'BOX' || !this._previousValues) {
      this._previousValues = undefined;
      return;
    }
    return (
      (annotation.position = this._previousValues.Position) &&
      (annotation.scale = this._previousValues.Scale) &&
      (annotation.rotation = this._previousValues.Rotation)
    );
  }
}
