import {
  AnnotationGroup,
  CadLayer,
  ConvertCadObjectActionValue,
  PointAnnotation,
  DistanceAnnotation,
  AreaAnnotation,
  Annotation,
} from '@pointorama/database';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { addOrderedIdentifier } from '../helpers/addOrderedIdentifier';
import { keyBy } from 'lodash/fp';

export class ConvertCadObjectAction implements IAction<ConvertCadObjectActionValue> {
  public identifier: string;
  constructor(public value: ConvertCadObjectActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const cadObjectsById = keyBy(
      'identifier',
      state.cadLayers.flatMap((cadLayer) =>
        cadLayer.cadObjectGroups.flatMap((cadObjectGroup) => cadObjectGroup.cadObjects)
      )
    );
    this.value.cadObjectIds.forEach((id, index) => {
      // find cadObject
      const cadObject = cadObjectsById[id];

      // find or create annotation group
      let group: AnnotationGroup | undefined = undefined;
      if (cadObject) {
        const groupName =
          state.cadLayers.find((layer: CadLayer) => layer.identifier === cadObject.cadLayerId)?.name || 'unknown';
        group = state.groups.find((annotationGroup) => annotationGroup.name === groupName);
        if (!group) {
          group = {
            identifier: this.value.identifiers[index].groupIdentifier,
            name: groupName,
          };
          state.groups = state.groups.concat(group);
          addOrderedIdentifier(state, {
            identifier: group.identifier,
            itemIdentifierKey: 'annotations',
            orderedIdentifiersKey: 'orderedIdentifiers',
          });
        }
      }
      // create annotation
      let annotation: Annotation;
      const commonProperties = {
        identifier: this.value.identifiers[index].annotationIdentifier,
        annotationIdentifier: this.identifier,
        name: cadObject.name,
        groupIdentifier: group?.identifier,
      };
      switch (cadObject.type) {
        case 'POINT':
          annotation = {
            ...commonProperties,
            type: 'POINT',
            position: cadObject.position,
          } as PointAnnotation;
          break;
        case 'LINE':
          annotation = {
            ...commonProperties,
            points: cadObject.points.map((p) => ({ position: p, pointCloudId: undefined })),
            type: 'DISTANCE',
          } as DistanceAnnotation;
          break;
        case 'POLYGON':
          annotation = {
            ...commonProperties,
            points: cadObject.points.map((p) => ({ position: p, pointCloudId: undefined })),
            type: 'AREA',
          } as AreaAnnotation;
          break;
      }
      state.annotations.push(annotation);
      if (group)
        addOrderedIdentifier(state, {
          groupIdentifier: group.identifier,
          identifier: annotation.identifier,
          orderedIdentifiersKey: 'orderedIdentifiers',
          itemIdentifierKey: 'annotations',
        });
    });
  }
  undo() {
    return;
  }
}
