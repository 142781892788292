import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createBrowserHistory } from 'history';
import { get } from 'lodash';
import store, { logoutUserAction } from '../redux/store';
import { cache } from './cache';

export const history = createBrowserHistory();

const link = () => {
  return ApolloLink.from([
    onError((apolloError) => {
      const { graphQLErrors = [], networkError } = apolloError;
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
      if (
        graphQLErrors.find(
          ({ extensions }) => extensions?.code === 'UNAUTHENTICATED' && extensions?.reason === 'NO_USER'
        )
      ) {
        store.dispatch(logoutUserAction({}));
        apolloClient.cache.reset().then(() => window.location.replace('/login'));
      }
      const invalidSubscriptionError = graphQLErrors.find(({ message }) => message === 'SUBSCRIPTION_INVALID');
      if (invalidSubscriptionError) {
        const organisationsIndex = window.location.pathname.indexOf('/organisations/');
        const slashAfterOrganisationIdIndex = window.location.pathname.indexOf('/', organisationsIndex + 15);
        const url = window.location.pathname.slice(
          0,
          slashAfterOrganisationIdIndex !== -1 ? slashAfterOrganisationIdIndex : undefined
        );
        const newPathname = url + '/invalid-subscription?reason=' + invalidSubscriptionError.extensions?.reason;
        if (newPathname !== window.location.pathname + window.location.search)
          window.location.replace(url + '/invalid-subscription?reason=' + invalidSubscriptionError.extensions?.reason);
      }
      const invalidProjectError = graphQLErrors.find(({ message }) => message === 'PROJECT_INVALID');
      if (invalidProjectError) {
        const organisationsIndex = window.location.pathname.indexOf('/organisations/');
        const slashAfterOrganisationIdIndex = window.location.pathname.indexOf('/', organisationsIndex + 15);
        const url = window.location.pathname.slice(
          0,
          slashAfterOrganisationIdIndex !== -1 ? slashAfterOrganisationIdIndex : undefined
        );
        const newPathname = url + '/invalid-project?reason=' + invalidProjectError.extensions?.reason;
        if (newPathname !== window.location.pathname + window.location.search)
          window.location.replace(url + '/invalid-project?reason=' + invalidProjectError.extensions?.reason);
      }
      if (!networkError) return;

      try {
        //@ts-ignore
        JSON.parse(networkError.bodyText);
      } catch (e) {
        //caught Network error: Unexpected token < in JSON at position 0 //
        networkError.message = 'Http Error';
      }
    }),
    new HttpLink({
      uri: ({ operationName, query }) => {
        const type = query.definitions.map((x: any) => get(x, 'operation', '')).join('');
        return `${import.meta.env.VITE_NX_SERVER}/graphql?t=${type}&o=${operationName}`;
      },
      credentials: 'include',
    }),
  ]);
};

export const apolloClient = new ApolloClient({
  link: link(),
  cache,
  connectToDevTools: import.meta.env.DEV,
});

apolloClient.onResetStore(async () => {
  apolloClient.setLink(link());
});
