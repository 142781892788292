import { memo } from 'react';
import { T } from '../../../translation/src';

interface MultiSelectCalculationsProps {}
const $MultiSelectCalculations: React.FC2<MultiSelectCalculationsProps> = () => {
  return (
    <div className="flex flex-col space-y-4 divide-y divide-gray-300">
      <div className="mt-4 text-sm">
        <T _str="Calculations on multiple selections are not possible." />
      </div>
    </div>
  );
};

export const MultiSelectCalculations = memo($MultiSelectCalculations);
