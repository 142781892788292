import classNames from 'classnames';
import { DetailedHTMLProps, InputHTMLAttributes, memo } from 'react';

export type CheckBoxProps = { className?: string } & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
const $CheckBox: React.FC<CheckBoxProps> = ({ className, ...props }) => {
  return (
    <input
      type="checkbox"
      className={classNames(className, 'w-4 h-4 text-neon-green-600 border-gray-300 dark:border-gray-400 focus:ring-0 focus:ring-offset-0')}
      {...props}
    />
  );
};

export const CheckBox = memo($CheckBox);
