import { v4 as uuid } from 'uuid';
import { addOrderedIdentifier } from '../helpers/addOrderedIdentifier';
import { removeOrderedIdentifier } from '../helpers/removeOrderedIdentifier';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class AddAnnotationAction<ActionValue extends { groupIdentifier?: string | null; annotationIdentifier: string }>
  implements IAction<ActionValue>
{
  public identifier: string;
  constructor(public value: ActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    addOrderedIdentifier(state, {
      groupIdentifier: this.value.groupIdentifier,
      identifier: this.value.annotationIdentifier,
      itemIdentifierKey: 'annotations',
      orderedIdentifiersKey: 'orderedIdentifiers',
    });
  }
  undo(state: PointCloudCommandManagerState) {
    state.annotations = state.annotations.filter(
      (annotation) => annotation.identifier !== this.value.annotationIdentifier
    );
    removeOrderedIdentifier(state, {
      identifier: this.value.annotationIdentifier,
      orderedIdentifiersKey: 'orderedIdentifiers',
    });
  }
}
