import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import amex from '../../../assets/card-images/amex.png';
import diners from '../../../assets/card-images/diners.png';
import discover from '../../../assets/card-images/discover.png';
import jcb from '../../../assets/card-images/jcb.png';
import mastercard from '../../../assets/card-images/mastercard.png';
import unionpay from '../../../assets/card-images/unionpay.png';
import visa from '../../../assets/card-images/visa.png';
import { Card } from '../../../components/Card';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { useStripeAppearance, useStripeModule } from '../../../hooks/modules/billing/useStripeModule';
import { T, useT } from '../../../translation/src';
import {
  useBillingPaymentDetailsQuery,
  useBillingSetupIntentCardClientSecretMutation,
} from '../../../types/graphqlTypes';

const CARD_IMAGES = {
  visa,
  mastercard,
  amex,
  discover,
  diners,
  jcb,
  unionpay,
};

interface PaymentCardModalProps {
  onClose: () => void;
  open?: boolean;
  onPaymentCardFormReady: () => void;
  paymentCardFormReady?: boolean;
}
const $PaymentCardModal: React.FC<PaymentCardModalProps> = ({
  onClose,
  paymentCardFormReady,
  onPaymentCardFormReady,
}) => {
  const translations = useTranslations();
  const stripe = useStripe();
  const elements = useElements();
  const { value: isSubmitting, setValue: setIsSubmitting } = useBoolean();

  const onSubmit = useCallback(async () => {
    if (!stripe || !elements) return null;
    setIsSubmitting(true);
    await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });
    setIsSubmitting(false);
  }, [elements, stripe, setIsSubmitting]);

  const paymentElement = useMemo(() => <PaymentElement onReady={onPaymentCardFormReady} />, [onPaymentCardFormReady]);

  if (!paymentCardFormReady) return <div className="hidden">{paymentElement}</div>;

  return (
    <div className={paymentCardFormReady ? '' : 'hidden'}>
      <SubmitModal
        open={paymentCardFormReady}
        onClose={onClose}
        title={translations.updatePaymentMethod}
        actionButtonTitle={translations.save}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      >
        <PaymentElement onReady={onPaymentCardFormReady} />
      </SubmitModal>
    </div>
  );
};
const PaymentCardModal = memo($PaymentCardModal);

const useTranslations = () => {
  const paymentMethod = useT('payment method', { swc: true });
  const email = useT('email', { swc: true });
  const paymentType = useT('payment Type', { swc: true });
  const country = useT('country', { swc: true });
  const postalCode = useT('postal code', { swc: true });
  const editPaymentMethod = useT('edit payment method', { swc: true });
  const addPaymentMethod = useT('add payment method', { swc: true });
  const updatePaymentMethod = useT('update payment method', { swc: true });
  const street = useT('street', { swc: true });
  const save = useT('save', { swc: true });
  return {
    paymentMethod,
    addPaymentMethod,
    email,
    paymentType,
    country,
    postalCode,
    editPaymentMethod,
    street,
    updatePaymentMethod,
    save,
  };
};

const $BillingPaymentDetails: React.FC2 = () => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const stripeAppearance = useStripeAppearance();
  const { setTrue: onPaymentCardFormReady, value: paymentCardFormReady } = useBoolean();
  const [billingSetupIntentCardClientSecret, { loading: billingSetupIntentCardClientSecretLoading }] =
    useBillingSetupIntentCardClientSecretMutation();
  const { data: billingPaymentDetailsData } = useBillingPaymentDetailsQuery({ variables: { organisationId } });
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const paymentDetails = billingPaymentDetailsData?.billingPaymentDetails;
  const stripePromise = useStripeModule();

  const onEditDetails = useCallback(async () => {
    const result = await billingSetupIntentCardClientSecret({ variables: { organisationId } });
    setClientSecret(result.data?.billingSetupIntentCardClientSecret);
  }, [billingSetupIntentCardClientSecret, organisationId]);

  const onClosePaymentCardModal = useCallback(() => {
    setClientSecret(undefined);
  }, []);

  const cardImageUrl = CARD_IMAGES[paymentDetails?.cardBrand as keyof typeof CARD_IMAGES];

  if (!paymentDetails) return null;

  return (
    <div className="w-full">
      <Card
        title={translations.paymentMethod}
        className="w-full p-0"
        titleClassName="px-4 pt-4"
        onSubmit={onEditDetails}
        submitButtonTitle={paymentDetails ? translations.editPaymentMethod : translations.addPaymentMethod}
        isSubmitting={billingSetupIntentCardClientSecretLoading || (clientSecret && !paymentCardFormReady) || false}
      >
        <div className="flex items-center h-full px-4 py-4">
          {cardImageUrl && <img className="h-6" src={cardImageUrl} alt="card_brand" />}
          <div className="ml-3">
            <strong>{paymentDetails.cardBrand}</strong> <T _str="ending in" />{' '}
            <strong>{paymentDetails.cardLast4}</strong>
          </div>
        </div>
      </Card>
      {clientSecret && (
        // @todo change appearance
        <Elements stripe={stripePromise} options={{ clientSecret, appearance: stripeAppearance }}>
          <PaymentCardModal
            onClose={onClosePaymentCardModal}
            paymentCardFormReady={paymentCardFormReady}
            onPaymentCardFormReady={onPaymentCardFormReady}
          />
        </Elements>
      )}
    </div>
  );
};

export const BillingPaymentDetails = memo($BillingPaymentDetails);
