import { ProjectAction } from '@pointorama/database';

export enum ErrorMessage {
  POSITION_MISSING = 'POSITION_MISSING',
  POINTS_MISSING = 'POINTS_MISSING',
}

interface ErrorInfo {
  meta?: Record<string, unknown>;
  actionName: ProjectAction['type'];
}
export class PointcloudCommanderError extends Error {
  info: ErrorInfo;
  constructor(message: ErrorMessage, info: ErrorInfo) {
    super(message);
    this.info = info;
  }
}
