import { createContext } from 'react';
import { ProjectByIdQuery } from '../types/graphqlTypes';

export type WmsLayersContextType = {
  wmsLayers: ProjectByIdQuery['projectById']['state']['wmsLayers'];
};

export const WMSLayersContext = createContext<WmsLayersContextType>({
  wmsLayers: [],
});
