import { BaseMutationOptions, useMutation } from '@apollo/client';
import { RendererContext } from '../../../contexts/RendererContext';
import { PROJECT_UPDATE_SETTINGS } from '../../../graphql/project';
import { ProjectionSystems } from '../../../modules/project/ProjectProjectionSystemModal';
import { ProjectUpdateSettingsMutation, ProjectUpdateSettingsMutationVariables } from '../../../types/graphqlTypes';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

export const useUpdateProjectSettings = () => {
  const [updateProject, { loading }] = useMutation<
    ProjectUpdateSettingsMutation,
    ProjectUpdateSettingsMutationVariables
  >(PROJECT_UPDATE_SETTINGS);
  const { projectId = '' } = useParams();
  const renderContext = useContext(RendererContext);
  const viewer = renderContext.viewer;

  const updateSettings = useCallback(
    ({
      onCompleted,
      settings,
    }: {
      settings: ProjectUpdateSettingsMutationVariables['settings'];
      onCompleted?: (data: ProjectUpdateSettingsMutation, clientOptions?: BaseMutationOptions) => void;
    }) => {
      viewer?.scene.pointclouds.forEach((pointCloud) => {
        const projection = ProjectionSystems[settings.projectionSystem as keyof typeof ProjectionSystems];
        if (!projection) return;
        pointCloud.projection = projection;
      });
      updateProject({
        variables: { settings, projectId },
        onCompleted,
        optimisticResponse: {
          __typename: 'Mutation',
          projectUpdateSettings: {
            __typename: 'Project',
            id: projectId,
            settings: {
              __typename: 'ProjectSettings',
              ...settings,
            },
          },
        },
      });
    },
    [projectId, updateProject, viewer]
  );

  return [updateSettings, { loading }] as const;
};
