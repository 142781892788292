import { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { UploadProgressModal } from '../../../components/UploadCard/UploadProgressModal';
import { UserContext } from '../../../contexts/UserContext';
import { T } from '../../../translation/src';
import useOpen from '../../useOpen';

export const useMaxFiles = () => {
  const currentUser = useContext(UserContext);
  const { organisationId = '' } = useParams();
  const organisation = currentUser.organisations.find((org) => org.id === organisationId);
  return organisation?.subscription.simultaneousUploadsLeft || -1;
};

export const usePointCloudDropWithModals = ({ isPythagorasFile = false }: { isPythagorasFile?: boolean } = {}) => {
  const { open: maxFilesModalOpen, onClose: closeMaxFilesModal, onOpenWithValue: openMaxFilesModal } = useOpen();
  const {
    open: uploadProgressModalOpen,
    onClose: onCloseUploadProgressModal,
    onOpenWithValue: openUploadProgressModal,
    value: uploadProgressModalFiles,
  } = useOpen<File[]>();
  const maxFiles = useMaxFiles();
  const user = useContext(UserContext);

  const onNewFiles = useCallback(
    (files: File[]) => {
      if (!user.isSuperAdmin && !!files.length && files.length > maxFiles) return openMaxFilesModal();
      return openUploadProgressModal(files);
    },
    [maxFiles, openMaxFilesModal, openUploadProgressModal, user.isSuperAdmin]
  );

  const modals = useMemo(() => {
    return (
      <>
        <SubmitModal
          open={maxFilesModalOpen}
          onCancel={closeMaxFilesModal}
          title={<T _str="Too many files" />}
          key="maxFilesModal"
        >
          <InfoContent
            type="info"
            title={<T _str="Too many files" swc />}
            description={
              <T
                _str="There is a restriction on the max amount of files you can simultaneously upload based on your subscription. The amount of files you can upload now is {amount}"
                args={{ amount: maxFiles }}
              />
            }
          />
        </SubmitModal>
        <UploadProgressModal
          key="uploadprogressmodal"
          open={uploadProgressModalOpen}
          files={uploadProgressModalFiles}
          onClose={onCloseUploadProgressModal}
          isPythagorasFile={isPythagorasFile}
        />
      </>
    );
  }, [
    maxFilesModalOpen,
    closeMaxFilesModal,
    maxFiles,
    uploadProgressModalOpen,
    uploadProgressModalFiles,
    onCloseUploadProgressModal,
    isPythagorasFile,
  ]);

  return [{ modals }, { onNewFiles }] as const;
};

export const usePointCloudDrop = ({
  isPythagorasFile,
  onNewFiles,
}: { isPythagorasFile?: boolean; onNewFiles?: (files: File[]) => void } = {}) => {
  const [files, setFiles] = useState<File[] | undefined>();
  const onDrop = useCallback(
    (files: File[]) => {
      if (!files.length) return;
      onNewFiles?.(files);
      setFiles(files);
    },
    [onNewFiles]
  );
  const maxFiles = useMaxFiles();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: isPythagorasFile
      ? {
          'application/octet-stream': ['.zip'],
        }
      : {
          'application/vnd.las': ['.las'],
          'application/octet-stream': ['.laz', '.pts'],
          'application/vnd.e57': ['.e57'],
        },
  });
  return [
    { files, maxFiles },
    { setFiles, getRootProps, getInputProps },
  ] as const;
};
