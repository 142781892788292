import { Annotation, AnnotationGroup, DeleteGroupActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { removeGroup } from '../helpers/removeGroup';
import { undoGroupAction } from '../helpers/undoGroupAction';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class DeleteGroupAction implements IAction<DeleteGroupActionValue> {
  public identifier: string;
  _removedOrderedIdentifiers:
    | {
        identifier: string;
        index: number;
      }[]
    | undefined
    | null;
  _removedGroupAnnotations: Annotation[] | undefined | null;
  _removedGroup: AnnotationGroup | undefined | null;
  constructor(public value: DeleteGroupActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const { removedOrderedIdentifiers, removedGroupAnnotations, removedGroup } = removeGroup(state, {
      identifier: this.value.groupIdentifier,
    });
    this._removedGroupAnnotations = removedGroupAnnotations;
    this._removedOrderedIdentifiers = removedOrderedIdentifiers;
    this._removedGroup = removedGroup;
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedGroupAnnotations || !this._removedOrderedIdentifiers || !this._removedGroup) return;
    undoGroupAction(
      {
        removedOrderedIdentifiers: this._removedOrderedIdentifiers,
        removedAnnotations: this._removedGroupAnnotations,
        removedGroup: this._removedGroup,
      },
      { state }
    );
    this._removedOrderedIdentifiers = undefined;
    this._removedGroupAnnotations = undefined;
    this._removedGroup = undefined;
  }
}
