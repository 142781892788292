import { CheckIcon, PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { Button } from '../../../components/Button';
import { FormikTextArea } from '../../../components/formik/FormTextArea';
import { UserContext } from '../../../contexts/UserContext';
import { PROJECT } from '../../../graphql/project';
import { useFormatDate } from '../../../hooks/useFormatDate';
import useOpen from '../../../hooks/useOpen';
import { useCustomSelector } from '../../../redux/store';
import { T, useT } from '../../../translation/src';
import {
  Comment,
  Project,
  ProjectByIdQuery,
  useProjectAddCommentMutation,
  useProjectEditCommentMutation,
} from '../../../types/graphqlTypes';
import { ProjectDeleteCommentModal } from '../../project/ProjectDeleteCommentModal';

interface FormValues {
  comment: string;
}

const useTranslations = () => {
  const commentPlaceholder = useT('new comment...', { swc: true });
  return { commentPlaceholder };
};

interface CommentsProps {
  project: Pick<Project, 'id'> & { comments?: ProjectByIdQuery['projectById']['comments'] | null };
}
const $Comments: React.FC<CommentsProps> = ({ project }) => {
  const translations = useTranslations();
  const currentUser = useContext(UserContext);
  const [editingCommentId, setEditingCommentId] = useState('');
  const {
    onOpenWithValue: openDeleteCommentModal,
    onClose: closeDeleteCommentModal,
    open: deleteCommentModalOpen,
    value: deleteCommentValue,
  } = useOpen<Pick<Comment, 'id'>>();
  const projectId = project.id;
  const [addComment, { loading: addCommentLoading }] = useProjectAddCommentMutation();
  const [editComment] = useProjectEditCommentMutation();
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);
  const { formatDate } = useFormatDate();

  const comments = useMemo(() => {
    return project?.comments
      ?.filter((comment) =>
        selectedAnnotations.some((annotation) => annotation.identifier === comment.referenceIdentifier)
      )
      .slice(0)
      .reverse();
  }, [project?.comments, selectedAnnotations]);

  const onSubmitComment: FormikConfig<FormValues>['onSubmit'] = useCallback(
    (values, formikbag) => {
      if (values.comment === '') {
        return;
      }
      addComment({
        variables: {
          projectId: projectId || '',
          comment: values.comment,
          referenceIdentifier: selectedAnnotations[0].identifier,
        },
        refetchQueries: [{ query: PROJECT, variables: { projectId: projectId } }],
      }).then(() => formikbag.resetForm());
    },
    [addComment, projectId, selectedAnnotations]
  );

  const onEditComment: FormikConfig<FormValues>['onSubmit'] = useCallback(
    (values) => {
      if (values.comment.trim() === '') return;
      editComment({
        variables: {
          projectId: projectId,
          commentId: editingCommentId,
          newComment: values.comment,
        },
        optimisticResponse: {
          projectEditComment: {
            id: projectId,
            comments: [
              {
                __typename: 'Comment',
                id: editingCommentId,
                comment: values.comment,
              },
            ],
          },
        },
        refetchQueries: [{ query: PROJECT, variables: { projectId: project?.id } }],
      });
      setEditingCommentId('');
    },
    [editComment, projectId, editingCommentId, project?.id]
  );

  return (
    <div className="flex flex-col h-full overflow-auto">
      <div>
        <Formik initialValues={{ comment: '' }} onSubmit={onSubmitComment}>
          <Form className={classNames('mt-2 mr-2 p-1', comments?.length && 'border-b-2 pb-2')}>
            <FormikTextArea className="text-sm" name="comment" placeholder={translations.commentPlaceholder} />
            <Button type="submit" className="my-2" variant="secondary" loading={addCommentLoading}>
              <T _str="add comment" swc />
            </Button>
          </Form>
        </Formik>
      </div>
      <div className="flex flex-col overflow-auto divide-y">
        {comments?.map((comment) => (
          <div key={comment.id} className="p-2">
            <div className="relative font-bold">
              {comment.user.name}
              {comment.user.id === currentUser.id && (
                <>
                  <button className="absolute right-3" onClick={() => openDeleteCommentModal(comment)}>
                    <TrashIcon className="w-5 h-5 text-gray-500 hover:text-neon-green-600" />
                  </button>
                  <button className="absolute right-9" onClick={() => setEditingCommentId(comment.id)}>
                    <PencilIcon className="w-5 h-5 text-gray-500 hover:text-neon-green-600" />
                  </button>
                </>
              )}
            </div>
            {!comment.updatedAt && <div className="text-xs text-gray-400">{formatDate(comment.createdAt)}</div>}
            {comment.updatedAt && (
              <div className="text-xs text-gray-400">{formatDate(comment.createdAt) + ' (Edited)'}</div>
            )}
            {editingCommentId !== comment.id && (
              <div className="my-3 text-sm text-gray-500 break-words dark:text-gray-300">{comment.comment}</div>
            )}
            {editingCommentId === comment.id && (
              <div>
                <Formik initialValues={{ comment: comment.comment }} onSubmit={onEditComment}>
                  <Form className="mt-2 mr-2">
                    <FormikTextArea className="text-sm" name="comment" placeholder={translations.commentPlaceholder} />
                    <button type="submit" className="my-2">
                      <CheckIcon className="w-6 h-6 text-gray-300 hover:text-neon-green-600" />
                    </button>
                    <button type="button" onClick={() => setEditingCommentId('')} className="my-2">
                      <XMarkIcon className="w-6 h-6 text-gray-300 hover:text-error-red-500" />
                    </button>
                  </Form>
                </Formik>
              </div>
            )}
          </div>
        ))}
      </div>
      <ProjectDeleteCommentModal
        onClose={closeDeleteCommentModal}
        comment={deleteCommentValue}
        open={deleteCommentModalOpen}
      />
    </div>
  );
};

export const Comments = memo($Comments);
