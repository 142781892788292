import { useMemo } from 'react';
import { useT } from '../translation/src';

export const useValidationTranslations = () => {
  const isRequired = useT('is a required field', { swc: true });
  const maxLength20 = useT('must be at most {max} characters', { swc: true, args: { max: 20 } });
  const maxLength220 = useT('must be at most {max} characters', { swc: true, args: { max: 220 } });
  const maxLength120 = useT('must be at most {max} characters', { swc: true, args: { max: 120 } });
  const validEmail = useT('must be a valid email', { swc: true });
  const passwordsMustMatch = useT('passwords must match', { swc: true });
  const agreeTerms = useT('you must agree to the terms and conditions', { swc: true });
  const somethingWentWrong = useT('something went wrong', { swc: true });
  const isInvalidValue = useT('is invalid value', { swc: true });
  const alreadyExists = useT('already exists', { swc: true });
  const invalidUrl = useT('invalid url', { swc: true });

  const translations = useMemo(
    () => ({
      isRequired,
      maxLength20,
      maxLength120,
      maxLength220,
      validEmail,
      passwordsMustMatch,
      agreeTerms,
      somethingWentWrong,
      isInvalidValue,
      alreadyExists,
      invalidUrl,
    }),
    [
      isRequired,
      maxLength120,
      maxLength20,
      maxLength220,
      validEmail,
      passwordsMustMatch,
      agreeTerms,
      somethingWentWrong,
      isInvalidValue,
      alreadyExists,
      invalidUrl,
    ]
  );

  return translations;
};
