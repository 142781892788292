import { useCallback } from 'react';
import { useProjectDeleteCalculationMutation } from '../../../types/graphqlTypes';

export const useCancelCalculation = ({
  projectId,
  calculationIds,
  __typename,
}: {
  projectId: string;
  calculationIds?: string[];
  __typename: string;
}) => {
  const [deleteCalculation] = useProjectDeleteCalculationMutation();
  const onCancelCalculation = useCallback(() => {
    if (!calculationIds) return;
    calculationIds.forEach((calculationId) => {
      deleteCalculation({
        variables: { projectId, calculationId },
        update: (cache) => cache.evict({ id: `${__typename}:${calculationId}` }),
      });
    })
  }, [calculationIds, projectId, deleteCalculation, __typename]);

  return { onCancelCalculation };
};
