import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { UserContext } from '../../contexts/UserContext';
import useQueryParams from '../../hooks/useQueryParams';
import { T, useT } from '../../translation/src';
import { Permissions } from '../../types/graphqlTypes';

const useTranslations = () => {
  const NO_ACTIVE_SUBSCRIPTION = useT(
    'It seems that there is no active subscription or the free trial period is expired.'
  );
  return { NO_ACTIVE_SUBSCRIPTION };
};

export const InvalidSubscription: React.FC2 = () => {
  const user = useContext(UserContext);
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const { reason = '' } = useQueryParams(['reason']);
  const navigate = useNavigate();

  const reasonDescription = translations[reason as keyof typeof translations];
  const action = useMemo(() => {
    const permissions = user.organisations.find((org) => org.id === organisationId)?.myPermissions;
    if (permissions?.includes(Permissions.All) || permissions?.includes(Permissions.Billing)) {
      return (
        <div className="flex flex-col items-center justify-center">
          <div className="mb-3">
            {' '}
            <T _str="Please purchase a valid subscription or update your payment details on the billing page." />
          </div>
          <div>
            <Button variant="primary" onClick={() => navigate(`/organisations/${organisationId}/billing`)}>
              <T _str="go to billing" swc />{' '}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <T _str="Contact the admin of your organisation to update to a valid subscription." />
      </div>
    );
  }, [navigate, organisationId, user.organisations]);

  return (
    <div className="flex items-center justify-center dark:bg-[#313131] dark:text-white flex-col w-full h-full">
      <div className="mb-6 text-lg font-bold">{reasonDescription}</div>
      {action}
    </div>
  );
};
