import { AddCadLayerActionValue, CadLayer } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction, isNotNullOrUndefined } from '../types';
import { v4 as uuid } from 'uuid';
import { ErrorMessage, PointcloudCommanderError } from '../error';

export class AddCadLayerAction implements IAction<AddCadLayerActionValue> {
  public identifier: string;
  constructor(public value: AddCadLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const cadLayer: CadLayer = {
      identifier: this.value.cadLayerId,
      name: this.value.name,
      type: 'CAD',
      cadObjectGroups: this.value.cadObjectGroups
        .map((group) => {
          return {
            ...group,
            cadObjects: group.cadObjects
              .map((cadObject) => {
                if (cadObject.type === 'POINT') {
                  if (!cadObject.position)
                    throw new PointcloudCommanderError(ErrorMessage.POSITION_MISSING, {
                      actionName: 'AddCadLayerAction',
                    });
                  return { ...cadObject, type: 'POINT' as const, position: cadObject.position };
                }
                if (cadObject.type === 'LINE') {
                  if (!cadObject.points)
                    throw new PointcloudCommanderError(ErrorMessage.POINTS_MISSING, {
                      actionName: 'AddCadLayerAction',
                    });
                  return { ...cadObject, type: 'LINE' as const, points: cadObject.points };
                }
                if (cadObject.type === 'POLYGON') {
                  if (!cadObject.points)
                    throw new PointcloudCommanderError(ErrorMessage.POINTS_MISSING, {
                      actionName: 'AddCadLayerAction',
                    });
                  return { ...cadObject, type: 'POLYGON' as const, points: cadObject.points };
                }
                return null;
              })
              .filter(isNotNullOrUndefined),
          };
        })
        .filter(isNotNullOrUndefined),
    };
    state.cadLayers = state.cadLayers ? state.cadLayers.concat(cadLayer) : [cadLayer];
    return;
  }
  undo() {
    return;
  }
}
