import { useApolloClient, useMutation } from '@apollo/client';
import { PROJECT_FRAGMENT, PROJECT_TOGGLE_OBJECT_VISIBILITY } from '../../../graphql/project';
import {
  ProjectFragment,
  ProjectToggleObjectVisibilityMutation,
  ProjectToggleObjectVisibilityMutationVariables,
  AnnotationLabels,
  useProjectToggleObjectLabelVisibilityMutation,
} from '../../../types/graphqlTypes';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const useToggleHide = () => {
  const [toggleHide] = useMutation<
    ProjectToggleObjectVisibilityMutation,
    ProjectToggleObjectVisibilityMutationVariables
  >(PROJECT_TOGGLE_OBJECT_VISIBILITY);
  const apolloClient = useApolloClient();
  const { projectId = '' } = useParams();

  const onToggleHide = useCallback(
    ({ identifiers, visible }: { identifiers: string[]; visible: boolean }) => {
      const project = apolloClient.readFragment<ProjectFragment>({
        fragment: PROJECT_FRAGMENT,
        id: `Project:${projectId}`,
        fragmentName: 'Project',
      });
      if (!project) return;
      toggleHide({
        variables: { identifiers, projectId, visible },
        optimisticResponse: {
          __typename: 'Mutation',
          projectToggleObjectVisibility: {
            ...project,
            state: {
              ...project.state,
              annotations: project.state.annotations.map((annotation) => {
                if (identifiers.includes(annotation.identifier)) {
                  return { ...annotation, visible: visible };
                }
                return annotation;
              }),
              wmsLayers: project.state.wmsLayers.map((wmsLayer) => {
                if (identifiers.includes(wmsLayer.identifier)) {
                  return { ...wmsLayer, visible: visible };
                }
                return wmsLayer;
              }),
              cadLayers: project.state.cadLayers.map((cadLayer) => {
                if (identifiers.includes(cadLayer.identifier)) {
                  // switch visibility of all cadObjects in this layer
                  return {
                    ...cadLayer,
                    cadObjectGroups: cadLayer.cadObjectGroups.map((cadObjectGroup) => {
                      return {
                        ...cadObjectGroup,
                        cadObjects: cadObjectGroup.cadObjects.map((cadObject) => {
                          return {
                            ...cadObject,
                            visible: visible,
                          };
                        }),
                      };
                    }),
                  };
                }
                return {
                  ...cadLayer,
                  cadObjectGroups: cadLayer.cadObjectGroups.map((cadObjectGroup) => {
                    if (identifiers.includes(cadObjectGroup.identifier)) {
                      // switch visibility of all objects in this group
                      return {
                        ...cadObjectGroup,
                        cadObjects: cadObjectGroup.cadObjects.map((cadObject) => {
                          return { ...cadObject, visible: visible };
                        }),
                      };
                    }
                    return {
                      ...cadObjectGroup,
                      cadObjects: cadObjectGroup.cadObjects.map((cadObject) => {
                        if (identifiers.includes(cadObject.identifier)) {
                          // switch visibility of this object
                          return { ...cadObject, visible: visible };
                        }
                        return cadObject;
                      }),
                    };
                  }),
                };
              }),
            },
            mapVisible: identifiers.includes('map') ? visible : project.mapVisible,
            pointClouds: project.pointClouds.map((pointCloud) => {
              if (identifiers.includes(pointCloud.id)) {
                return { ...pointCloud, visible: visible };
              }
              return pointCloud;
            }),
          },
        },
      });
    },
    [apolloClient, projectId, toggleHide]
  );

  return { toggleHide: onToggleHide };
};

export const useToggleHideLabel = () => {
  const [toggleHideLabel] = useProjectToggleObjectLabelVisibilityMutation();
  const apolloClient = useApolloClient();
  const { projectId = '' } = useParams();

  const onToggleHideLabel = useCallback(
    async ({ identifiers, visible, label }: { identifiers: string[]; visible: boolean; label: AnnotationLabels }) => {
      const project = apolloClient.readFragment<ProjectFragment>({
        fragment: PROJECT_FRAGMENT,
        id: `Project:${projectId}`,
        fragmentName: 'Project',
      });
      if (!project) return;
      await toggleHideLabel({
        variables: { identifiers, projectId, visible, label },
        optimisticResponse: {
          __typename: 'Mutation',
          projectToggleObjectLabelVisibility: {
            ...project,
            state: {
              ...project.state,
              annotations: project.state.annotations.map((annotation) => {
                if (identifiers.includes(annotation.identifier)) {
                  return {
                    ...annotation,
                    hiddenLabels: visible
                      ? annotation.hiddenLabels.filter((l) => l !== label)
                      : [...annotation.hiddenLabels, label],
                  };
                }
                return annotation;
              }),
            },
          },
        },
      });
    },
    [apolloClient, projectId, toggleHideLabel]
  );

  return { toggleHideLabel: onToggleHideLabel };
};
