import { DeletePointActionValue, Position } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class DeletePointAction implements IAction<DeletePointActionValue> {
  public identifier: string;
  _oldPointArray?: { position: Position; pointCloudId?: string | null }[];
  constructor(public value: DeletePointActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation) return;
    if (annotation.type === 'POINT') return;
    else if (annotation.type === 'AREA' || annotation.type === 'DISTANCE') {
      this._oldPointArray = annotation.points;
      annotation.points.splice(this.value.pointIndex, 1);
      return;
    }
    return;
  }
  undo(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation) return;
    if (!this._oldPointArray) return;
    if (annotation.type === 'POINT') return;
    else if (annotation.type === 'AREA' || annotation.type === 'DISTANCE') {
      return (annotation.points = this._oldPointArray);
    }
    return;
  }
}
