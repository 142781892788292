import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { useUpdateProjectSettings } from '../../../hooks/modules/project/useUpdateProjectSettings';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import { Project, ProjectUpdateSettingsMutation } from '../../../types/graphqlTypes';
import { FormikConfig } from 'formik';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        projectionSystem: yup.string().required(translations.isRequired),
      }),
    [translations]
  );

  return schema;
};

interface FormValues {
  projectionSystem: string;
}

export const ProjectionSystems = {
  BelgianLamber72:
    '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs',
  'RD/NL':
    '+proj=sterea +lat_0=52.1561605555556 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.4171,50.3319,465.5524,1.9342,-1.6677,9.1019,4.0725 +units=m +no_defs +type=crs',
  ...Array.from(new Array(60))
    .map((_, i) => `+proj=utm +zone=${i + 1} +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs`)
    .reduce((acc, cur, i) => ({ ...acc, [`utm${i + 1}`]: cur }), {} as Record<string, string>),
  ...Array.from(new Array(60))
  .map((_, i) => `+proj=utm +zone=${i + 1} +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs`)
  .reduce((acc, cur, i) => ({ ...acc, [`utm${i + 1}S`]: cur }), {} as Record<string, string>),
};

export const isValidProjectionSystem = (
  projectionSystem: string
): projectionSystem is keyof typeof ProjectionSystems => {
  return Object.keys(ProjectionSystems).includes(projectionSystem);
};

const useProjectionSystemTranslations = () => {
  const belgianLamber72 = useT('Belgian Lambert 72');
  const utmZone = useT('UTM zone');
  return { belgianLamber72, utmZone };
};
export const useProjectionSystemOptions = () => {
  const translations = useProjectionSystemTranslations();
  return [
    {
      label: translations.belgianLamber72,
      value: 'BelgianLamber72',
    },
    {
      label: 'RD/NL',
      value: 'RD/NL',
    },
    ...Array.from(new Array(60)).map((_, i) => ({ label: `${translations.utmZone} ${i + 1}N`, value: `utm${i + 1}` })),
    ...Array.from(new Array(60)).map((_, i) => ({ label: `${translations.utmZone} ${i + 1}S`, value: `utm${i + 1}S` })),
  ];
};

export interface ProjectProjectionSystemModalProps {
  onClose: () => void;
  onSuccess?: (args: { project: ProjectUpdateSettingsMutation['projectUpdateSettings'] }) => void;
  open?: boolean;
  project: Pick<Project, 'id'>;
  title?: ReactElement | null;
}
const $ProjectProjectionSystemModal: React.FC2<ProjectProjectionSystemModalProps> = ({
  onClose,
  open,
  onSuccess,
  title,
}) => {
  const validationSchema = useValidationSchema();
  const [updateProjectSettings, { loading }] = useUpdateProjectSettings();
  const projectionSystemOptions = useProjectionSystemOptions();

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    ({ projectionSystem }) => {
      return updateProjectSettings({
        settings: { projectionSystem },
        onCompleted: ({ projectUpdateSettings }) => {
          onSuccess?.({ project: projectUpdateSettings });
        },
      });
    },
    [updateProjectSettings, onSuccess]
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { projectionSystem: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema]
  );

  return (
    <CreateModal
      title={title || <T _str="open street map settings" swc />}
      createButtonTitle={<T _str="apply" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={loading}
      noOverflow
    >
      <FormikSelect name="projectionSystem">
        {projectionSystemOptions.map((option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </FormikSelect>
    </CreateModal>
  );
};

export const ProjectProjectionSystemModal = memo($ProjectProjectionSystemModal);
