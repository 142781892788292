import { EditPointClusterActionValue, Segment } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';

export class EditPointClusterAction implements IAction<EditPointClusterActionValue> {
  public identifier: string;
  _previousValues?: {
    segments: Segment[];
  };

  constructor(public value: EditPointClusterActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation || annotation.type !== 'POINTCLUSTER') return;
    this._previousValues = {
      segments: annotation.segments,
    };
    const { segments } = this.value;
    annotation.segments = segments;
    return annotation;
  }
  undo(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation || annotation.type !== 'POINTCLUSTER' || !this._previousValues) {
      this._previousValues = undefined;
      return;
    }
    return (annotation.segments = this._previousValues.segments);
  }
}
