import classNames from 'classnames';
import { memo, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

interface TooltipProps {
  message: JSX.Element;
  children: React.ReactNode;
  className?: string;
  delay?: number;
}
const $Tooltip: React.FC<TooltipProps> = ({ message, children, className, delay }) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const [arrowElement, setArrowElement] = useState<any>();
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const onMouseEnter = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, delay || 0);
  }, [delay]);
  const onMouseLeave = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setShowTooltip(false);
  }, []);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  return (
    <>
      <div ref={setReferenceElement} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="cursor-pointer">
        {children}
      </div>

      {showTooltip && (
        <div
          className={classNames('text-sm bg-white dark:text-white dark:bg-[#313131] border rounded-md w-max border-neon-green', !className?.includes('p-') && 'p-1', className)}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {message}
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </>
  );
};

export const Tooltip = memo($Tooltip);
