import { AddAreaActionValue, AreaAnnotation } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { AddAnnotationAction } from './AddAnnotationAction';

export class AddAreaAction extends AddAnnotationAction<AddAreaActionValue> {
  override execute(state: PointCloudCommandManagerState) {
    const annotation = { ...this.value, type: 'AREA', identifier: this.value.annotationIdentifier } as AreaAnnotation;
    state.annotations.push(annotation);
    super.execute(state);
  }
}
