import { memo, useCallback, useState } from 'react';
import { T } from '../../../translation/src';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Input } from '../../../components/inputs/Input';
import {
  Color,
  useProjectDeleteCustomClassMutation,
  useProjectEditCustomClassColorMutation,
  useProjectEditCustomClassNameMutation,
} from '../../../types/graphqlTypes';
import { ColorPopover } from '../../../components/Popover/ColorPopover';
import { emitter } from '../../../hooks/potree/useProject';
import { IClassification } from '../../../hooks/potree/useRenderer';
import { SubmitModal } from '../../../components/Modal/SubmitModal';

const convertToRGBValue = (value: number, invert = false): number => {
  if (invert) {
    return value / 255;
  } else {
    return Math.round(value * 255);
  }
};

export interface ClassificationSettingsModalProps {
  projectId: string;
  value: IClassification;
  onClose: () => void;
  open?: boolean;
}

const $ClassificationSettingsModal: React.FC2<ClassificationSettingsModalProps> = ({
  onClose,
  open,
  projectId,
  value,
}) => {
  const [newClassName, setNewClassName] = useState<string>(value.name || '');
  const [classColor, setClassColor] = useState<Color>({
    r: convertToRGBValue(value.color[0]),
    g: convertToRGBValue(value.color[1]),
    b: convertToRGBValue(value.color[2]),
    a: value?.color[3],
  });
  const [deleteCustomClass, { loading: deleteCustomClassLoading }] = useProjectDeleteCustomClassMutation();
  const [editCustomClassName, { loading: editCustomClassNameLoading }] = useProjectEditCustomClassNameMutation();
  const [editCustomClassColor, { loading: editCustomClassColorLoading }] = useProjectEditCustomClassColorMutation();

  const onUpdate = useCallback(async () => {
    await Promise.all([
      editCustomClassName({
        variables: { customClassId: value.identifier, projectId, newName: newClassName },
      }),
      editCustomClassColor({
        variables: {
          customClassId: value.identifier,
          projectId,
          newColor: classColor,
        },
      }),
    ]);
    setTimeout(() => {
      emitter.emit('CLASSIFICATIONS_UPDATES');
    }, 1000);
    onClose();
  }, [classColor, editCustomClassColor, editCustomClassName, newClassName, onClose, projectId, value.identifier]);

  const onDelete = useCallback(async () => {
    await deleteCustomClass({
      variables: { customClassId: value.identifier, projectId },
      update: (cache) => {
        cache.evict({ id: `CustomClass:${value.identifier}` });
      },
    });
    setTimeout(() => {
      emitter.emit('CLASSIFICATIONS_UPDATES');
    }, 1000);

    onClose();
  }, [value, deleteCustomClass, projectId, onClose]);

  const loading = deleteCustomClassLoading || editCustomClassNameLoading || editCustomClassColorLoading;

  return (
    <SubmitModal
      open={open}
      onClose={onClose}
      isSubmitting={loading}
      title={<T _str="custom class" swc />}
      actionButtonTitle={<T _str="update" />}
      onCancel={onClose}
      onSubmit={onUpdate}
    >
      <div className="z-50 flex items-center">
        <ColorPopover
          value={classColor}
          onChange={(color) => {
            setClassColor(color);
          }}
        />
        <Input
          type="text"
          defaultValue={value.name}
          className="h-8 m-2"
          onChange={(value) => setNewClassName(value.target.value)}
        ></Input>
        <TrashIcon onClick={onDelete} className="w-8 h-8 hover:bg-neon-green-300 hover:dark:bg-black" />
      </div>
    </SubmitModal>
  );
};
export const ClassificationSettingsModal = memo($ClassificationSettingsModal);
