import { MapIcon } from '@heroicons/react/24/outline';
import { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { HideSelect } from '../../../../components/HideSelector';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { RendererContext } from '../../../../contexts/RendererContext';
import { RendererReadOnlyContext } from '../../../../contexts/RendererReadOnlyContext';
import { UserContext } from '../../../../contexts/UserContext';
import useOpen from '../../../../hooks/useOpen';
import { selectMap } from '../../../../redux/rendererReducer';
import { useCustomSelector } from '../../../../redux/store';
import { T, useT } from '../../../../translation/src';
import { PointCloud, Project, UploadStatus } from '../../../../types/graphqlTypes';
import {
  ProjectProjectionSystemModal,
  ProjectProjectionSystemModalProps,
} from '../../../project/ProjectProjectionSystemModal';
import { UploadPointCloudModal } from '../../../project/UploadPointCloudModal';
import { ShouldUpgradeModal } from '../../../user/ShouldUpgradeModal';
import { PointCloudLayer } from './PointCloudLayer';
import { useToggleHideMap } from '../../../../hooks/modules/project/useToggleHideMap';
import { useCADObjects } from '../../../../hooks/potree/useRenderer';
import { CadLayers } from './CadLayers';
import { AddCADLayerModal } from '../../../project/AddCADLayerModal';
import { WMSLayerModal, WMSLayerModalProps } from '../../../project/WMSLayerModal';
import { WmsLayers } from './WmsLayers';
import { WMSLayersContext } from '../../../../contexts/WmsLayersContext';

const useTranslations = () => {
  const shouldUpgradeModalDescriptionProject = useT('In order to add more projects, you need to upgrade your plan.');
  const shouldUpgradeModalDescriptionUpload = useT(
    'In order to add more simultaneous uploads, you need to upgrade your plan.'
  );
  return { shouldUpgradeModalDescriptionProject, shouldUpgradeModalDescriptionUpload };
};

interface LayersProps {
  project?: Pick<Project, 'settings' | 'id' | 'mapVisible'> & {
    pointClouds: Pick<
      PointCloud,
      'cloudName' | 'id' | 'visible' | 'displayName' | 'uploadStatus' | 'availableClasses'
    >[];
  };
}
const $Layers: React.FC2<LayersProps> = ({ project }) => {
  const pointClouds = project?.pointClouds || [];
  const dispatch = useDispatch();
  const isReadOnly = useContext(RendererReadOnlyContext);
  const { organisationId = '' } = useParams();
  const translations = useTranslations();
  const {
    onClose: closeMapModal,
    open: mapModalOpen,
    onOpenWithValue,
    value: mapModalValue,
  } = useOpen<{ origin: 'MAP' | 'WMS' }>();
  const { onClose: closeCADLayerModal, onOpen: openCADLayerModal, open: CADLayerModalOpen } = useOpen();
  const {
    onClose: closeWmsLayerModal,
    onOpen: openWmsLayerModal,
    open: wmsLayerModalOpen,
    onOpenWithValue: openWmsLayerModalWithValue,
    value: wmsLayerModalValue,
  } = useOpen<WMSLayerModalProps['initialValues']>();
  const {
    onClose: closePointCloudUploadModal,
    onOpen: openPointCloudUploadModal,
    open: pointCloudUploadModalOpen,
  } = useOpen();
  const {
    open: shouldUpgradeModalOpen,
    onClose: closeShouldUpgradeModal,
    onOpenWithValue: openShouldUpgradeModal,
    value: shouldUpgradeModalDescription,
  } = useOpen<string>();
  const { selectedPointCloudName, mapSelected } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedPointCloudName', 'mapSelected']
  );
  const currentUser = useContext(UserContext);
  const renderContext = useContext(RendererContext);
  const viewer = renderContext.viewer;
  const [mapVisible, onToggleHideMap] = useToggleHideMap({ project });
  const [{ cadLayers }] = useCADObjects();
  const { wmsLayers } = useContext(WMSLayersContext);

  const onClickHideSelect = useCallback(() => {
    if (project?.settings?.projectionSystem) {
      onToggleHideMap();
    } else {
      onOpenWithValue({ origin: 'MAP' });
    }
  }, [project?.settings?.projectionSystem, onToggleHideMap, onOpenWithValue]);

  const onClickMap = useCallback(() => {
    if (project?.settings?.projectionSystem) return dispatch(selectMap({}));
    return onOpenWithValue({ origin: 'MAP' });
  }, [dispatch, onOpenWithValue, project?.settings?.projectionSystem]);

  const onMapModalSuccess: Required<ProjectProjectionSystemModalProps>['onSuccess'] = useCallback(() => {
    if (mapModalValue?.origin === 'MAP') onToggleHideMap();
    else if (mapModalValue?.origin === 'WMS') openWmsLayerModal();
    closeMapModal();
  }, [closeMapModal, mapModalValue?.origin, openWmsLayerModal, onToggleHideMap]);

  const onAddPointCloud = useCallback(() => {
    const organisation = currentUser.organisations.find((organisation) => organisation.id === organisationId);
    if (!organisation) return;
    if (organisation.subscription.canAddPointClouds && organisation.subscription.canAddSimultaneousUploads)
      return openPointCloudUploadModal();
    return openShouldUpgradeModal(
      !organisation.subscription.canAddPointClouds
        ? translations.shouldUpgradeModalDescriptionProject
        : translations.shouldUpgradeModalDescriptionUpload
    );
  }, [
    currentUser.organisations,
    openPointCloudUploadModal,
    openShouldUpgradeModal,
    organisationId,
    translations.shouldUpgradeModalDescriptionProject,
    translations.shouldUpgradeModalDescriptionUpload,
  ]);

  const onOpenWmsLayerModal = useCallback(() => {
    if (project?.settings?.projectionSystem) return openWmsLayerModal();
    return onOpenWithValue({ origin: 'WMS' });
  }, [onOpenWithValue, openWmsLayerModal, project?.settings?.projectionSystem]);

  const projectionSystem = project?.settings?.projectionSystem;

  return (
    <>
      <div className="flex flex-col flex-grow mt-8 overflow-auto divide-y">
        {pointClouds.map((pointCloud) => (
          <PointCloudLayer
            pointCloud={pointCloud}
            active={selectedPointCloudName === pointCloud.cloudName}
            key={pointCloud.id}
            refreshSuggested={
              pointCloud.uploadStatus === UploadStatus.Success &&
              !viewer?.scene.pointclouds.some((p) => p.identifier === pointCloud.id)
            }
            isLastPointCloud={pointClouds.length === 1}
          />
        ))}
        <SelectItemContent
          icon={MapIcon}
          idle={!mapVisible}
          selectStyle="primary"
          onClick={onClickMap}
          active={mapSelected}
          className="pl-8"
        >
          <T _str="map" swc />
          <div className="flex justify-end flex-grow">
            <HideSelect hidden={!mapVisible} onClick={onClickHideSelect} />
          </div>
        </SelectItemContent>
        {cadLayers.length ? <CadLayers className="flex flex-col" cadLayers={cadLayers} /> : null}
        {wmsLayers.length ? (
          <WmsLayers className="flex flex-col" wmsLayers={wmsLayers} onUpdateLayer={openWmsLayerModalWithValue} />
        ) : null}
        <div className="flex items-end flex-grow w-full">
          {!isReadOnly && (
            <div className="w-full">
              <Button variant="secondary" onClick={onAddPointCloud} className="w-full mt-2" size="sm">
                <T _str="add pointcloud" swc />
              </Button>
              {projectionSystem && (
                <Button variant="secondary" onClick={openCADLayerModal} className="w-full" size="sm">
                  <T _str="add CAD file" swc />
                </Button>
              )}
              <Button variant="secondary" onClick={onOpenWmsLayerModal} className="w-full" size="sm">
                <T _str="add WM(T)S" swc />
              </Button>
            </div>
          )}
        </div>
      </div>
      {project && (
        <ProjectProjectionSystemModal
          open={mapModalOpen}
          onClose={closeMapModal}
          project={project}
          onSuccess={onMapModalSuccess}
          title={
            mapModalValue?.origin === 'WMS' ? (
              <T _str="first select a projection system before adding a wms layer" swc />
            ) : null
          }
        />
      )}
      <UploadPointCloudModal onClose={closePointCloudUploadModal} open={pointCloudUploadModalOpen} />
      <ShouldUpgradeModal
        open={shouldUpgradeModalOpen}
        onClose={closeShouldUpgradeModal}
        description={shouldUpgradeModalDescription || ''}
      />
      {projectionSystem && (
        <AddCADLayerModal open={CADLayerModalOpen} onClose={closeCADLayerModal} projectionSystem={projectionSystem} />
      )}
      <WMSLayerModal open={wmsLayerModalOpen} onClose={closeWmsLayerModal} initialValues={wmsLayerModalValue} />
    </>
  );
};

export const Layers = memo($Layers);
