import { CogIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { GradientPopover } from '../../../components/Popover/GradientPopover';
import { Input } from '../../../components/inputs/Input';
import { Option, Select, SelectProps } from '../../../components/inputs/Select';
import {
  EnumAppearanceTypes,
  useAppearanceTypeTranslations,
  usePointCloudProperties,
} from '../../../hooks/potree/usePointCloudProperties';
import { useClassificationTranslations } from '../../../hooks/translations/useClassifications';
import { useConvertedMeasurementInputProps } from '../../../hooks/useConvertedMeasurementInputProps';
import { ConvertType, useCondMeterToFeet, useFormatNumber } from '../../../hooks/useFormatNumber';
import { useRerender } from '../../../hooks/useRerender';
import { T, useT } from '../../../translation/src';
import { PointCloud, useProjectGenerateClassificationsMutation } from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { ResolutionFilter } from './ResolutionFilter';
import { ClassificationSettingsModal } from '../../user/ClassificationSettingsModal';
import useOpen from '../../../hooks/useOpen';
import { IClassification } from '../../../hooks/potree/useRenderer';

const useTranslations = () => {
  const min = useT('min');
  const max = useT('max');
  return { min, max };
};

const $ClassificationFilter: React.FC2<{ availableClasses: number[]; projectId: string }> = ({
  availableClasses,
  projectId,
}) => {
  const rerender = useRerender();
  const classificationTranslations = useClassificationTranslations();
  const [{ classifications }, helpers] = usePointCloudProperties();
  const classificationOptions = useMemo(
    () => classifications.filter((classification) => availableClasses.includes(classification.code)),
    [classifications, availableClasses],
  );

  const classificationsValue = useMemo(
    () => classificationOptions.filter((classification) => classification.visible),
    [classificationOptions],
  );

  const onChangeClassification: SelectProps<any>['onChange'] = useCallback(
    (event) => {
      if (!event.target.value?.length) {
        classificationOptions.forEach((classification) => {
          helpers.onChangeClassificationVisibility({
            code: +classification.code,
            checked: false,
          });
        });
        rerender();
        return;
      }
      const newClassifications = event.target.value as any[];
      const newClassificationCodes = newClassifications.map((classification) => classification.code);
      const previousClassifications = classificationOptions.filter((classification) => classification.visible);
      const previousClassificationCodes = previousClassifications.map((classification) => classification.code);
      const classification =
        newClassifications.length > previousClassifications.length
          ? newClassifications.find((classification) => !previousClassificationCodes.includes(classification.code))
          : previousClassifications.find((classification) => !newClassificationCodes.includes(classification.code));
      if (!classification) return;
      helpers.onChangeClassificationVisibility({
        code: +classification.code,
        checked: !classification.visible,
      });
      rerender();
    },
    [classificationOptions, helpers, rerender],
  );

  const {
    onOpenWithValue: openClassificationSettingsModal,
    onClose: closeClassificationSettingsModal,
    open: ClassificationSettingsModalOpen,
    value: customClassification,
  } = useOpen<IClassification>();

  return (
    <Property title={<T _str="classification" swc />}>
      <Select<any>
        className="w-full"
        onChange={onChangeClassification}
        value={classificationsValue}
        name="classification"
        multiple
        clearable
      >
        {classificationOptions.map((value) => {
          const isCustomClass = value.code >= 64;
          return (
            <Option key={value.code} value={value}>
              <div className="flex">
                <div className="flex items-center flex-grow truncate">
                  <div
                    className="flex-shrink-0 w-4 h-4 mr-1 border border-gray-500 rounded "
                    style={{
                      backgroundColor: `rgb(${value.color.map((c: number) => c * 255).join(', ')})`,
                    }}
                  />
                  {classificationTranslations[value.name] || value.name}
                </div>
                {isCustomClass && (
                  <div className="flex-shrink-0 ml-2">
                    <CogIcon
                      className="w-4 h-4 text-[#6F7173] hover:text-black dark:hover:text-neon-green-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        openClassificationSettingsModal(value);
                      }}
                    />
                  </div>
                )}
              </div>
            </Option>
          );
        })}
      </Select>
      {customClassification && (
        <ClassificationSettingsModal
          onClose={closeClassificationSettingsModal}
          open={ClassificationSettingsModalOpen}
          projectId={projectId}
          value={customClassification}
        />
      )}
    </Property>
  );
};
const ClassificationFilter = memo($ClassificationFilter);

interface PointCloudPropertiesProps {
  pointCloud: Pick<PointCloud, 'cloudName' | 'hasClassifications' | 'availableClasses' | 'pointCount' | 'id'>;
}
const $PointCloudProperties: React.FC2<PointCloudPropertiesProps> = ({ pointCloud }) => {
  const { condMeterToFeet } = useCondMeterToFeet();
  const [
    {
      appearanceValue,
      heightMin,
      heightMax,
      initialHeightMin,
      initialHeightMax,
      heightGradient,
      initialIntensityMax,
      initialIntensityMin,
      intensityMax,
      intensityMin,
      noIntensity,
    },
    helpers,
  ] = usePointCloudProperties();

  const resetHeights = useCallback(() => {
    // We need to do this because the component is uncontrolled. It's hard to have this component controlled with the convertions to meter/feet
    const { heightMin, heightMax } = helpers.onResetHeight();
    const heightMinElement = document.getElementById('inputHeightMin');
    const heightMaxElement = document.getElementById('inputHeightMax');
    if (heightMinElement) (heightMinElement as HTMLInputElement).value = condMeterToFeet(heightMin).toFixed(2);
    if (heightMaxElement) (heightMaxElement as HTMLInputElement).value = condMeterToFeet(heightMax).toFixed(2);
  }, [condMeterToFeet, helpers]);

  const { projectId = '' } = useParams();
  const translations = useTranslations();
  const appearanceTranslations = useAppearanceTypeTranslations();
  const [generateClassifications, { loading: generateClassificationsLoading }] =
    useProjectGenerateClassificationsMutation();
  const convertMeasurementInputProps = useConvertedMeasurementInputProps();

  const onChangeAppearance: SelectProps<EnumAppearanceTypes>['onChange'] = useCallback(
    (event) => {
      const appearance = event.target.value;
      helpers.onChangeAppearance({ value: appearance });
    },
    [helpers],
  );

  const onGenerateClassifications = useCallback(() => {
    generateClassifications({ variables: { cloudName: pointCloud.cloudName, projectId } });
  }, [generateClassifications, pointCloud.cloudName, projectId]);

  const appearanceOptions = Object.values(EnumAppearanceTypes).filter((appearance) => {
    if (noIntensity && appearance === EnumAppearanceTypes.INTENSITY) return false;
    return true;
  });

  const { formatNumber } = useFormatNumber();

  return (
    <div className="flex flex-col flex-grow space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="point cloud" cap />
        </Property>
        <Property title={<T _str="number of points" swc />}>
          {formatNumber(pointCloud.pointCount || 0, { convertType: ConvertType.NONE, addUnit: false })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="Colorization" swc />}>
        <Property title={<T _str="Field" swc />}>
          <Select<EnumAppearanceTypes>
            className="w-full"
            onChange={onChangeAppearance}
            value={appearanceValue || EnumAppearanceTypes.RGBA}
            name="visualization"
          >
            {appearanceOptions.map((visualization) => (
              <Option key={visualization} value={visualization}>
                {appearanceTranslations[visualization]}
              </Option>
            ))}
          </Select>
        </Property>
        <Property title={<T _str="gradient" swc />} className="mb-2">
          <div className="flex items-center w-full">
            <GradientPopover value={heightGradient} onChange={helpers.onChangeHeightGradient} />
          </div>
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="filters" swc />}>
        <ResolutionFilter />
        {pointCloud.hasClassifications ? (
          <ClassificationFilter availableClasses={pointCloud.availableClasses} projectId={projectId} />
        ) : (
          <Property title={<T _str="classification" swc />}>
            <div>
              <Button
                variant="secondary"
                size="sm"
                className="w-full"
                loading={generateClassificationsLoading}
                loadingTitle={<T _str="calculating" swc />}
                onClick={onGenerateClassifications}
              >
                <T swc _str="generate classifications" />
              </Button>
            </div>
          </Property>
        )}
        <Property title={<T _str="height" swc />} className="mb-2">
          <div className="flex items-center w-full">
            <Input
              id="inputHeightMin"
              className="w-full mr-2"
              placeholder={translations.min}
              onChange={helpers.onChangeHeightMin}
              {...convertMeasurementInputProps({ defaultValue: heightMin })}
              min={condMeterToFeet(initialHeightMin).toFixed(2)}
              max={condMeterToFeet(initialHeightMax).toFixed(2)}
            />
            <Input
              id="inputHeightMax"
              className="w-full mr-2"
              placeholder={translations.max}
              onChange={helpers.onChangeHeightMax}
              {...convertMeasurementInputProps({ defaultValue: heightMax })}
              min={condMeterToFeet(initialHeightMin).toFixed(2)}
              max={condMeterToFeet(initialHeightMax).toFixed(2)}
            />
            <ArrowPathIcon
              className="flex-shrink-0 w-5 h-5 cursor-pointer text-neon-green-500 dark:text-neon-green-300"
              onClick={resetHeights}
            />
          </div>
        </Property>
        {!noIntensity && (
          <Property title={<T _str="intensity" swc />}>
            <div className="flex items-center w-full">
              <Input
                className="w-full mr-2"
                placeholder={translations.min}
                type="number"
                onChange={helpers.onChangeIntensityMin}
                value={Math.round(intensityMin)}
                min={Math.round(initialIntensityMin)}
                max={Math.round(initialIntensityMax)}
              />
              <Input
                className="w-full mr-2"
                placeholder={translations.max}
                type="number"
                onChange={helpers.onChangeIntensityMax}
                value={Math.round(intensityMax)}
                min={Math.round(initialIntensityMin)}
                max={Math.round(initialIntensityMax)}
              />
              <ArrowPathIcon
                className="flex-shrink-0 w-5 h-5 cursor-pointer text-neon-green-500 dark:text-neon-green-300"
                onClick={helpers.onResetIntensity}
              />
            </div>
          </Property>
        )}
      </PropertiesSection>
    </div>
  );
};

export const PointCloudProperties = memo($PointCloudProperties);
