import { memo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { UserContext } from '../../../contexts/UserContext';
import { T, useT } from '../../../translation/src';
import { OrganisationSubscription, SubscriptionType, useOrganisationsQuery } from '../../../types/graphqlTypes';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useFormatNumber } from '../../../hooks/useFormatNumber';
import moment from 'moment';
import classNames from 'classnames';
import { useSubscriptionTypeTranslations } from '../../../hooks/translations/useSubscriptionTypeTranslations';

export const useStatisticsTranslations = () => {
  const statistics = useT('statistics', { swc: true });
  const subscription = useT('subscription', { swc: true });
  const subscriptionEndDate = useT('subscription end date', { swc: true });
  const users = useT('users', { swc: true });
  const projects = useT('projects', { swc: true });
  const pointCount = useT('point count', { swc: true });
  const monthlyPointCount = useT('monthly point count', { swc: true });
  const joinedDate = useT('joined date', { swc: true });
  return {
    statistics,
    subscription,
    subscriptionEndDate,
    users,
    projects,
    pointCount,
    joinedDate,
    monthlyPointCount,
  };
};

interface SubscriptionPillProps {
  subscription: Omit<
    OrganisationSubscription,
    'limits' | 'canAddPointClouds' | 'canAddSimultaneousUploads' | 'isFreeTrial' | 'simultaneousUploadsLeft'
  >;
}
const $SubscriptionPill: React.FC<SubscriptionPillProps> = ({ subscription }) => {
  const subscriptionTypeTranslations = useSubscriptionTypeTranslations();
  const invalidSubscription = moment().isAfter(subscription.endDate);
  const isFreeTrial = subscription.type === SubscriptionType.Free;
  return (
    <div
      className={classNames(
        'p-1 text-xs rounded',
        invalidSubscription ? 'bg-red-700' : isFreeTrial ? 'bg-blue-600' : 'bg-green-700'
      )}
    >
      {subscriptionTypeTranslations[subscription.type]}
    </div>
  );
};
export const SubscriptionPill = memo($SubscriptionPill);

interface PropertyProps {
  title: React.ReactNode;
  count?: number;
  nrRemaining?: number;
  date?: Date;
}
const $StatsProperty: React.FC2<PropertyProps> = ({ title, count, nrRemaining, date, children }) => {
  const { formatNumber } = useFormatNumber();
  const { formatDate } = useFormatDate();
  return (
    <div className="flex justify-between py-3 text-sm">
      <div>{title}</div>
      <div>
        {children}
        {date && formatDate(date)}
        {count && formatNumber(count, {})}{' '}
        {nrRemaining && (
          <span className="text-xs text-gray-400">
            ({formatNumber(nrRemaining, {})} <T _str="left" />)
          </span>
        )}
      </div>
    </div>
  );
};
export const StatsProperty = memo($StatsProperty);

const $BillingCompanyStatistics: React.FC2 = () => {
  const translations = useStatisticsTranslations();
  const { organisationId = '' } = useParams();
  const user = useContext(UserContext);
  const { data: organisationsData } = useOrganisationsQuery(
    user ? { variables: { includeIds: [organisationId] } } : {}
  );
  const organisations = organisationsData?.organisations;
  const organisation = organisations?.find((org) => org.id === organisationId);
  const { xsDevice } = useDeviceSize();

  if (!organisation) return null;

  return (
    <div>
      <div className={xsDevice ? 'w-full' : 'w-1/2'}>
        <Card noPadding title={translations.statistics} className="w-full">
          <div className="flex flex-col px-4 py-2 divide-y divide-gray-700">
            <StatsProperty title={translations.subscription}>
              <SubscriptionPill subscription={organisation.subscription} />
            </StatsProperty>
            <StatsProperty title={translations.subscriptionEndDate} date={organisation.subscription.endDate} />
            <StatsProperty title={translations.joinedDate} date={organisation.createdAt} />
            <StatsProperty title={translations.users} count={organisation.usersAmount} />
            <StatsProperty
              title={translations.projects}
              count={organisation.stats.totalProjects}
              nrRemaining={Infinity}
            />
            <StatsProperty
              title={translations.pointCount}
              count={organisation.stats.totalPointCount}
              nrRemaining={organisation.stats.totalPointCountLeft}
            />
            <StatsProperty
              title={translations.monthlyPointCount}
              count={organisation.stats.totalMonthlyPointCount}
              nrRemaining={organisation.stats.totalMonthlyPointCountLeft}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export const BillingCompanyStatistics = memo($BillingCompanyStatistics);
