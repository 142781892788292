import { AdjustmentsVerticalIcon, CalendarIcon, PaperAirplaneIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { ChangeEventHandler, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../components/Card';
import { ListItem } from '../../components/ListItem';
import { DotsPopover } from '../../components/Popover/DotsPopover';
import { Input } from '../../components/inputs/Input';
import { Option, Select, SelectProps } from '../../components/inputs/Select';
import { UserContext } from '../../contexts/UserContext';
import { useSubscriptionTypeTranslations } from '../../hooks/translations/useSubscriptionTypeTranslations';
import useOpen from '../../hooks/useOpen';
import { OrganisationDeleteModal } from '../../modules/organisation/OrganisationDeleteModal';
import { UpdateLimitsModal } from '../../modules/superadmin/UpdateLimitsModal';
import { UpdateTrialDateModal } from '../../modules/superadmin/UpdateTrialDateModal';
import { T, useT } from '../../translation/src';
import {
  SubscriptionType,
  SuperadminOrganisationsQuery,
  useSuperadminOrganisationsQuery,
} from '../../types/graphqlTypes';
import {
  StatsProperty,
  SubscriptionPill,
  useStatisticsTranslations,
} from '../../modules/billing/BillingCompanyStatistics';

interface OrganisationCardProps {
  organisation: SuperadminOrganisationsQuery['superadminOrganisations'][0];
}
export const $OrganisationCard: React.FC2<OrganisationCardProps> = ({ organisation }) => {
  const translations = useStatisticsTranslations();
  const { onClose: closeTrialDateModal, open: trialDateModalOpen, onOpen: openTrialDateModal } = useOpen();
  const { onClose: closeLimitsModal, open: limitsModalOpen, onOpen: openLimitsModal } = useOpen();
  const {
    onClose: closeOrganisationDeleteModal,
    open: organisationDeleteModalOpen,
    onOpen: openOrganisationDeleteModal,
  } = useOpen();

  const trialDateInitialValues = useMemo(() => {
    return {
      endDate: moment(organisation.subscription.customLimits?.trialEndDate || organisation.subscription.endDate).format(
        'YYYY-MM-DD',
      ),
    };
  }, [organisation.subscription.customLimits?.trialEndDate, organisation.subscription.endDate]);

  const limitsInitialValues = useMemo(() => {
    return {
      maxPointCount:
        organisation.subscription.customLimits?.maxPointCount || organisation.subscription.limits.maxPointCount,
      maxMonthlyPointCount:
        organisation.subscription.customLimits?.maxMonthlyPointCount ||
        organisation.subscription.limits.maxMonthlyPointCount,
      maxUploadsInProgress:
        organisation.subscription.customLimits?.maxUploadsInProgress ||
        organisation.subscription.limits.maxUploadsInProgress,
    };
  }, [organisation.subscription]);

  const onNavigateToOrganisation = useCallback(() => {
    window.open(`/organisations/${organisation.id}`, '_blank');
  }, [organisation.id]);

  return (
    <>
      <Card noPadding>
        <div className="relative flex bg-gray dark:bg-gray-900">
          <span className="p-4">{organisation.name}</span>
          <DotsPopover>
            {organisation.subscription.type === SubscriptionType.Free && (
              <ListItem onClick={openTrialDateModal} icon={CalendarIcon}>
                <T _str="extend trial period" swc />
              </ListItem>
            )}
            <ListItem onClick={openLimitsModal} icon={AdjustmentsVerticalIcon}>
              <T _str="update limits" swc />
            </ListItem>
            <ListItem onClick={onNavigateToOrganisation} icon={PaperAirplaneIcon}>
              <T _str="Go to organisation" swc />
            </ListItem>
            <ListItem onClick={openOrganisationDeleteModal} icon={TrashIcon}>
              <T _str="Delete organisation" swc />
            </ListItem>
          </DotsPopover>
        </div>
        <div className="flex flex-col px-4 py-2 divide-y divide-gray-700">
          <StatsProperty title={translations.subscription}>
            <SubscriptionPill subscription={organisation.subscription} />
          </StatsProperty>
          <StatsProperty title={translations.subscriptionEndDate} date={organisation.subscription.endDate} />
          <StatsProperty title={translations.joinedDate} date={organisation.createdAt} />
          <StatsProperty title={translations.users} count={organisation.stats.totalUsers} />
          <StatsProperty
            title={translations.projects}
            count={organisation.stats.totalProjects}
            nrRemaining={Infinity}
          />
          <StatsProperty
            title={translations.pointCount}
            count={organisation.stats.totalPointCount}
            nrRemaining={organisation.stats.totalPointCountLeft}
          />
          <StatsProperty
            title={translations.monthlyPointCount}
            count={organisation.stats.totalMonthlyPointCount}
            nrRemaining={organisation.stats.totalMonthlyPointCountLeft}
          />
          <StatsProperty title="ID">{organisation.id}</StatsProperty>
        </div>
      </Card>
      <UpdateTrialDateModal
        open={trialDateModalOpen}
        onClose={closeTrialDateModal}
        organisationId={organisation.id}
        initialValues={trialDateInitialValues}
      />
      <UpdateLimitsModal
        open={limitsModalOpen}
        onClose={closeLimitsModal}
        organisationId={organisation.id}
        initialValues={limitsInitialValues}
      />
      <OrganisationDeleteModal
        open={organisationDeleteModalOpen}
        onClose={closeOrganisationDeleteModal}
        organisation={organisation}
      />
    </>
  );
};
const OrganisationCard = memo($OrganisationCard);

const useTranslations = () => {
  const searchOrganisation = useT('search organisation', { swc: true });
  return { searchOrganisation };
};

export const AdminCenter: React.FC = () => {
  const { data: superadminOrganisationsData } = useSuperadminOrganisationsQuery();
  const organisations = superadminOrganisationsData?.superadminOrganisations || [];
  const translations = useTranslations();
  const subscriptionTypeTranslations = useSubscriptionTypeTranslations();
  const [filters, setFilters] = useState({ name: '', subscriptionType: undefined as SubscriptionType | undefined });
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();

  const onChangeFilterName: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setFilters((filters) => ({ ...filters, name: event.target.value }));
  }, []);
  const onChangeFilterSubscriptionType: SelectProps['onChange'] = useCallback((event) => {
    setFilters((filters) => ({ ...filters, subscriptionType: event.target.value as SubscriptionType }));
  }, []);

  const filteredOrganisations = organisations
    .filter((organisation) => {
      if (!filters.name && !filters.subscriptionType) return organisation;
      if (organisation.name && !organisation.name.toLowerCase().includes(filters.name.toLowerCase())) return false;
      if (filters.subscriptionType && filters.subscriptionType !== organisation.subscription.type) return false;
      return true;
    })
    .reverse();

  useEffect(() => {
    if (currentUser && !currentUser.isSuperAdmin) return navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.isSuperAdmin]);

  if (!currentUser?.isSuperAdmin) return null;

  return (
    <div className="flex flex-col w-full h-full px-14 dark:bg-[#313131] dark:text-white">
      <div className="w-full pt-8 text-xl">
        <T _str="admin center" swc />
      </div>
      <hr className="my-4 border-t border-gray-300 dark:border-gray-400" />
      <div className="flex mt-5 space-x-4">
        <Input className="w-52" placeholder={`${translations.searchOrganisation}...`} onChange={onChangeFilterName} />
        <Select
          onChange={onChangeFilterSubscriptionType}
          value={filters.subscriptionType || ''}
          name="subscriptionType"
          isLabelPlaceholder
          placeholder={<T _str="subscription type" swc />}
          className="w-60"
          clearable
        >
          {Object.values(SubscriptionType).map((type) => (
            <Option key={type} value={type}>
              {subscriptionTypeTranslations[type]}
            </Option>
          ))}
        </Select>
      </div>
      <div className="w-full h-full mt-10 overflow-auto">
        <div className="grid w-full grid-cols-3 gap-8 rounded-md">
          {filteredOrganisations.map((organisation) => (
            <OrganisationCard key={organisation.id} organisation={organisation} />
          ))}
        </div>
      </div>
    </div>
  );
};
