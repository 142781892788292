import { useT } from '../../translation/src';

export const useClassificationTranslations = () => {
  const neverClassified = useT('never classified', { swc: true });
  const unclassified = useT('unclassified', { swc: true });
  const ground = useT('ground', { swc: true });
  const lowVegetation = useT('low vegetation', { swc: true });
  const mediumVegetation = useT('medium vegetation', { swc: true });
  const highVegetation = useT('high vegetation', { swc: true });
  const building = useT('building', { swc: true });
  const lowPointNoise = useT('low point (noise)', { swc: true });
  const keyPoint = useT('key-point', { swc: true });
  const water = useT('water', { swc: true });
  const rail = useT('rail', { swc: true });
  const roadSurface = useT('road surface', { swc: true });
  const overlap = useT('overlap', { swc: true });
  const wireGuard = useT('wire - guard', { swc: true });
  const wireConductor = useT('wire - conductor', { swc: true });
  const transmissionTower = useT('transmission tower', { swc: true });
  const wireStructureConnector = useT('wire-structure connector', { swc: true });
  const bridgeDeck = useT('bridge deck', { swc: true });
  const highNoise = useT('high noise', { swc: true });
  const overheadStructure = useT('overhead structure', { swc: true });
  const ignoredGround = useT('ignored ground', { swc: true });
  const snow = useT('snow', { swc: true });
  const temporalExclusion = useT('temporal exclusion', { swc: true });

  return {
    neverClassified,
    unclassified,
    ground,
    lowVegetation,
    mediumVegetation,
    highVegetation,
    building,
    lowPointNoise,
    keyPoint,
    water,
    rail,
    roadSurface,
    overlap,
    wireGuard,
    wireConductor,
    transmissionTower,
    wireStructureConnector,
    bridgeDeck,
    highNoise,
    overheadStructure,
    ignoredGround,
    snow,
    temporalExclusion,
  } as { [key: string]: string };
};
