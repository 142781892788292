import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUserAction } from '../../redux/store';
import { T } from '../../translation/src';

export const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const onLogout = useCallback(async () => {
    try {
      await fetch(`${import.meta.env.VITE_NX_SERVER}/auth/logout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      dispatch(logoutUserAction({}));
      apolloClient.resetStore();
      Sentry.setUser(null);
      navigate('/login');
    } catch (e) {}
  }, [apolloClient, dispatch, navigate]);

  useEffect(() => {
    onLogout();
  }, [onLogout]);

  return (
    <div className="flex items-center justify-center w-full h-full dark:bg-[#313131]">
      <div className="text-2xl font-bold">
        <T _str="Logging out" />
        ....
      </div>
    </div>
  );
};
