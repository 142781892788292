export const replaceURLParams = ({ params, search = '' }: { params: { [key: string]: string }; search?: string }) => {
  const urlParams = new URLSearchParams(search);
  Object.entries(params).forEach(([key, value]) => {
    if (value) urlParams.set(key, value);
  });
  return urlParams.toString();
};

export const createURLParams = ({ params }: { params: { [key: string]: string } }) => {
  return replaceURLParams({ params });
};
