import gql from 'graphql-tag';

export const NOTIFICATIONS = gql`
  query notifications {
    notifications {
      id
      type
      isRead
      createdAt
      ... on PointCloudConvertDoneNotification {
        organisationId
        meta {
          projectId
          projectName
          uploadStatus: status
        }
      }
      ... on PointCloudCalculationDoneNotification {
        organisationId
        meta {
          projectId
          projectName
          status
          annotationIdentifier
        }
      }
      ... on UserAcceptedInvitationNotification {
        organisationId
        meta {
          organisationName
          userName
        }
      }
      ... on PointCloudExportDoneNotification {
        organisationId
        meta {
          projectId
          projectName
        }
      }
    }
  }
`;

export const NOTIFICATIONS_MARK_AS_READ = gql`
  mutation notificationsMarkAsRead($ids: [ID!]) {
    notificationsMarkAsRead(ids: $ids)
  }
`;
