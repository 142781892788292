import { Form, Formik, FormikConfig } from 'formik';
import { useCallback, useContext, useMemo } from 'react';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { FormikInput } from '../../components/formik/FormInput';
import { UserContext } from '../../contexts/UserContext';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';
import { useUserUpdateMutation } from '../../types/graphqlTypes';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import classNames from 'classnames';

const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().trim().required(translations.isRequired),
        lastName: yup.string().trim().required(translations.isRequired),
        email: yup.string().trim().email(translations.validEmail).required(translations.isRequired),
      }),
    [translations]
  );

  return schema;
};

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

const useTranslations = () => {
  const email = useT('email', { swc: true });
  const firstName = useT('first name', { swc: true });
  const lastName = useT('last name', { swc: true });
  return { email, firstName, lastName };
};

export const Profile: React.FC2 = () => {
  const translations = useTranslations();
  const currentUser = useContext(UserContext);
  const validationSchema = useValidationSchema();
  const [updateUser, { loading: updateUserLoading }] = useUserUpdateMutation();
  const { mdDevice } = useDeviceSize();

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      await updateUser({ variables: values });
      helpers.resetForm({ values });
    },
    [updateUser]
  );

  const initialValues = useMemo(
    () => ({ email: currentUser.email, firstName: currentUser.firstName, lastName: currentUser.lastName }),
    [currentUser.email, currentUser.firstName, currentUser.lastName]
  );

  return (
    <div className="flex flex-col w-full h-full px-14 dark:bg-[#313131] dark:text-white">
      <div className="z-20 w-full pt-8 text-xl">
        <div>
          <T _str="profile" swc />
        </div>
      </div>
      <hr className="my-4 border-t border-gray-300" />
      <div
        className={classNames(
          'flex flex-col p-4 bg-[white] border border-gray-300 rounded dark:bg-[#242424]',
          mdDevice ? 'w-full' : 'w-1/2'
        )}
      >
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {/* @ts-ignore */}
          <Form>
            <FormikInput name="firstName" placeholder={translations.firstName} label={translations.firstName} />
            <FormikInput name="lastName" placeholder={translations.lastName} label={translations.lastName} />
            <FormikInput name="email" placeholder={translations.email} label={translations.email} />
            <div className="text-sm italic text-red-500">
              <T _str="changing your email will also change your login credentials" swc />
            </div>
            <Button type="submit" variant="primary" className="mt-6" loading={updateUserLoading}>
              <T _str="update profile" swc />
            </Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
