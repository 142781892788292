import { useParams } from 'react-router-dom';

// Add additional :param variables here
type RouteParams = 'organisationId';

export function useRouteParams<T extends RouteParams>(routeParams: Array<T>) {
  const fetchedRouteParams = useParams<Record<T, string>>();

  for (const param of routeParams) {
    const requestedParam = fetchedRouteParams[param as unknown as keyof typeof fetchedRouteParams];
    if (requestedParam == null || requestedParam === '') {
      throw new Error(`${param} is missing in URL`);
    }
  }
  return fetchedRouteParams as unknown as Record<T, string>;
}
