import { memo, useMemo } from 'react';
import { useClassifications } from '../../../hooks/potree/useRenderer';
import { useClassificationTranslations } from '../../../hooks/translations/useClassifications';
import { ConvertType, useFormatNumber } from '../../../hooks/useFormatNumber';
import { T } from '../../../translation/src';
import { AnnotationLabels, CadPoint, PointAnnotation } from '../../../types/graphqlTypes';
import { EditableColorProperty, StaticColorProperty } from './ColorProperties';
import { PropertiesSection, Property } from './PropertiesSection';
import { HideLabelProperty } from './HideLabel';

interface PointPropertiesProps {
  annotation: PointAnnotation;
}
const $PointProperties: React.FC2<PointPropertiesProps> = ({ annotation }) => {
  const [{ classifications }] = useClassifications();
  const { formatNumber } = useFormatNumber();

  const classification = useMemo(() => {
    const classification =
      (annotation.classification && classifications?.find((c) => c.code === annotation.classification)) || undefined;
    return classification;
  }, [annotation.classification, classifications]);

  const classificationTranslations = useClassificationTranslations();

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="point" swc />
        </Property>
        <Property title={<T _str="classification" swc />}>
          {classification ? classificationTranslations[classification.name] : '-'}
        </Property>
        <Property title={<T _str="intensity" swc />}>
          {annotation.intensity != undefined ? Math.round(annotation.intensity) : '-'}
        </Property>
        <StaticColorProperty color={annotation.color} />
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={<T _str="x" swc />}>
          {formatNumber(annotation.position.x, { convertType: ConvertType.DISTANCE })}
        </Property>
        <Property title={<T _str="y" swc />}>
          {formatNumber(annotation.position.y, { convertType: ConvertType.DISTANCE })}
        </Property>
        <Property title={<T _str="z" swc />}>
          {formatNumber(annotation.position.z, { convertType: ConvertType.DISTANCE })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <HideLabelProperty annotations={[annotation]} label={AnnotationLabels.Coordinate} />
        <EditableColorProperty annotations={[annotation]} />
      </PropertiesSection>
    </div>
  );
};
export const PointProperties = memo($PointProperties);

const $CadPointProperties: React.FC2<{ cadPoint: CadPoint }> = ({ cadPoint }) => {
  const { formatNumber } = useFormatNumber();

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="point" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={<T _str="x" swc />}>
          {formatNumber(cadPoint.position.x, { convertType: ConvertType.DISTANCE })}
        </Property>
        <Property title={<T _str="y" swc />}>
          {formatNumber(cadPoint.position.y, { convertType: ConvertType.DISTANCE })}
        </Property>
        <Property title={<T _str="z" swc />}>
          {formatNumber(cadPoint.position.z, { convertType: ConvertType.DISTANCE })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <StaticColorProperty color={cadPoint.color} />
      </PropertiesSection>
    </div>
  );
};
export const CadPointProperties = memo($CadPointProperties);
