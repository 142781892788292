import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  usePointCloudUploadStatusLazyQuery,
  useProjectCalculationsLazyQuery,
  useProjectExportsLazyQuery,
  useProjectRequestDownloadTokenLazyQuery,
} from '../../types/graphqlTypes';
import { pusher } from '../pusher';

export const useRealtimeUpdates = () => {
  const { projectId = '' } = useParams();
  const [fetchProjectCalculations] = useProjectCalculationsLazyQuery();
  const [fetchProject] = usePointCloudUploadStatusLazyQuery();
  const [fetchExports] = useProjectExportsLazyQuery();
  const [getDownloadToken] = useProjectRequestDownloadTokenLazyQuery();

  useEffect(() => {
    const downloadExport = async (exportId: string) => {
      if (!projectId) return;
      const { data: tokenData } = await getDownloadToken({ variables: { projectId } });
      await fetch(`${import.meta.env.VITE_NX_SERVER}/project/${projectId}/export/${exportId}/download`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: tokenData?.projectRequestDownloadToken }),
      })
        .then((response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          let filename = 'export.zip';
          if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+)"/);
            if (match) {
              filename = match[1];
            }
          }
          return response.blob().then((blob) => ({ blob, filename }));
        })
        .then(({ blob, filename }) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
    };

    const channel = pusher.subscribe(`project-${projectId}`);
    channel.bind('calculation-done', async () => {
      await fetchProjectCalculations({ variables: { projectId }, fetchPolicy: 'network-only' });
    });
    channel.bind('convert-done', async () => {
      await fetchProject({ variables: { projectId }, fetchPolicy: 'network-only' });
    });
    channel.bind('export-done', async ({ exportId }: { exportId: string }) => {
      await fetchExports({ variables: { projectId }, fetchPolicy: 'network-only' });
      downloadExport(exportId);
    });
    return () => {
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
};
