import { CadLayer, CadObject, DeleteCadObjectActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export const deleteCadObject = (
  { cadObjectIdentifier }: { cadObjectIdentifier: string },
  { state }: { state: PointCloudCommandManagerState }
) => {
  const removedCadObject = state.cadLayers
    .flatMap((cadLayer: CadLayer) => cadLayer.cadObjectGroups.flatMap((cadObjectGroup) => cadObjectGroup.cadObjects))
    .find((cadObject) => cadObject.identifier === cadObjectIdentifier);
  state.cadLayers = state.cadLayers.map((cadLayer) => ({
    ...cadLayer,
    cadObjectGroups: cadLayer.cadObjectGroups.map((cadObjectGroup) => ({
      ...cadObjectGroup,
      cadObjects: cadObjectGroup.cadObjects.filter((object) => object.identifier !== cadObjectIdentifier),
    })),
  }));
  return removedCadObject;
};

export const undoDeleteCadObject = (
  { removedCadObject }: { removedCadObject: CadObject },
  { state }: { state: PointCloudCommandManagerState }
) => {
  state.cadLayers.map((layer) => {
    if (layer.identifier === removedCadObject.cadLayerId) {
      return {
        ...layer,
        cadObjectGroups: layer.cadObjectGroups.map((group) => {
          if (group.identifier === removedCadObject.cadObjectGroupId) {
            return {
              ...group,
              cadObjects: [...group.cadObjects, removedCadObject],
            };
          }
          return group;
        }),
      };
    }
    return layer;
  });
};

export class DeleteCadObjectAction implements IAction<DeleteCadObjectActionValue> {
  _removedCadObject?: CadObject;
  public identifier: string;
  constructor(public value: DeleteCadObjectActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const removedCadObject = deleteCadObject({ cadObjectIdentifier: this.value.cadObjectIdentifier }, { state });
    this._removedCadObject = removedCadObject;
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedCadObject) return;
    undoDeleteCadObject({ removedCadObject: this._removedCadObject }, { state });
    this._removedCadObject = undefined;
  }
}
