import { Popover as HSPopover, PopoverButton as HSPopoverButton, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { memo } from 'react';
import { ListItemProps } from '../ListItem';

export const $PopoverListItem: React.FC2<ListItemProps> = ({ children, ...props }) => {
  return <HSPopoverButton {...props}>{children}</HSPopoverButton>;
};
export const PopoverListItem = memo($PopoverListItem);

type ChildFunction = (props: { open: boolean; close: () => void }) => React.ReactNode;
export interface PopoverProps {
  buttonChild: React.ReactNode;
  buttonClassName?: string;
  panelClassName?: string;
  children: ChildFunction | React.ReactNode;
  className?: string;
}
const $Popover: React.FC<PopoverProps> = ({
  buttonChild,
  buttonClassName,
  panelClassName,
  children,
  className,
  ...props
}) => {
  return (
    <HSPopover className={classNames(!className?.includes('absolute') && 'relative', className)} {...props}>
      {({ open, close }) => (
        <>
          <HSPopoverButton className={classNames(buttonClassName, 'flex items-center justify-center')}>
            {buttonChild}
          </HSPopoverButton>
          <Transition
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <HSPopover.Panel
              className={classNames(
                panelClassName,
                !panelClassName?.includes('mt-') && 'mt-3',
                'absolute z-10 transform right-0 bg-white p-2 rounded-md flex flex-col w-48 border border-gray-300 shadow-sm dark:bg-[#313131] dark:text-white dark:border-gray-500',
              )}
            >
              {children === 'function' ? (children as any)({ open, close }) : children}
            </HSPopover.Panel>
          </Transition>
        </>
      )}
    </HSPopover>
  );
};

export const Popover = memo($Popover);
