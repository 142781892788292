import { StarIcon } from '@heroicons/react/24/outline';
import HistoryIcon from '../../assets/icons/history.svg?react';
import Logo from '../../assets/logo-same-color.svg?react';
import LogoSimple from '../../assets/logo-simple.svg?react';
import classNames from 'classnames';
import { memo, MouseEventHandler, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import useQueryParams from '../../hooks/useQueryParams';
import { OrganisationSelect } from '../../modules/organisation/OrganisationSelect';
import { AccountSelect } from '../../modules/user/AccountSelect';
import { T } from '../../translation/src';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { HeroIcon } from '../../types';

const StackSvg: React.FC2 = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
    />
  </svg>
);

interface NavigationItemProps {
  icon: React.FC2 | HeroIcon;
  active?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  smallDevice?: boolean;
}
const $NavigationItem: React.FC2<NavigationItemProps> = ({ icon: Icon, children, active, smallDevice, ...props }) => {
  return (
    <div
      className={classNames(
        'w-full cursor-pointer h-14 items-center text-eerie-black flex px-4 font-semibold dark:text-white ',
        active
          ? 'bg-neon-green-300 text-ash-green-800 dark:bg-black dark:text-white'
          : 'hover:marker:font-bold hover:bg-neon-green-300 dark:hover:bg-black dark:border-gray-400',
      )}
      {...props}
    >
      <div className={'flex items-center'}>
        <Icon className={smallDevice ? 'w-5 ml-3' : 'w-4 mr-4'} />
        <div>{children}</div>
      </div>
    </div>
  );
};
const NavigationItem = memo($NavigationItem);

const $Navigation: React.FC2 = () => {
  const { projectsSelect } = useQueryParams(['projectsSelect']);
  const [lastSelectedOrganisation] = useLocalStorage('lastSelectedOrganisation', '');
  const navigate = useNavigate();
  const location = useLocation();
  const { organisationId = '' } = useParams();
  const { mdDevice } = useDeviceSize();

  const isOnProjectsPage = location.pathname.includes('/projects');

  const onClickNavigationItem = useCallback(
    ({ type }: { type: 'all' | 'recent' | 'favorites' }) => {
      if (organisationId === '' && !lastSelectedOrganisation) {
        navigate(`/no-organisation`);
      } else {
        navigate({
          ...location,
          pathname: `/organisations/${organisationId || lastSelectedOrganisation}/projects`,
          search: '?projectsSelect=' + type,
        });
      }
    },
    [location, navigate, organisationId, lastSelectedOrganisation],
  );

  return (
    <div
      className={classNames(
        'flex flex-col flex-shrink-0 h-full py-2 bg-gray-50 border-r border-gray-400 dark:bg-[#242424]',
        mdDevice ? 'w-20' : 'w-72',
      )}
    >
      {mdDevice ? (
        <LogoSimple className="my-4 mr-4 h-7 text-eerie-black dark:text-white" width="100%" />
      ) : (
        <Logo className="px-4 my-8 text-eerie-black dark:text-white" width="100%" />
      )}
      <div className={classNames('border-b border-gray-400', mdDevice ? 'h-2' : 'h-8')} />
      <OrganisationSelect size={mdDevice ? 'small' : 'large'} />
      <div className={classNames('border-t border-gray-400', mdDevice ? 'h-2' : 'h-8')} />

      <NavigationItem
        icon={StackSvg}
        active={isOnProjectsPage && (!projectsSelect || projectsSelect === 'all')}
        onClick={() => onClickNavigationItem({ type: 'all' })}
        smallDevice={mdDevice}
      >
        {!mdDevice && <T _str="all projects" swc />}
      </NavigationItem>
      <NavigationItem
        icon={HistoryIcon}
        active={isOnProjectsPage && projectsSelect === 'recent'}
        onClick={() => onClickNavigationItem({ type: 'recent' })}
        smallDevice={mdDevice}
      >
        {!mdDevice && <T _str="recent projects" swc />}
      </NavigationItem>
      <NavigationItem
        icon={StarIcon}
        active={isOnProjectsPage && projectsSelect === 'favorites'}
        onClick={() => onClickNavigationItem({ type: 'favorites' })}
        smallDevice={mdDevice}
      >
        {!mdDevice && <T _str="favorite projects" swc />}
      </NavigationItem>
      <div className="flex items-end justify-end flex-grow">
        <AccountSelect variant="navigation" forceReload={false} size={mdDevice ? 'small' : 'large'} />
      </div>
    </div>
  );
};

export const Navigation = memo($Navigation);
