import { FormikInput } from '../../../components/formik/FormInput';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import { toast } from 'react-toastify';
import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        currentPassword: yup.string().trim().required(translations.isRequired),
        password: yup.string().trim().required(translations.isRequired),
        confirmPassword: yup
          .string()
          .required(translations.isRequired)
          .oneOf([yup.ref('password')], translations.passwordsMustMatch),
      }),
    [translations]
  );

  return schema;
};

interface FormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const useTranslations = () => {
  const email = useT('email', { swc: true });
  const currentPassword = useT('current password', { swc: true });
  const newPassword = useT('new password', { swc: true });
  const confirmPassword = useT('confirm password', { swc: true });
  const wrongPassword = useT('confirm password', { swc: true });
  return { email, currentPassword, newPassword, confirmPassword, wrongPassword };
};
interface ResetPasswordModalProps {
  onClose: () => void;
  open?: boolean;
}
const $ResetPasswordModal: React.FC2<ResetPasswordModalProps> = ({ onClose, open }) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationTranslations = useValidationTranslations();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose]
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(async (values, helpers) => {
    try {
      setIsSubmitting(true);
      const response = await fetch(`${import.meta.env.VITE_NX_SERVER}/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: values.password, currentPassword: values.currentPassword }),
        credentials: 'include',
      });
      if (response.status === 200) return onSuccess({ helpers });
      const { message } = await response.json();
      if (message === 'WRONG_PASSWORD') return helpers.setFieldError('currentPassword', translations.wrongPassword);
      toast.error(validationTranslations.somethingWentWrong);
    } catch (e) {
      console.log('e', e);
      toast.error(validationTranslations.somethingWentWrong);
    } finally {
      setIsSubmitting(false);
    }
  }, []);

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { currentPassword: '', password: '', confirmPassword: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema]
  );

  return (
    <CreateModal
      title={<T _str="reset your password" swc />}
      createButtonTitle={<T _str="reset password" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
    >
      <FormikInput name="currentPassword" type="password" label={translations.currentPassword} />
      <FormikInput name="password" type="password" label={translations.newPassword} />
      <FormikInput name="confirmPassword" type="password" label={translations.confirmPassword} />
    </CreateModal>
  );
};

export const ResetPasswordModal = memo($ResetPasswordModal);
