import { Annotation, DeleteAnnotationActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { deleteAnnotation } from '../helpers/deleteAnnotation';
import { undoDeleteAnnotation } from '../helpers/undoDeleteAnnotation';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class DeleteAnnotationAction implements IAction<DeleteAnnotationActionValue> {
  _removedAnnotation?: Annotation;
  _removedAnnotationIndex?: number;
  public identifier: string;
  constructor(public value: DeleteAnnotationActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const { removedAnnotation, removedAnnotationIndex } = deleteAnnotation(
      { annotationIdentifier: this.value.annotationIdentifier },
      { state }
    );
    if (!removedAnnotation) return;
    this._removedAnnotation = removedAnnotation;
    this._removedAnnotationIndex = removedAnnotationIndex;
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedAnnotation || this._removedAnnotationIndex === undefined) return;
    undoDeleteAnnotation(
      { removedAnnotation: this._removedAnnotation, removedAnnotationIndex: this._removedAnnotationIndex },
      { state }
    );
    this._removedAnnotation = undefined;
    this._removedAnnotationIndex = undefined;
  }
}
