import Logo from '../../assets/logo-same-color.svg?react';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { FormikInput } from '../../components/formik/FormInput';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';

const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().trim().email(translations.validEmail).required(translations.isRequired),
      }),
    [translations]
  );

  return schema;
};

interface FormValues {
  email: string;
}

const useTranslations = () => ({
  email: useT('email', { swc: true }),
});

const $ForgotPassword: React.FC2 = () => {
  const translations = useTranslations();
  const validationTranslations = useValidationTranslations();
  const navigate = useNavigate();
  const validationSchema = useValidationSchema();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);
        const response = await fetch(`${import.meta.env.VITE_NX_SERVER}/auth/forgot-password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
          credentials: 'include',
        });
        if (response.status === 200) return setSubmitSuccess(true);
        toast.error(validationTranslations.somethingWentWrong);
      } catch (e) {
        toast.error(validationTranslations.somethingWentWrong);
      } finally {
        setIsSubmitting(false);
      }
    },
    [validationTranslations.somethingWentWrong]
  );

  const initialValues = useMemo(() => ({ email: '' }), []);

  if (submitSuccess) {
    return (
      <div className="flex items-center justify-center dark:bg-[#313131] dark:text-white flex-col w-full h-full">
        <div className="w-3/4 text-lg font-bold text-center">
          <T _str="An email has been sent to the provided email address if it is registered in our system." />
        </div>
        <div className="mb-6 text-center">
          <T _str="Please check your inbox (including spam/junk folders) for further instructions on resetting your password." />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div>
            <Button variant="primary" onClick={() => navigate(`/login`)}>
              <T _str="go back to login" swc />{' '}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full min-h-full px-4 py-12 sm:px-6 lg:px-8 dark:bg-[#313131] dark:text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <Logo className="w-auto mx-auto" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center">
            <T _str="Forgot your password?" />
          </h2>
          <p className="mt-2 text-sm text-center">
            Or{' '}
            <Link
              className="font-medium text-french-violet-500 hover:text-french-violet-400 dark:text-neon-green-500 dark:hover:text-neon-green-400"
              to={`/login`}
            >
              <T _str="go back to login page" />
            </Link>
          </p>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {/* @ts-ignore  TODO fix when formik update*/}
          <Form className="mt-8">
            <div className="mb-2 text-sm dark:text-white">
              <T _str={`Enter your email and we'll send you a link to reset your password.`} />
            </div>
            <FormikInput
              autoFocus
              name="email"
              autoComplete="email"
              label={translations.email}
              placeholder={translations.email}
            />
            <div>
              <Button
                variant="primary"
                type="submit"
                className="items-center justify-center w-full"
                loading={isSubmitting}
              >
                <T _str="Reset password" />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export const ForgotPassword = memo($ForgotPassword);
