import classNames from 'classnames';
import { memo, PropsWithChildren, ReactElement } from 'react';
import { T } from '../../../translation/src';
import { Button } from '../../Button';
import { Modal } from '../../Modal';

interface SubmitModal {
  onClose?: () => void;
  onCancel?: () => void;
  open?: boolean;
  title: React.ReactNode;
  actionButtonTitle?: React.ReactNode;
  cancelButtonTitle?: React.ReactNode;
  modalBodyClassName?: string;
  modalContainerClassName?: string;
  isSubmitting?: boolean;
  formClassName?: string;
  onSubmit?: () => void;
  disabled?: boolean;
}

const $SubmitModal = ({
  onClose,
  onCancel,
  open,
  title,
  actionButtonTitle,
  cancelButtonTitle,
  modalBodyClassName,
  modalContainerClassName,
  children,
  isSubmitting,
  onSubmit,
  disabled,
}: PropsWithChildren<SubmitModal>): ReactElement<any, any> | null => {
  return (
    <Modal.Container
      onClose={onClose}
      open={open}
      className={classNames('flex flex-col w-full h-full', modalContainerClassName)}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className={classNames(modalBodyClassName)}>{children}</Modal.Body>
      <Modal.Footer className="space-x-4">
        {(onSubmit || actionButtonTitle) && (
          <Button type="submit" variant="primary" onClick={onSubmit} loading={isSubmitting} disabled={disabled}>
            {actionButtonTitle || <T _str="submit" swc />}
          </Button>
        )}
        <Button variant="secondary" onClick={onCancel ? onCancel : onClose} disabled={isSubmitting}>
          {cancelButtonTitle ? cancelButtonTitle : <T _str="cancel" swc />}
        </Button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export const SubmitModal = memo($SubmitModal) as typeof $SubmitModal;
