import { useContext, useMemo } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { Permissions } from '../types/graphqlTypes';

interface ProtectedLayoutProps {
  permissions: Permissions[];
}
export const ProtectedLayout: React.FC2<ProtectedLayoutProps> = ({ permissions, children }) => {
  const { organisationId = '' } = useParams();
  const user = useContext(UserContext);

  const myPermissions = useMemo(() => {
    return user.organisations.find((o) => o.id === organisationId)?.myPermissions || [];
  }, [organisationId, user.organisations]);

  const hasPermission =
    user.isSuperAdmin || myPermissions.includes(Permissions.All) || permissions.some((p) => myPermissions.includes(p));

  if (!hasPermission) return <Navigate to="/" />;

  if (children) return <>{children}</>;
  return <Outlet />;
};
