import { memo, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { RendererContext } from '../../../contexts/RendererContext';
import { T } from '../../../translation/src';
import { useProjectPointCloudDeleteMutation } from '../../../types/graphqlTypes';

interface PointCloudDeleteModalProps {
  onClose: () => void;
  open?: boolean;
  pointCloudId?: string;
  isLastPointCloud?: boolean;
}
const $PointCloudDeleteModal: React.FC2<PointCloudDeleteModalProps> = ({
  onClose,
  open,
  pointCloudId,
  isLastPointCloud,
}) => {
  const { projectId = '' } = useParams();
  const [deletePointCloud, { loading: isSubmitting }] = useProjectPointCloudDeleteMutation();
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;

  const onSubmit = useCallback(async () => {
    if (!pointCloudId) return;
    await deletePointCloud({
      variables: { projectId, pointCloudId },
      onCompleted: onClose,
      update: (cache) => cache.evict({ id: `PointCloud:${pointCloudId}` }),
    });
    viewer?.scene.removePointCloud(pointCloudId);
  }, [deletePointCloud, onClose, pointCloudId, projectId, viewer?.scene]);

  return (
    <SubmitModal
      title={<T _str="delete pointcloud" swc />}
      actionButtonTitle={<T _str="delete pointcloud" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      disabled={isLastPointCloud}
    >
      {!isLastPointCloud && (
        <InfoContent
          type="warning-red"
          title={<T _str="This action cannot be undone!" swc />}
          description={<T _str="Deleting the pointcloud will not delete calculations and annotations" />}
        />
      )}
      {isLastPointCloud && (
        <InfoContent
          type="info"
          title={<T _str="This pointcloud cannot be deleted" swc />}
          description={<T _str="It is not possible to delete the last pointcloud in a project" />}
        />
      )}
    </SubmitModal>
  );
};

export const PointCloudDeleteModal = memo($PointCloudDeleteModal);
