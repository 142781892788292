import { memo, useCallback, useMemo } from 'react';
import { RendererProviderStore } from '../../../redux/rendererReducer';
import { T } from '../../../translation/src';
import { PropertiesSection, Property } from './PropertiesSection';
import { useSelectedAnnotations } from '../../../hooks/potree/useSelectedAnnotations';
import { HideLabelProperty } from './HideLabel';
import { EditableColorProperty } from './ColorProperties';
import {
  ClipMethods,
  AnnotationLabels,
  useProjectUpdateClippingMethodMutation,
  BoxAnnotation,
} from '../../../types/graphqlTypes';
import { ClipMethodProperty } from './ClipMethodProperties';
import { SelectProps } from '../../../components/inputs/Select';

interface MultiSelectPropertiesProps {
  projectId: string;
  selection: RendererProviderStore['selectedAnnotations'];
}
const $MultiSelectProperties: React.FC2<MultiSelectPropertiesProps> = ({ projectId, selection }) => {
  const { allSelectedAnnotations } = useSelectedAnnotations();
  const selectedPointAnnotations = allSelectedAnnotations.filter((annotation) => annotation.type === 'POINT');
  const selectedDistanceAnnotations = allSelectedAnnotations.filter((annotation) => annotation.type === 'DISTANCE');
  const selectedAreaAnnotations = allSelectedAnnotations.filter((annotation) => annotation.type === 'AREA');
  const selectedBoxAnnotations = allSelectedAnnotations.filter((annotation) => annotation.type === 'BOX');

  // Can expand this to more annotation types later
  const filterSelectedAnnotations = selectedBoxAnnotations as BoxAnnotation[];

  const selectedGroups = useMemo(() => selection.filter((annotation) => annotation.type === 'group'), [selection]);

  const annotationIdentifiers = allSelectedAnnotations.map((annotation) => annotation.identifier);

  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();

  const clipMethod = useMemo(() => {
    if (filterSelectedAnnotations.length === 0) return undefined;

    const firstFilter = filterSelectedAnnotations[0]?.annotationFilter?.clipMethod;
    const sameFilter = filterSelectedAnnotations.every(
      (annotation) => annotation.annotationFilter?.clipMethod === firstFilter
    );

    return sameFilter ? firstFilter : undefined;
  }, [filterSelectedAnnotations]);

  const onChangeClippingMethod: SelectProps<ClipMethods>['onChange'] = useCallback(
    (event) => {
      updateClippingMethod({
        variables: { projectId, annotationIdentifiers, clipMethod: event.target.value },
      });
    },
    [annotationIdentifiers, projectId, updateClippingMethod]
  );

  if (allSelectedAnnotations.length === 0) return null;

  return (
    <div className="flex flex-col space-y-4 divide-y divide-gray-300">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="selection" swc />
        </Property>
        <Property title={<T _str="annotations" swc />}>{new Set(allSelectedAnnotations).size}</Property>
        <Property title={<T _str="groups" swc />}>{selectedGroups.length}</Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        {selectedPointAnnotations.length > 0 && (
          <HideLabelProperty annotations={selectedPointAnnotations} label={AnnotationLabels.Coordinate} />
        )}
        {(selectedDistanceAnnotations.length > 0 || selectedAreaAnnotations.length > 0) && (
          <HideLabelProperty
            annotations={[...selectedDistanceAnnotations, ...selectedAreaAnnotations]}
            label={AnnotationLabels.Length}
          />
        )}
        {selectedAreaAnnotations.length > 0 && (
          <>
            <HideLabelProperty annotations={selectedAreaAnnotations} label={AnnotationLabels.Area} />
            <HideLabelProperty annotations={selectedAreaAnnotations} label={AnnotationLabels.Volume} />
          </>
        )}
        <EditableColorProperty annotations={allSelectedAnnotations} />
      </PropertiesSection>
      {allSelectedAnnotations.some((annotation) => annotation.type === 'BOX') && (
        <PropertiesSection title={<T _str="filters" swc />}>
          <ClipMethodProperty
            onChange={onChangeClippingMethod}
            clipMethod={clipMethod || undefined}
          ></ClipMethodProperty>
        </PropertiesSection>
      )}
    </div>
  );
};

export const MultiSelectProperties = memo($MultiSelectProperties);
