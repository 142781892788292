import gql from 'graphql-tag';

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUser on User {
    id
    name
    email
    firstName
    lastName
    isSuperAdmin
    rolesByOrganisation {
      organisationId
      role
    }
    organisations {
      id
      myPermissions
      projects {
        id
      }
      subscription {
        isFreeTrial
        endDate
        canAddPointClouds
        canAddSimultaneousUploads
        simultaneousUploadsLeft
      }
    }
    favoriteProjectIds
    measurementUnit
    dateFormat
    darkMode
  }
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    name
    role(organisationId: $organisationId)
    joinDate(organisationId: $organisationId)
    status(organisationId: $organisationId)
    status(organisationId: $organisationId)
    allowedProjectIds(organisationId: $organisationId)
    allowedProjects(organisationId: $organisationId) {
      id
      name
    }
    name
    email
  }
`;

export const USERS = gql`
  ${USER_FRAGMENT}
  query users($organisationId: ID!) {
    usersByOrganisationId(organisationId: $organisationId) {
      ...User
    }
  }
`;

export const CURRENT_USER = gql`
  ${CURRENT_USER_FRAGMENT}
  query currentUser {
    currentUser {
      ...CurrentUser
    }
  }
`;

export const USER_ADD_TO_ORGANISATION = gql`
  ${USER_FRAGMENT}
  mutation userAddToOrganisation($organisationId: ID!, $email: String!, $role: UserRole!) {
    userAddToOrganisation(organisationId: $organisationId, email: $email, role: $role) {
      ...User
    }
  }
`;

export const USER_RESEND_INVITATION = gql`
  ${USER_FRAGMENT}
  mutation userResendInvitation($organisationId: ID!, $userId: ID!) {
    userResendInvitation(organisationId: $organisationId, userId: $userId) {
      ...User
    }
  }
`;

export const USER_DELETE_FROM_ORGANISATION = gql`
  mutation userDeleteFromOrganisation($organisationId: ID!, $userId: ID!) {
    userDeleteFromOrganisation(organisationId: $organisationId, userId: $userId)
  }
`;

export const USER_CHANGE_ROLE = gql`
  ${USER_FRAGMENT}
  mutation userChangeRole($organisationId: ID!, $userId: ID!, $role: UserRole!) {
    userChangeRole(organisationId: $organisationId, userId: $userId, role: $role) {
      ...User
    }
  }
`;

export const USER_DISABLE = gql`
  ${USER_FRAGMENT}
  mutation userDisable($organisationId: ID!, $userId: ID!) {
    userDisable(organisationId: $organisationId, userId: $userId) {
      ...User
    }
  }
`;

export const USER_ENABLE = gql`
  ${USER_FRAGMENT}
  mutation userEnable($organisationId: ID!, $userId: ID!) {
    userEnable(organisationId: $organisationId, userId: $userId) {
      ...User
    }
  }
`;

export const USER_INVITE_INFO = gql`
  query userInviteInfo($token: String!) {
    userInviteInfo(token: $token) {
      email
      organisationName
      isNewUser
      inviteId
    }
  }
`;

export const USER_ACCEPT_INVITATION = gql`
  ${CURRENT_USER_FRAGMENT}
  mutation userAcceptInvitation($inviteId: String!, $userEmail: String!, $user: InputUser) {
    userAcceptInvitation(inviteId: $inviteId, userEmail: $userEmail, user: $user)
  }
`;

export const USER_UPDATE = gql`
  ${CURRENT_USER_FRAGMENT}
  mutation userUpdate($firstName: String!, $lastName: String!, $email: String!) {
    userUpdate(firstName: $firstName, lastName: $lastName, email: $email) {
      ...CurrentUser
    }
  }
`;

export const USER_UPDATE_FAVORITES = gql`
  mutation userUpdateFavorites($projectId: ID!, $inFavorites: Boolean!) {
    userUpdateFavorites(projectId: $projectId, inFavorites: $inFavorites) {
      id
      favoriteProjectIds
    }
  }
`;

export const USER_CHANGE_UNITS = gql`
  mutation userChangeUnits($inUnit: MeasurementUnits!) {
    userChangeUnits(inUnit: $inUnit) {
      id
      measurementUnit
    }
  }
`;

export const USER_CHANGE_DATE_FORMAT = gql`
  mutation userChangeDateFormat($inFormat: DateFormats!) {
    userChangeDateFormat(inFormat: $inFormat) {
      id
      dateFormat
    }
  }
`;

export const USER_CHANGE_DARK_MODE = gql`
  mutation userChangeDarkMode($inDarkMode: Boolean!) {
    userChangeDarkMode(inDarkMode: $inDarkMode) {
      id
      darkMode
    }
  }
`;
