import { ClipMethods } from '../../../types/graphqlTypes';

export function getPotreeFilter(filter: ClipMethods | null | undefined) {
  switch (filter) {
    case ClipMethods.None:
      return Potree.ClipTask.NONE;
    case ClipMethods.Highlight:
      return Potree.ClipTask.GRAYSCALE;
    case ClipMethods.Hide:
      return Potree.ClipTask.SHOW_OUTSIDE;
    case ClipMethods.Isolate:
      return Potree.ClipTask.SHOW_INSIDE;
    default:
      return Potree.ClipTask.NONE;
  }
}
