import { useLocalStorage } from 'usehooks-ts';

export const useRecentProjects = (): [string[], (projectId: string) => void] => {
  const [recentProjects, setRecentProjects] = useLocalStorage('recentProjects', [] as string[]);

  const addRecentProject = (projectId: string) => {
    setRecentProjects((value) => {
      return [projectId, ...value].slice(0, 25);
    });
  };

  return [recentProjects, addRecentProject];
};
