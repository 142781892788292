import { MovePointActionValue, Position } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class MovePointAction implements IAction<MovePointActionValue> {
  public identifier: string;
  _previousPosition: Position | undefined;
  _previousPointCloudId: string | undefined | null;
  constructor(public value: MovePointActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation) return;
    if (annotation.type === 'POINT') {
      const { position, color, classification, intensity, pointCloudId } = this.value;
      this._previousPosition = { ...position };
      this._previousPointCloudId = pointCloudId;
      return Object.assign(annotation, { position, color, classification, intensity, pointCloudId });
    } else if (annotation.type === 'AREA' || annotation.type === 'DISTANCE') {
      const point = annotation.points[this.value.pointIndex];
      if (!point) return;
      this._previousPosition = { ...point.position };
      this._previousPointCloudId = point.pointCloudId;
      const { position, pointCloudId } = this.value;
      annotation.points[this.value.pointIndex] = { ...point, position, pointCloudId };
    }
    return;
  }
  undo(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation) return;
    if (!this._previousPosition) return;
    if (!this._previousPointCloudId) return;
    if (annotation.type === 'POINT') {
      return (annotation.position = this._previousPosition) && (annotation.pointCloudId = this._previousPointCloudId);
    } else if (annotation.type === 'AREA' || annotation.type === 'DISTANCE')
      return (
        (annotation.points[this.value.pointIndex].position = { ...this._previousPosition }) &&
        (annotation.points[this.value.pointIndex].pointCloudId = this._previousPointCloudId)
      );
    this._previousPosition = undefined;
    this._previousPointCloudId = undefined;
    return;
  }
}
