import { Dispatch, SetStateAction, memo } from 'react';
import { AppliedFiltersArray } from '../../hooks/potree/usePointCloudProperties';
import { Radio, RadioGroup } from '../inputs/Radio';
import { useT } from '../../translation/src';
import { ConvertType, useFormatNumber } from '../../hooks/useFormatNumber';

const useTranslations = () => {
  const noFilters = useT('no filters', { swc: true });
  const currentFilters = useT('current filters', { swc: true });
  const classifications = useT('classifications', { swc: true });
  const height = useT('height', { swc: true });
  const intensity = useT('intensity', { swc: true });
  const resolution = useT('resolution', { swc: true });
  const clippingBoxes = useT('clipping boxes', { swc: true });
  const clippingClusters = useT('clipping clusters', { swc: true });
  return { noFilters, currentFilters, classifications, height, intensity, resolution, clippingBoxes, clippingClusters };
};

export const FiltersText: React.FC<{ filters: AppliedFiltersArray }> = ({ filters }) => {
  const translations = useTranslations();
  const { formatNumber } = useFormatNumber();
  return (
    <>
      {filters
        .map((filter) => {
          if (filter.name === 'classifications') return `${translations.classifications} ${filter.value.length}`;
          if (filter.name === 'heightFilter')
            return `${translations.height} ${formatNumber(filter.value.min, {
              convertType: ConvertType.DISTANCE,
              addUnit: true,
            })} - ${formatNumber(filter.value.max, { convertType: ConvertType.DISTANCE, addUnit: true })}`;
          if (filter.name === 'intensityFilter')
            return `${translations.intensity} ${filter.value.min} - ${filter.value.max}`;
          if (filter.name === 'clippingFilter') {
            const clippingObjectStrings = [];
            if (filter.value.nrOfClippingBoxes)
              clippingObjectStrings.push(`${translations.clippingBoxes} (${filter.value.nrOfClippingBoxes})`);
            if (filter.value.nrOfClippingClusters)
              clippingObjectStrings.push(`${translations.clippingClusters} (${filter.value.nrOfClippingClusters})`);
            return clippingObjectStrings.join(', ');
          }
          return `${translations.resolution} ${formatNumber(filter.value.resolutionValue, {
            convertType: ConvertType.DISTANCE,
            addUnit: true,
          })}`;
        })
        .join(', ')}
    </>
  );
};

interface FiltersProps {
  filters: AppliedFiltersArray;
  selectedFilters: {
    [key: string]: boolean;
  };
  setSelectedFilters: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  pointCloudId: string;
}

const $Filters: React.FC<FiltersProps> = ({ filters, selectedFilters, pointCloudId, setSelectedFilters }) => {
  const translations = useTranslations();

  if (!filters.length) return null;
  return (
    <div className="flex flex-col pl-6 mt-2 overflow-auto">
      <RadioGroup
        name={`filter_${pointCloudId}`}
        value={selectedFilters[pointCloudId] ? 'current' : 'noFilters'}
        onChange={({ target: { value } }) =>
          setSelectedFilters((filters) => ({ ...filters, [pointCloudId]: value === 'current' }))
        }
      >
        <Radio
          value="current"
          extraContent={
            <div className="pl-6 text-xs text-gray-500">
              <FiltersText filters={filters} />
            </div>
          }
        >
          {translations.currentFilters}
        </Radio>
        <Radio value="noFilters">{translations.noFilters}</Radio>
      </RadioGroup>
    </div>
  );
};
export const Filters = memo($Filters);
