import Logo from '../../assets/logo-same-color.svg?react';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { FormikCheckBox } from '../../components/formik/FormCheckBox';
import { FormikInput } from '../../components/formik/FormInput';
import { useLogin } from '../../hooks/modules/user/useLogin';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';

const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().trim().email(translations.validEmail).required(translations.isRequired),
        password: yup.string().trim().required(translations.isRequired),
      }),
    [translations]
  );

  return schema;
};

interface FormValues {
  email: string;
  password: string;
  remember: boolean;
}

const useTranslations = () => ({
  wrongCombination: useT('wrong email/password combination', { swc: true }),
  email: useT('email', { swc: true }),
  password: useT('password', { swc: true }),
  rememberMe: useT('remember me', { swc: true }),
});

const $Login: React.FC2 = () => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const validationSchema = useValidationSchema();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const login = useLogin();
  const [_, setLastSelectedOrganisation] = useLocalStorage('lastSelectedOrganisation', '');

  useEffect(() => {
    setLastSelectedOrganisation('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      try {
        setIsSubmitting(true);
        const response = await login(values);
        if (response.status === 200) return navigate('/');

        if (response.status === 401) throw new Error();
      } catch (e) {
        helpers.setFieldError('password', translations.wrongCombination);
      } finally {
        setIsSubmitting(false);
      }
    },
    [login, navigate, translations.wrongCombination]
  );

  const initialValues = useMemo(() => ({ email: '', password: '', remember: true }), []);

  return (
    <div className="flex items-center justify-center w-full min-h-full px-4 py-12 sm:px-6 lg:px-8 dark:bg-[#313131] dark:text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <Logo className="w-auto mx-auto text-eerie-black dark:text-white" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center">
            <T _str="Sign in to your account" />
          </h2>
          <p className="mt-2 text-sm text-center">
            Or{' '}
            <Link
              className="font-medium text-french-violet-500 hover:text-french-violet-400 dark:text-neon-green-400 dark:hover:text-neon-green-300"
              to={`/register`}
            >
              <T _str="get started - free" swc />
            </Link>
          </p>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {/* @ts-ignore  TODO fix when formik update*/}
          <Form className="mt-8">
            <FormikInput
              name="email"
              type="email"
              autoComplete="email"
              label={translations.email}
              placeholder={translations.email}
            />
            <FormikInput
              name="password"
              type="password"
              autoComplete="current-password"
              label={translations.password}
              placeholder={translations.password}
            />

            <div className="flex items-center justify-between">
              <FormikCheckBox name="remember" label={translations.rememberMe} />
              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-french-violet-500 hover:text-french-violet-400 dark:text-neon-green-400 dark:hover:text-neon-green-300"
                >
                  <T _str="Forgot your password?" />
                </Link>
              </div>
            </div>
            <div>
              <Button
                variant="primary"
                type="submit"
                className="items-center justify-center w-full"
                loading={isSubmitting}
              >
                <T _str="Sign in" />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export const Login = memo($Login);
