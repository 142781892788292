import { CadLayer, CadObjectGroup, DeleteCadObjectGroupActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export const deleteCadObjectGroup = (
  { cadObjectGroupIdentifier }: { cadObjectGroupIdentifier: string },
  { state }: { state: PointCloudCommandManagerState }
) => {
  const removedCadObjectGroup = state.cadLayers
    .flatMap((cadLayer: CadLayer) => cadLayer.cadObjectGroups)
    .find((cadObjectGroup) => cadObjectGroup.identifier === cadObjectGroupIdentifier);
  state.cadLayers = state.cadLayers.map((cadLayer) => ({
    ...cadLayer,
    cadObjectGroups: cadLayer.cadObjectGroups.filter(
      (objectGroup) => objectGroup.identifier !== cadObjectGroupIdentifier
    ),
  }));
  return removedCadObjectGroup;
};

export const undoDeleteCadObjectGroup = (
  { removedCadObjectGroup }: { removedCadObjectGroup: CadObjectGroup },
  { state }: { state: PointCloudCommandManagerState }
) => {
  state.cadLayers.map((layer) => {
    if (layer.identifier === removedCadObjectGroup.cadLayerId) {
      return {
        ...layer,
        cadObjectGroups: [...layer.cadObjectGroups, removedCadObjectGroup],
      };
    }
    return layer;
  });
};

export class DeleteCadObjectGroupAction implements IAction<DeleteCadObjectGroupActionValue> {
  _removedCadObjectGroup?: CadObjectGroup;
  public identifier: string;
  constructor(public value: DeleteCadObjectGroupActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const removedCadObjectGroup = deleteCadObjectGroup(
      { cadObjectGroupIdentifier: this.value.cadObjectGroupIdentifier },
      { state }
    );
    this._removedCadObjectGroup = removedCadObjectGroup;
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedCadObjectGroup) return;
    undoDeleteCadObjectGroup({ removedCadObjectGroup: this._removedCadObjectGroup }, { state });
    this._removedCadObjectGroup = undefined;
  }
}
