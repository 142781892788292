import { Button } from '../../../components/Button';
import { ProjectionCalculation } from '../../../contexts/AnnotationContext';
import useOpen from '../../../hooks/useOpen';
import { T, useT } from '../../../translation/src';
import {
  DistanceAnnotation,
  GeneralStatus,
  PointCloud,
  useProjectProjectionCalculateMutation,
} from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCancelCalculation } from '../../../hooks/modules/project/useCancelCalculation';
import { DATA_DEPTH, useAppliedFilters } from '../../../hooks/potree/usePointCloudProperties';
import { CalculationModal, CalculationModalProps } from '../../project/CalculationModal';
import { PropertiesSection } from './PropertiesSection';
import { RendererReadOnlyContext } from '../../../contexts/RendererReadOnlyContext';
import * as uuid from 'uuid';
import { DistanceCalculation } from './DistanceCalculation';

const useTranslations = () => {
  const invalidCalculation = useT(
    'This calculation is not valid anymore due to changes on the annotation. You should delete this one and recalculate it.',
  );
  const distanceCalculation = useT('distance calculation', { swc: true });
  return { invalidCalculation, distanceCalculation };
};

interface DistanceCalculationsProps {
  annotation: DistanceAnnotation;
  calculations: ProjectionCalculation[];
  otherCalculationsNav?: React.ReactNode;
  project: {
    id: string;
    pointClouds: Pick<PointCloud, 'id' | 'displayName' | 'availableClasses'>[];
  };
}
const $DistanceCalculations: React.FC2<DistanceCalculationsProps> = ({
  annotation,
  calculations,
  otherCalculationsNav,
  project,
}) => {
  const translations = useTranslations();
  const { projectId = '' } = useParams();
  const isReadOnly = useContext(RendererReadOnlyContext);
  const appliedFilters = useAppliedFilters({ project });
  const [calculateProjection, { loading: calculateProjectionLoading }] = useProjectProjectionCalculateMutation();

  const { onOpen: openCalculationModal, onClose: closeCalculationModal, open: calculationModalOpen } = useOpen();

  const onCalculateProjection = useCallback(() => {
    if (Object.values(appliedFilters).some((value) => value.hasFilters)) return openCalculationModal();
    if (project.pointClouds.length > 1) return openCalculationModal();
    calculateProjection({
      variables: {
        projectId,
        pointCloudId: project.pointClouds[0].id,
        annotationIdentifier: annotation.identifier,
        profileGroupIdentifier: uuid.v4(),
      },
    });
  }, [
    annotation.identifier,
    appliedFilters,
    calculateProjection,
    openCalculationModal,
    projectId,
    project.pointClouds,
  ]);

  const onSubmitCalculation: CalculationModalProps['onSubmit'] = useCallback(
    async (pointClouds) => {
      const profileGroupIdentifier = uuid.v4();
      pointClouds.forEach((pointCloud) => {
        calculateProjection({
          variables: {
            projectId,
            pointCloudId: pointCloud.id,
            annotationIdentifier: annotation.identifier,
            profileGroupIdentifier: profileGroupIdentifier,
            classificationFilters:
              pointCloud.filters?.classifications && pointCloud.filters?.classifications.length > 0
                ? pointCloud.filters?.classifications.map((classification) => +classification.code)
                : undefined,
            ...(pointCloud.filters?.heightFilter && {
              heightFilters: [pointCloud.filters?.heightFilter.min, pointCloud.filters?.heightFilter.max],
            }),
            ...(pointCloud.filters?.intensityFilter && {
              intensityFilters: [pointCloud.filters?.intensityFilter.min, pointCloud.filters?.intensityFilter.max],
            }),
            ...(pointCloud.filters?.resolutionFilter && {
              resolutionFilter: pointCloud.filters?.resolutionFilter.resolutionLevel + DATA_DEPTH,
            }),
            ...(pointCloud.filters?.clippingFilter && {
              clippingFilter:
                pointCloud.filters?.clippingFilter.nrOfClippingBoxes +
                pointCloud.filters?.clippingFilter.nrOfClippingClusters,
            }),
          },
        });
      });
    },
    [annotation.identifier, calculateProjection, projectId],
  );

  const calculationsLoading = calculations.some((calculation) => calculation.status === GeneralStatus.Progress);

  const progressCalculations = useMemo(
    () => calculations.filter((calculation) => calculation.status === GeneralStatus.Progress),
    [calculations],
  );

  const { onCancelCalculation } = useCancelCalculation({
    projectId,
    calculationIds: progressCalculations?.map((calculation) => calculation.id),
    __typename: 'ProjectionCalculation',
  });

  const uniqueProfileGroups = [...new Set(calculations.map((calculation) => calculation.profileGroupIdentifier))];

  return (
    <>
      <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
        <PropertiesSection title={<T _str="elevation profile" swc />}>
          {uniqueProfileGroups.map((profileGroup) => (
            <DistanceCalculation
              calculations={calculations.filter((calculation) => calculation.profileGroupIdentifier === profileGroup)}
              project={project}
            />
          ))}

          <div
            className={classNames(
              'flex items-center justify-center w-full',
              calculations &&
                calculations.length > 0 &&
                calculations.some((calculation) => calculation.status !== GeneralStatus.Progress) &&
                'mt-4',
            )}
          >
            <Button
              variant="secondary"
              size="sm"
              onClick={onCalculateProjection}
              className="w-full"
              loading={calculationsLoading || calculateProjectionLoading}
              loadingTitle={<T _str="calculating" swc />}
              onCancel={onCancelCalculation}
            >
              <T _str="generate elevation profile" swc />
            </Button>
          </div>

          <div className="mt-3 text-xs italic text-white">
            <T _str="This calculation can take some time. You will get a notification when your calculation is ready." />
          </div>
          {isReadOnly && !calculations && (
            <div>
              <T _str="No calculations yet" />
            </div>
          )}
        </PropertiesSection>
        {otherCalculationsNav}
      </div>
      <CalculationModal
        pointClouds={project.pointClouds}
        onClose={closeCalculationModal}
        open={calculationModalOpen}
        title={translations.distanceCalculation}
        onSubmit={onSubmitCalculation}
        multiplePointCloudsSupported={true}
      />
    </>
  );
};

export const DistanceCalculations = memo($DistanceCalculations);
