import { AddressElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik, FormikConfig } from 'formik';
import { omit } from 'lodash/fp';
import React, { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { CircleSpinner } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { FormikInput } from '../../../components/formik/FormInput';
import { BILLING_PAYMENT_DETAILS } from '../../../graphql/billing';
import { useStripeAppearance, useStripeModule } from '../../../hooks/modules/billing/useStripeModule';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { useT } from '../../../translation/src';
import {
  PaymentDetails,
  useBillingPaymentDetailsQuery,
  useBillingUpdateCustomerMutation,
} from '../../../types/graphqlTypes';

const useTranslations = () => {
  const email = useT('billing email', { swc: true });
  const companyName = useT('company name', { swc: true });
  const country = useT('country', { swc: true });
  const postalCode = useT('postal code', { swc: true });
  const billingInfo = useT('billing info', { swc: true });
  const city = useT('city', { swc: true });
  const line1 = useT('street', { swc: true });
  const line2 = useT('house number', { swc: true });
  const vatnumber = useT('VAT number', { swc: true });
  const general = useT('general', { swc: true });
  const save = useT('save', { swc: true });
  return { email, companyName, country, postalCode, billingInfo, city, line1, line2, vatnumber, general, save };
};

interface FormValues {
  email: string;
  VAT: string;
}

const $AddressElementForm: React.FC<{ paymentDetails?: PaymentDetails | null; name?: string | null }> = ({
  paymentDetails,
}) => {
  const translations = useTranslations();
  const validationTranslations = useValidationTranslations();
  const address = paymentDetails?.address;
  const stripe = useStripe();
  const elements = useElements();
  const { organisationId = '' } = useParams();
  const [updateCustomer] = useBillingUpdateCustomerMutation();
  const { value: isSubmitting, setValue: setIsSubmitting } = useBoolean();
  const { setTrue: setAddressElementReady, value: addressElementReady } = useBoolean();

  const initialValues = useMemo(
    () => ({ email: paymentDetails?.email || '', VAT: paymentDetails?.VAT || '' }),
    [paymentDetails]
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      if (!stripe || !elements) return null;
      setIsSubmitting(true);
      const addressElement = await elements.getElement('address')?.getValue();
      const address = addressElement?.value.address;
      const name = addressElement?.value.name;
      const emailUpdated = values.email !== initialValues.email;
      const VATUpdated = values.VAT !== initialValues.VAT;
      await updateCustomer({
        variables: {
          customer: {
            address: { ...omit('postal_code', address), postalCode: address?.postal_code },
            name,
            email: emailUpdated ? values.email : undefined,
            VAT: VATUpdated ? values.VAT : undefined,
          },
          organisationId,
        },
        update: (cache, data) => {
          cache.writeQuery({
            query: BILLING_PAYMENT_DETAILS,
            variables: { organisationId },
            data: { billingPaymentDetails: data.data?.billingUpdateCustomer },
          });
        },
        onError: (error) => {
          if (error.message === 'INVALID_VAT') helpers.setFieldError('VAT', validationTranslations.isInvalidValue);
          setIsSubmitting(false);
        },
      });
      setIsSubmitting(false);
    },
    [
      stripe,
      elements,
      setIsSubmitting,
      initialValues.email,
      initialValues.VAT,
      updateCustomer,
      organisationId,
      validationTranslations.isInvalidValue,
    ]
  );

  return (
    <div className="w-full">
      <Formik<FormValues> onSubmit={onSubmit} initialValues={initialValues}>
        {/* @ts-ignore todo when formik update */}
        <Form>
          <Card
            title={translations.billingInfo}
            className="w-full"
            submitButtonTitle={translations.save}
            isSubmitting={isSubmitting}
          >
            {!addressElementReady && (
              <CircleSpinner className="w-6 h-6 dark:text-neon-green-300 text-french-violet-600" />
            )}
            <AddressElement
              options={{
                mode: 'shipping',
                defaultValues: {
                  address: { ...address, postal_code: address?.postalCode, country: address?.country || '' },
                  name: paymentDetails?.name || '',
                },
              }}
              onReady={setAddressElementReady}
            />
            <FormikInput className="mt-3" label={translations.email} name="email" />
            <FormikInput label={translations.vatnumber} name="VAT" />
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

const AddressElementForm = memo($AddressElementForm);

const $BillingInfo: React.FC = () => {
  const stripePromise = useStripeModule();
  const stripeAppearance = useStripeAppearance();
  const { organisationId = '' } = useParams();
  const { data: billingPaymentDetailsData } = useBillingPaymentDetailsQuery({ variables: { organisationId } });
  const paymentDetails = billingPaymentDetailsData?.billingPaymentDetails;

  if (!paymentDetails) return null;
  return (
    <Elements stripe={stripePromise} options={{ appearance: stripeAppearance }}>
      <AddressElementForm paymentDetails={paymentDetails} />
    </Elements>
  );
};

export const BillingInfo = memo($BillingInfo);
