import classNames from 'classnames';
import { memo } from 'react';

const $Property: React.FC2<{ title: React.ReactNode }> = ({ title, children, className }) => {
  return (
    <div className={classNames('flex items-center text-sm', className)}>
      <div className="flex-shrink-0 w-32 text-[#6F7173] dark:text-[#C2C6C8]">{title}</div>
      <div className="flex flex-grow">{children}</div>
    </div>
  );
};
export const Property = memo($Property);

interface PropertiesSectionProps {
  title?: React.ReactNode;
}
const $PropertiesSection: React.FC2<PropertiesSectionProps> = ({ title, children, className }) => {
  return (
    <div className={classNames('flex flex-col w-full text-sm', className)}>
      <div className="my-4 font-bold dark:text-white">{title}</div>
      <div className="flex flex-col w-full space-y-2">{children}</div>
    </div>
  );
};

export const PropertiesSection = memo($PropertiesSection);
