import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { DateFormats } from "../types/graphqlTypes";

export const useFormatDate = () => {
  const {dateFormat} = useContext(UserContext);

  const formatDate = (value: Date) => {
      switch(dateFormat){
        case DateFormats.Ddmmyyyy:
          return moment(value).format('DD/MM/YYYY');
        case DateFormats.Mmddyyyy:
          return moment(value).format('MM/DD/YYYY');
        case DateFormats.Yyyymmdd:
          return moment(value).format('YYYY/MM/DD');
        default:
          return moment(value).format('DD/MM/YYYY');
      }
    };

  const formatDateAndTime = (value: Date) => {
      switch(dateFormat){
        case DateFormats.Ddmmyyyy:
          return moment(value).format('DD/MM/YYYY - HH:mm');
        case DateFormats.Mmddyyyy:
          return moment(value).format('MM/DD/YYYY - HH:mm');
        case DateFormats.Yyyymmdd:
          return moment(value).format('YYYY/MM/DD - HH:mm');
        default:
          return moment(value).format('DD/MM/YYYY - HH:mm');
      }
    };

  return { formatDate, formatDateAndTime };
};
