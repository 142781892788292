import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Analytics } from '@vercel/analytics/react';
import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apolloClient } from './apollo';
import App from './app/app';
import { ProtectedLayout } from './auth/ProtectedLayout';
import './index.css';
import { AdminCenter } from './pages/admin-center';
import { ForgotPassword } from './pages/authentication/ForgotPassword';
import { InvalidSubscription } from './pages/authentication/InvalidSubscription';
import { Invitation } from './pages/authentication/Invitation';
import { Login } from './pages/authentication/Login';
import { Logout } from './pages/authentication/Logout';
import { Register } from './pages/authentication/Register';
import { ResetPassword } from './pages/authentication/ResetPassword';
import { Billing } from './pages/billing';
import { CompanyInfo } from './pages/billing/companyInfo';
import { PaymentHistory } from './pages/billing/history';
import { Organisations } from './pages/organisations';
import PotreeRenderer from './pages/potree-renderer';
import { Projects } from './pages/projects';
import { Project } from './pages/projects/Project';
import { Users } from './pages/users';
import { Profile } from './pages/users/Profile';
import WATest from './pages/wa-test';
import store from './redux/store';
import { Permissions } from './types/graphqlTypes';
import { ErrorBoundary } from './pages/error-boundary';
import { InvalidProject } from './pages/projects/invalidProject';
import { NoOrganisationFound } from './pages/organisations/NoOrganisationFound';

if (import.meta.env.PROD)
  Sentry.init({
    dsn: 'https://6e70747785b5401dacda1d4392c9679f@o1342113.ingest.sentry.io/6615889',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_NX_SENTRY_ENV,
    enabled: !!import.meta.env.VITE_NX_SENTRY_ENV,
  });

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

const Home = () => {
  return <div />;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      {process.env.NODE_ENV === 'local' ? null : <Analytics />}
      <ApolloProvider client={apolloClient}>
        <ErrorBoundary>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register-invite" element={<Invitation />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<App />}>
                <Route index element={<Home />} />
                <Route path="potree-renderer" element={<PotreeRenderer />} />
                <Route path="wa-test" element={<WATest />} />
                <Route path="organisations" element={<Organisations />}>
                  <Route path=":organisationId">
                    <Route
                      index
                      element={
                        <ProtectedLayout permissions={[Permissions.Projects, Permissions.ProjectsRead]}>
                          <Projects />
                        </ProtectedLayout>
                      }
                    />
                    <Route
                      path="projects"
                      element={<ProtectedLayout permissions={[Permissions.Projects, Permissions.ProjectsRead]} />}
                    >
                      <Route index element={<Projects />} />
                      <Route path=":projectId" element={<Project />} />
                    </Route>
                    <Route path="invalid-project">
                      <Route index element={<InvalidProject />} />
                    </Route>
                    <Route path="users" element={<ProtectedLayout permissions={[Permissions.Users]} />}>
                      <Route index element={<Users />} />
                    </Route>
                    <Route path="billing" element={<ProtectedLayout permissions={[Permissions.Billing]} />}>
                      <Route index element={<Billing />} />
                      <Route path="history">
                        <Route index element={<PaymentHistory />} />
                      </Route>
                      <Route path="users" element={<ProtectedLayout permissions={[Permissions.Users]} />}>
                        <Route index element={<Users />} />
                      </Route>
                      <Route path="companyInfo">
                        <Route index element={<CompanyInfo />} />
                      </Route>
                    </Route>
                    <Route path="invalid-subscription">
                      <Route index element={<InvalidSubscription />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="no-organisation">
                  <Route index element={<NoOrganisationFound />} />
                </Route>
                <Route path="me">
                  <Route path="profile" element={<Profile />} />
                </Route>
                <Route path="admin-center">
                  <Route index element={<AdminCenter />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
        <ToastContainer />
      </ApolloProvider>
    </ReduxProvider>
  </StrictMode>
);
