import { AddPointActionValue, PointAnnotation } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { AddAnnotationAction } from './AddAnnotationAction';

export class AddPointAction extends AddAnnotationAction<AddPointActionValue> {
  override execute(state: PointCloudCommandManagerState) {
    const annotation = { ...this.value, type: 'POINT', identifier: this.value.annotationIdentifier } as PointAnnotation;
    state.annotations.push(annotation);
    super.execute(state);
  }
}
