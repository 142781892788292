import { configureStore } from '@reduxjs/toolkit';
import { isEqual, isObject } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import rendererProviderReducer, {
  initialRendererState,
  RendererProviderAction,
  RendererProviderStore,
} from './rendererReducer';
import { actionCreator, isType } from './utils/actions';

export interface State {
  rendererProvider: RendererProviderStore;
}

export const initialStoreState: State = {
  rendererProvider: initialRendererState,
};

export const logoutUserAction = actionCreator<{}>('LOGOUT_USER');
type LogoutUserAction = ReturnType<typeof logoutUserAction>;

export type Action = RendererProviderAction | LogoutUserAction;

export const ActionType = {};

const reducers = {
  rendererProvider: rendererProviderReducer,
};

const combinedReducers = combineReducers(reducers);

const rootReducer = (state: ReturnType<typeof combinedReducers> = initialStoreState, action: Action) => {
  if (isType(action, logoutUserAction)) {
    return combinedReducers(initialStoreState, action);
  }
  return combinedReducers(state, action);
};

const store = configureStore({ reducer: rootReducer, devTools: import.meta.env.NODE_ENV === 'development' });

export const useCustomSelector = <TState = State, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  properties: (keyof TSelected)[] = [],
  listenToEverything = !properties.length
) => {
  return useSelector(selector, (left: any, right: any) => {
    if (!left && !right) return true;
    if (listenToEverything) {
      if (!right && !!left) return false;
      if (!left && !!right) return false;
      for (const property of Object.keys(right).concat(Object.keys(left))) {
        const equal = shallowEqual(left[property], right[property]);
        if (!equal) return false;
      }
    }
    for (const property of properties) {
      if (!left[property] && right[property]) return false;
      if (!right[property] && left[property]) return false;
      let equal = false;
      if (isObject(left[property]) || isObject(right[property])) equal = isEqual(left[property], right[property]);
      else equal = shallowEqual(left[property], right[property]);
      if (!equal) return false;
    }
    return true;
  });
};

export default store;
