import * as React from "react";
const SvgVectorPolygon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 25 25", width: 25, height: 25, ...props }, /* @__PURE__ */ React.createElement("rect", { fill: "none", height: 25, width: 25 }), /* @__PURE__ */ React.createElement("circle", { cx: 11.719, cy: 4.688, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 3.906, cy: 11.719, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 15.625, cy: 20.313, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 20.313, cy: 7.031, fill: "none", r: 2.344, stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 18.047, x2: 13.985, y1: 6.416, y2: 5.303, style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 9.98, x2: 5.645, y1: 6.26, y2: 10.146, style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 5.801, x2: 13.73, y1: 13.105, y2: 18.926, style: {
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("line", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", x1: 16.406, x2: 19.531, y1: 18.105, y2: 9.237, style: {
  strokeWidth: "1.5px"
} }));
export default SvgVectorPolygon;
