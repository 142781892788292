import { Draggable } from '@hello-pangea/dnd';
import { ChevronDownIcon, ChevronRightIcon, FolderIcon, FolderMinusIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { memo, MouseEventHandler, useCallback, useMemo } from 'react';
import { ContextMenu } from '../../../../components/ContextMenu';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { useIsMultiSelect } from '../../../../hooks/modules/renderer/useIsMultiSelect';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import { useUpdatePosition } from '../../../../hooks/modules/renderer/useUpdatePosition';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import { emitter } from '../../../../hooks/potree/useProject';
import useOpen from '../../../../hooks/useOpen';
import { RendererProviderStore } from '../../../../redux/rendererReducer';
import { useCustomSelector } from '../../../../redux/store';
import { T } from '../../../../translation/src';
import { Annotation } from './Annotation';
import { AnnotationsIconsWrapper } from './AnnotationsIconsWrapper';
import { AnnotationsWithIndicesGroup } from './utils/getConvertedIndices';

const $MultiSelectMenuItems: React.FC2 = () => {
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);
  const [executeAction] = useExecuteAction();

  const onDeleteAnnotations = useCallback(() => {
    executeAction({
      action: { identifiers: selectedAnnotations.map((annotation) => annotation.identifier) },
      type: 'DELETE_ANNOTATION_MULTI',
    });
    // @TODO: investigate why project does not render automatically in this case...
    emitter.emit('RERENDER');
  }, [executeAction, selectedAnnotations]);

  return (
    <div className="flex flex-col">
      <SelectItemContent icon={TrashIcon} onClick={onDeleteAnnotations}>
        <T _str="delete" swc />
      </SelectItemContent>
    </div>
  );
};
const MultiSelectMenuItems = memo($MultiSelectMenuItems);

const $ContextMenuItems: React.FC2<{
  group: AnnotationsWithIndicesGroup & { index: number };
  isFirst?: boolean;
  isLast?: boolean;
  groupIdentifier?: string;
  onToggleNameEditable?: () => void;
  rootIndex: number;
}> = ({ isLast, isFirst, group, rootIndex }) => {
  const [executeAction] = useExecuteAction();
  const isMultiSelect = useIsMultiSelect();

  const [{ UpdatePositionItems }] = useUpdatePosition({
    groupIdentifier: group.identifier,
    downDisabled: isLast,
    upDisabled: isFirst,
  });

  const onDeleteGroup = useCallback(() => {
    executeAction({ action: { groupIdentifier: group.identifier }, type: 'DELETE_GROUP' });
  }, [executeAction, group.identifier]);

  const onUnGroup = useCallback(() => {
    executeAction({ action: { groupIdentifier: group.identifier }, type: 'UN_GROUP' });
  }, [executeAction, group.identifier]);

  if (isMultiSelect) return <MultiSelectMenuItems />;

  return (
    <>
      <div className="flex flex-col">
        <UpdatePositionItems index={rootIndex} />
      </div>
      <div className="flex flex-col">
        <SelectItemContent icon={TrashIcon} onClick={onDeleteGroup}>
          <T _str="delete" swc />
        </SelectItemContent>
        <SelectItemContent icon={FolderMinusIcon} onClick={onUnGroup}>
          <T _str="ungroup" swc />
        </SelectItemContent>
      </div>
    </>
  );
};
const ContextMenuItems = memo($ContextMenuItems);

interface AnnotationGroupProps {
  group: AnnotationsWithIndicesGroup & { index: number };
  active?: boolean;
  selectedAnnotations: RendererProviderStore['selectedAnnotations'];
  isFirst?: boolean;
  isLast?: boolean;
  onClickAnnotation: (
    args1: { identifier: string; groupIdentifier?: string; type: 'group' | 'annotation' },
    args2: { isCtrl?: boolean; isShift?: boolean }
  ) => void;
  index: number;
  rootIndex: number;
  isReadOnly: boolean;
}
const $AnnotationGroup: React.FC2<AnnotationGroupProps> = ({
  group,
  active,
  selectedAnnotations,
  isFirst,
  isLast,
  onClickAnnotation,
  index: rootIndex,
  isReadOnly,
}) => {
  const { open: folderOpen, onOpen: onOpenFolder, onClose: onCloseFolder } = useOpen(true);
  const selectedIdentifiers = useMemo(
    () => selectedAnnotations.map((annotation) => annotation.identifier),
    [selectedAnnotations]
  );
  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: group.name,
    groupIdentifier: group.identifier,
  });
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // event.preventDefault();
      // event.stopPropagation();
      onClickAnnotation(
        { identifier: group.identifier, type: 'group' },
        { isCtrl: event.ctrlKey || event.metaKey, isShift: event.shiftKey }
      );
    },
    [group.identifier, onClickAnnotation]
  );

  const onToggleFolderOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (folderOpen) return onCloseFolder();
      return onOpenFolder();
    },
    [folderOpen, onCloseFolder, onOpenFolder]
  );

  const visible = useMemo(() => {
    return group.annotations.some((annotation) => annotation.visible);
  }, [group]);

  return (
    <>
      {nameEditable ? (
        nameInputComponent
      ) : (
        <ContextMenu
          popoverMenuDisabled={isReadOnly}
          buttonChild={
            <Draggable
              draggableId={`group_${group.identifier}`}
              index={group.index}
              disableInteractiveElementBlocking={isReadOnly}
            >
              {(provided, snapshot) => (
                <div
                  data-group-id={group.identifier}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={provided.draggableProps.style}
                >
                  <SelectItemContent
                    onClick={onClick}
                    icon={FolderIcon}
                    active={active}
                    selectStyle="primary"
                    idle={!visible}
                    className={classNames(
                      'relative pl-8',
                      snapshot.isDragging && 'bg-neon-green-300 dark:bg-black dark:text-white'
                    )}
                    onDoubleClick={onToggleNameEditable}
                  >
                    <div
                      className="absolute flex items-center justify-center w-5 h-5 rounded left-1 hover:text-neon-green-400"
                      onClick={onToggleFolderOpen}
                    >
                      {folderOpen ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
                    </div>
                    <div className="overflow-hidden">{group.name}</div>
                    <AnnotationsIconsWrapper identifier={group.identifier} visible={visible} group={group} />
                  </SelectItemContent>
                </div>
              )}
            </Draggable>
          }
          panelClassName="divide-y"
        >
          <ContextMenuItems group={group} isFirst={isFirst} isLast={isLast} rootIndex={rootIndex} />
        </ContextMenu>
      )}

      {folderOpen &&
        group.annotations.map((annotation, index) => (
          <Annotation
            type={annotation.__typename || 'PointAnnotation'}
            active={selectedIdentifiers.includes(annotation.identifier)}
            rootActive={active}
            annotation={annotation}
            key={annotation.identifier}
            className="pl-16"
            groupIdentifier={group.identifier}
            isFirst={index === 0}
            isLast={index === group.annotations.length - 1}
            onClickAnnotation={onClickAnnotation}
            rootIndex={index}
            isReadOnly={isReadOnly}
          />
        ))}
    </>
  );
};
export const AnnotationGroup = memo($AnnotationGroup);
