import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { selectEditableNameAnnotation } from '../../../redux/rendererReducer';
import { useCustomSelector } from '../../../redux/store';
import { useExecuteAction } from '../../potree/useExecuteAction';

export const useRenameSubmit = () => {
  const [executeAction] = useExecuteAction();
  const { editableNameAnnotation } = useCustomSelector((state) => state.rendererProvider, ['editableNameAnnotation']);
  const dispatch = useDispatch();

  const submitRename = useCallback(() => {
    if (editableNameAnnotation) {
      const identifier = editableNameAnnotation.id;
      executeAction({
        type: 'UPDATE_NAME',
        action: {
          name: editableNameAnnotation.name,
          annotationIdentifier: editableNameAnnotation.type === 'annotation' ? identifier : '',
          groupIdentifier: editableNameAnnotation.type === 'group' ? identifier : '',
          pointCloudIdentifier: editableNameAnnotation.type === 'pointcloud' ? identifier : '',
          cadObjectIdentifier: editableNameAnnotation.type === 'cadObject' ? identifier : '',
          cadObjectGroupIdentifier: editableNameAnnotation.type === 'cadObjectGroup' ? identifier : '',
          cadLayerIdentifier: editableNameAnnotation.type === 'cadLayer' ? identifier : '',
          wmsLayerIdentifier: editableNameAnnotation.type === 'wmsLayer' ? identifier : '',
        },
      });
      dispatch(selectEditableNameAnnotation(undefined));
    }
  }, [dispatch, editableNameAnnotation, executeAction]);

  return submitRename;
};
