import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { memo } from 'react';

const $Header: React.FC2 = ({ children, className, ...props }) => {
  return (
    <Dialog.Title {...props} className={classNames(className, 'flex items-center text-lg mb-6')}>
      {children}
    </Dialog.Title>
  );
};

export const Header = memo($Header);
