import classNames from 'classnames';
import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { CreateModal } from '../../components/Modal/CreateModal';
import { FormikInput } from '../../components/formik/FormInput';
import { T } from '../../translation/src';
import { useSuperadminUpdateLimitsMutation } from '../../types/graphqlTypes';

interface FormValues {
  maxPointCount: number;
  maxUploadsInProgress: number;
}

interface UpdateLimitsModalProps {
  onClose: () => void;
  open?: boolean;
  organisationId?: string;
  initialValues?: { maxPointCount: number; maxUploadsInProgress: number };
}
const $UpdateLimitsModal: React.FC2<UpdateLimitsModalProps> = ({ onClose, open, organisationId, initialValues }) => {
  const [updateLimits, { loading: updateLimitsLoading }] = useSuperadminUpdateLimitsMutation();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose]
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      if (!organisationId || !initialValues) return;
      await updateLimits({
        variables: {
          customLimits: Object.entries(values).reduce((result, [key, value]) => {
            if (initialValues[key as keyof typeof initialValues] !== value) return { ...result, [key]: value };
            return { ...result, [key]: undefined };
          }, {}),
          organisationId,
        },
      });
      onSuccess({ helpers });
    },
    [initialValues, onSuccess, organisationId, updateLimits]
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: initialValues || { maxPointCount: 0, maxUploadsInProgress: 0 },
      onSubmit,
    }),
    [onSubmit, initialValues]
  );

  return (
    <CreateModal
      title={<T _str="update limits" swc />}
      createButtonTitle={<T _str="submit" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={updateLimitsLoading}
    >
      <div className={classNames('flex flex-col')}>
        <FormikInput type="number" autoFocus name="maxPointCount" label={<T _str="max point count" swc />} />
        <FormikInput
          type="number"
          autoFocus
          name="maxMonthlyPointCount"
          label={<T _str="max monthly point count" swc />}
        />
        <FormikInput
          type="number"
          autoFocus
          name="maxUploadsInProgress"
          label={<T _str="max simultaneous uploads" swc />}
        />
      </div>
    </CreateModal>
  );
};

export const UpdateLimitsModal = memo($UpdateLimitsModal);
