import { memo, useEffect, useState } from 'react';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { useT } from '../../../translation/src';

const useTranslations = () => {
  const upgradePlan = useT('upgrade plan', { swc: true });
  return { upgradePlan };
};

interface ShouldUpgradeModal {
  onClose: () => void;
  open?: boolean;
  description: string;
}
const $ShouldUpgradeModal: React.FC2<ShouldUpgradeModal> = ({ onClose, open, ...props }) => {
  const translations = useTranslations();
  const [description, setDescription] = useState(props.description);
  useEffect(() => {
    if (props.description) setDescription(props.description);
  }, [props.description]);

  return (
    <SubmitModal title={translations.upgradePlan} onClose={onClose} open={open}>
      {description}
    </SubmitModal>
  );
};
export const ShouldUpgradeModal = memo($ShouldUpgradeModal);
