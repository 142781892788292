import { ButtonToggle } from '../../../components/ButtonToggle';
import { FormikInput } from '../../../components/formik/FormInput';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import { UserRole, useUserAddToOrganisationMutation } from '../../../types/graphqlTypes';
import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().trim().email(translations.validEmail).required(translations.isRequired),
      }),
    [translations]
  );

  return schema;
};

interface FormValues {
  email: string;
}

const buttonToggleOptions = [
  { value: UserRole.Member, label: <T _str="member" swc /> },
  {
    value: UserRole.Admin,
    label: <T _str="admin" swc />,
  },
];

const useTranslations = () => {
  const email = useT('email', { swc: true });
  return { email };
};
interface AddUserModalProps {
  onClose: () => void;
  open?: boolean;
}
const $AddUserModal: React.FC2<AddUserModalProps> = ({ onClose, open }) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const [addUser, { loading: addUserLoading }] = useUserAddToOrganisationMutation();
  const [role, setRole] = useState<UserRole>(UserRole.Member);

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose]
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      await addUser({
        variables: { organisationId, email: values.email, role },
        refetchQueries: ['users', 'currentUser', 'organisations'],
        awaitRefetchQueries: true,
      });
      onSuccess({ helpers });
    },
    [addUser, onSuccess, organisationId, role]
  );

  const onChangeRole = useCallback((value: UserRole) => {
    setRole(value);
  }, []);

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { email: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema]
  );

  return (
    <CreateModal
      title={<T _str="add member" swc />}
      createButtonTitle={<T _str="send invitation" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={addUserLoading}
    >
      <ButtonToggle options={buttonToggleOptions} onChange={onChangeRole} value={role} />
      <div className="mt-2 mb-3 text-sm text-gray-400">
        {role === UserRole.Admin ? (
          <T _str="Admins have access to all projects and can manage your organisation, your organisation users, your subscription and payments." />
        ) : (
          <T _str="Members have access to all projects." />
        )}
      </div>
      <FormikInput name="email" label={translations.email} placeholder={translations.email} />
    </CreateModal>
  );
};

export const AddUserModal = memo($AddUserModal);
