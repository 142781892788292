import { keyBy } from 'lodash';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { fixOrderIndices } from './fixOrderIndices';
import { Annotation, WmsLayer } from '@pointorama/database';
import { isWmsLayerItem } from '../types';
import { getItemsByIdentifier } from './getItemsByIdentifier';

const handleMoveRootLevel = ({
  state,
  item,
  value,
  currentIndex,
}: {
  state: PointCloudCommandManagerState;
  item?: Annotation | WmsLayer;
  value: {
    orderedIdentifiersKey: 'orderedIdentifiers' | 'wmsLayerOrderedIdentifiers';
    itemIdentifier?: string;
    groupIdentifier?: string;
    toIndex: number;
  };
  currentIndex: number;
}) => {
  const orderedIdentifiersKey = value.orderedIdentifiersKey;
  state[orderedIdentifiersKey] = state[orderedIdentifiersKey].map(({ identifier, index }) => {
    if (identifier === value.itemIdentifier || identifier === value.groupIdentifier)
      return { identifier, index: value.toIndex };
    if (!isWmsLayerItem(item) && item?.groupIdentifier) return { identifier, index };
    if (index === value.toIndex) return { identifier, index: currentIndex > value.toIndex ? index + 1 : index - 1 };
    return { identifier, index: index > value.toIndex ? index + 1 : index - 1 };
  });
};

export const updateOrderedIdentifiers = (
  state: PointCloudCommandManagerState,
  value: {
    groupIdentifier?: string;
    itemIdentifier?: string;
    toIndex: number;
    toGroupIdentifier?: string | null;
    itemIdentifierKey: 'annotations' | 'wmsLayers';
    groupIdentifierKey?: 'groups';
    orderedIdentifiersKey: 'orderedIdentifiers' | 'wmsLayerOrderedIdentifiers';
  }
) => {
  const itemIdentifierKey = value.itemIdentifierKey;
  const groupIdentifierKey = value.groupIdentifierKey || 'groups';
  const orderedIdentifiersKey = value.orderedIdentifiersKey;
  const itemsByIdentifier = getItemsByIdentifier({ state, itemIdentifierKey });
  const groupsByIdentifier = keyBy(state[groupIdentifierKey], 'identifier');
  const item = (value.itemIdentifier && itemsByIdentifier[value.itemIdentifier]) || undefined;
  const group = (value.groupIdentifier && groupsByIdentifier[value.groupIdentifier]) || undefined;
  const currentIndex =
    state[value.orderedIdentifiersKey].find(
      (val) => val.identifier === value.itemIdentifier || val.identifier === value.groupIdentifier
    )?.index || 0;
  if (isWmsLayerItem(item)) {
    handleMoveRootLevel({ state, item, value, currentIndex });
  } else {
    if (((item && !item.groupIdentifier) || group) && !value.toGroupIdentifier) {
      /**
       * Case 1: Moved annotation or group on root level
       */
      handleMoveRootLevel({ state, item, value, currentIndex });
    } else if (item && item.groupIdentifier == value.toGroupIdentifier) {
      /**
       * Case 2: Moved annotation within same group
       */
      state[orderedIdentifiersKey] = state[orderedIdentifiersKey].map(({ identifier, index }) => {
        const item = itemsByIdentifier[identifier];
        if (identifier === value.itemIdentifier) return { identifier, index: value.toIndex };
        if (!isWmsLayerItem(item) && item?.groupIdentifier != value.toGroupIdentifier) return { identifier, index };
        if (index === value.toIndex) return { identifier, index: currentIndex > value.toIndex ? index + 1 : index - 1 };
        return { identifier, index: index > value.toIndex ? index + 1 : index - 1 };
      });
    } else if (
      item &&
      item.groupIdentifier != value.toGroupIdentifier &&
      value.toGroupIdentifier &&
      item.groupIdentifier
    ) {
      /**
       * Case 3: Moved annotation to different group
       */
      state[orderedIdentifiersKey] = state[orderedIdentifiersKey].map(({ identifier, index }) => {
        const item = itemsByIdentifier[identifier];
        if (identifier === value.itemIdentifier) return { identifier, index: value.toIndex };
        if (!isWmsLayerItem(item) && item?.groupIdentifier != value.toGroupIdentifier) return { identifier, index };
        return { identifier, index: index >= value.toIndex ? index + 1 : index - 1 };
      });
      item.groupIdentifier = value.toGroupIdentifier;
    } else if (item && !item.groupIdentifier && value.toGroupIdentifier) {
      /**
       * Case 4: Moved annotation from root level to group
       */
      state[orderedIdentifiersKey] = state[orderedIdentifiersKey].map(({ identifier, index }) => {
        const item = itemsByIdentifier[identifier];
        if (identifier === value.itemIdentifier) return { identifier, index: value.toIndex };
        if (!isWmsLayerItem(item) && item?.groupIdentifier != value.toGroupIdentifier) return { identifier, index };
        return { identifier, index: index >= value.toIndex ? index + 1 : index - 1 };
      });
      item.groupIdentifier = value.toGroupIdentifier;
    } else if (item && item.groupIdentifier && !value.toGroupIdentifier) {
      /**
       * Case 5: Move item from group to root level
       */
      state[orderedIdentifiersKey] = state[orderedIdentifiersKey].map(({ identifier, index }) => {
        const item = itemsByIdentifier[identifier];
        if (identifier === value.itemIdentifier) return { identifier, index: value.toIndex };
        if (!isWmsLayerItem(item) && item?.groupIdentifier != value.toGroupIdentifier) return { identifier, index };
        return { identifier, index: index >= value.toIndex ? index + 1 : index - 1 };
      });
      item.groupIdentifier = undefined;
    }
  }
  fixOrderIndices(state);
  return { ...state };
};
