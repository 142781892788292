import { Annotation, AnnotationGroup, DeleteAnnotationMultiActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { deleteAnnotation } from '../helpers/deleteAnnotation';
import { fixOrderIndices } from '../helpers/fixOrderIndices';
import { removeGroup } from '../helpers/removeGroup';
import { undoDeleteAnnotation } from '../helpers/undoDeleteAnnotation';
import { undoGroupAction } from '../helpers/undoGroupAction';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class DeleteAnnotationMultiAction implements IAction<DeleteAnnotationMultiActionValue> {
  _removedAnnotations: { annotation: Annotation; index: number }[] = [];
  _removedGroupAnnotations: {
    orderedIdentifiers: { identifier: string; index: number }[];
    annotations: Annotation[];
    group?: AnnotationGroup;
  }[] = [];
  public identifier: string;
  constructor(public value: DeleteAnnotationMultiActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    this.value.identifiers.forEach((identifier) => {
      const annotation = state.annotations.find((annotation) => annotation.identifier === identifier);
      if (annotation) {
        const { removedAnnotation, removedAnnotationIndex } = deleteAnnotation(
          { annotationIdentifier: identifier },
          { state }
        );
        if (removedAnnotation && removedAnnotationIndex !== undefined) {
          this._removedAnnotations.push({ annotation: removedAnnotation, index: removedAnnotationIndex });
        }
      }
      const group = state.groups.find((group) => group.identifier === identifier);
      if (!group) return;
      const { removedOrderedIdentifiers, removedGroupAnnotations, removedGroup } = removeGroup(state, {
        identifier: identifier,
      });
      this._removedGroupAnnotations.push({
        orderedIdentifiers: removedOrderedIdentifiers,
        annotations: removedGroupAnnotations,
        group: removedGroup,
      });
    });
  }
  undo(state: PointCloudCommandManagerState) {
    this._removedAnnotations.forEach(({ annotation, index }) => {
      undoDeleteAnnotation({ removedAnnotation: annotation, removedAnnotationIndex: index }, { state });
    });
    this._removedAnnotations = [];
    this._removedGroupAnnotations.forEach(({ orderedIdentifiers, annotations, group }) => {
      if (!group) return;
      undoGroupAction(
        {
          removedOrderedIdentifiers: orderedIdentifiers,
          removedAnnotations: annotations,
          removedGroup: group,
        },
        { state }
      );
    });
    this._removedGroupAnnotations = [];
    fixOrderIndices(state);
  }
}
