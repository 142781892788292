import pako from 'pako';
import { ProjectByIdQuery } from '../../../types/graphqlTypes';
import { omit } from 'lodash/fp';
import * as base64 from 'byte-base64';

type Point = { x: number; y: number; z: number };

const decompressData = (data: string): Point[] => {
  const binaryString = atob(data); // Decode base64 to binary string
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const decompressed = pako.ungzip(bytes, { to: 'string' });
  const points = JSON.parse(decompressed);
  return points;
};

export const compressData = (data: Point[]) => {
  try {
    const jsonString = JSON.stringify(data);
    const compressed = pako.gzip(jsonString);
    const base64String = base64.bytesToBase64(compressed);

    return base64String;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export type DecompressedCadLayer = ReturnType<typeof decompressCadLayers>[0];

export const decompressCadLayers = (layers?: ProjectByIdQuery['projectById']['state']['cadLayers']) => {
  return (layers || []).map((layer) => ({
    ...layer,
    cadObjectGroups: layer.cadObjectGroups.map((group) => ({
      ...group,
      cadObjects: group.cadObjects.map((cadObject) => {
        if (cadObject.__typename === 'CadLine' || cadObject.__typename === 'CadPolygon') {
          return {
            ...omit('pointsCompressed', cadObject),
            points: decompressData(cadObject.pointsCompressed),
          };
        }
        return cadObject;
      }),
    })),
  }));
};
