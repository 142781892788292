import React, { createContext } from 'react';

export interface ILanguageContext {
  language: string;
  languages: { [key: string]: { [key: string]: any } };
  setLanguage: (e: string) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
  language: 'en',
  languages: {},
  setLanguage: (e: string) => e,
});

export const LanguageProvider: React.FC2<ILanguageContext> = ({ children, language, languages, setLanguage }) => {
  return <LanguageContext.Provider value={{ language, languages, setLanguage }}>{children}</LanguageContext.Provider>;
};
