import { useContext, useMemo } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { useOrganisationsQuery } from '../../../types/graphqlTypes';
import { useRouteParams } from '../../useRouteParams';
import { EMPTY_ARRAY } from '../../../utils/constants';

export const useCurrentOrganisation = () => {
  const user = useContext(UserContext);
  const { organisationId } = useRouteParams(['organisationId']);

  const organisationsQuery = useOrganisationsQuery({
    skip: !user?.isSuperAdmin,
    variables: { includeIds: [organisationId] },
  });
  const organisations = organisationsQuery.data?.organisations || EMPTY_ARRAY;

  const currentOrganisation = useMemo(() => {
    if (!user) return undefined;
    if (user.isSuperAdmin) return organisations.find((organisation) => organisation.id === organisationId);
    return user.organisations.find((organisation) => organisation.id === organisationId);
  }, [user, organisations, organisationId]);

  return { currentOrganisation, loading: organisationsQuery.loading || false };
};
