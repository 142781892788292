import classNames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

interface TabPaneProps
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'key' | 'onClick'> {
  key: string;
  onClick?: ({ key }: { key: string }) => void;
  selected?: boolean;
  customKey?: string;
}
const $TabPane: React.FC<TabPaneProps> = ({ className, onClick: onClickProps, selected, customKey, ...props }) => {
  const onClick = useCallback(() => {
    if (onClickProps && customKey) onClickProps({ key: customKey });
  }, [customKey, onClickProps]);
  return (
    <div
      className={classNames(
        'flex items-center justify-center cursor-pointer text-sm font-semibold',
        selected && 'dark:text-white',
        className
      )}
      {...props}
      onClick={onClick}
    ></div>
  );
};
export const TabPane = memo($TabPane);

interface TabsContainerProps<Key extends string = string> {
  defaultActiveKey: Key;
  selectedKey?: Key;
  onChange: ({ key }: { key: Key }) => void;
  tabWidth?: React.CSSProperties['width'];
}
const $TabsContainer: React.FC<
  TabsContainerProps & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'>
> = ({ defaultActiveKey, onChange, className, children, tabWidth, selectedKey: selectedKeyProps, ...props }) => {
  const TAB_WIDTH = tabWidth || '10rem';
  const [selectedKey, setSelectedKey] = useState(defaultActiveKey);

  const onChangeKey = useCallback(
    ({ key }: { key: string }) => {
      setSelectedKey(key);
      onChange({ key });
    },
    [onChange]
  );

  useEffect(() => {
    if (selectedKeyProps) setSelectedKey(selectedKeyProps);
  }, [selectedKeyProps]);

  useEffect(() => {
    onChangeKey({ key: defaultActiveKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultActiveKey]);

  const childrenKeys = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) return child.key;
        return null;
      }) || [],
    [children]
  );
  const tabIndex = useMemo(() => {
    if (!selectedKey) return 0;
    return childrenKeys.findIndex((key) => key === selectedKey);
  }, [childrenKeys, selectedKey]);

  return (
    <div className={classNames('flex w-full relative h-12 flex-shrink-0', className)} {...props}>
      {React.Children.map(children, (child: any, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as any, {
            key: child.key,
            customKey: child.key,
            // @ts-ignore
            onClick: ({ key }: { key: string }) => {
              // @ts-ignore
              if (child.onClick) child.onClick({ key });
              onChangeKey({ key });
            },
            selected: !!selectedKey ? child.key === selectedKey : index === 0,
            style: {
              // @ts-ignore
              ...child.style,
              width: TAB_WIDTH,
            },
            className: classNames(
              child.key === selectedKey ? 'dark:text-white' : 'text-gray-500',
              // @ts-ignore
              child.className
            ),
          });
        }
        return null;
      })}
      <div className={classNames('absolute bottom-px bg-gray-300 h-px transition-all w-full')}></div>
      <div
        className={classNames('absolute bottom-0 bg-black dark:bg-white transition-all')}
        style={{ width: TAB_WIDTH, left: `calc(${tabIndex} * ${TAB_WIDTH})`, height: '3px' }}
      ></div>
    </div>
  );
};

export const TabsContainer = memo($TabsContainer) as typeof $TabsContainer;
