import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { FormikInput } from '../../../components/formik/FormInput';
import { UserContext } from '../../../contexts/UserContext';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { useT } from '../../../translation/src';
import { useOrganisationUpdateMutation, useOrganisationsQuery } from '../../../types/graphqlTypes';
import { useDeviceSize } from '../../../hooks/useDeviceSize';

interface FormValues {
  name: string;
}

const useTranslations = () => {
  const email = useT('billing email', { swc: true });
  const companyName = useT('company name', { swc: true });
  const vatnumber = useT('VAT number', { swc: true });
  const general = useT('general', { swc: true });
  const save = useT('save', { swc: true });
  return { email, companyName, vatnumber, general, save };
};

const $BillingCompanyInfo: React.FC2 = () => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const validationTranslations = useValidationTranslations();
  const user = useContext(UserContext);
  const { data: organisationsData } = useOrganisationsQuery(
    user ? { variables: { includeIds: [organisationId] } } : {}
  );
  const [updateOrganisation, { loading: updateOrganisationLoading }] = useOrganisationUpdateMutation();
  const organisations = organisationsData?.organisations;
  const organisation = organisations?.find((org) => org.id === organisationId);
  const { xsDevice } = useDeviceSize();

  const initialValues = useMemo(() => ({ name: organisation?.name || '' }), [organisation]);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      await updateOrganisation({
        variables: { name: values.name, organisationId },
        onError: (error) => {
          if (error.message === 'ALREADY_EXISTS') helpers.setFieldError('name', validationTranslations.alreadyExists);
        },
      });
    },
    [organisationId, updateOrganisation, validationTranslations.alreadyExists]
  );

  return (
    <div className="flex space-x-4">
      <Formik<FormValues> onSubmit={onSubmit} initialValues={initialValues}>
        {/* @ts-ignore todo when formik update */}
        <Form className={xsDevice ? 'w-full' : 'w-1/2'}>
          <Card
            title={translations.general}
            className="w-full"
            submitButtonTitle={translations.save}
            isSubmitting={updateOrganisationLoading}
          >
            <div className="flex flex-col">
              <FormikInput label={translations.companyName} name="name" />
            </div>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

export const BillingCompanyInfo = memo($BillingCompanyInfo);
