import classNames from 'classnames';
import { memo, MouseEventHandler, useCallback } from 'react';
import { HeroIcon } from '../../types';

export interface ListItemProps {
  icon: HeroIcon;
  active?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}
const $ListItem: React.FC2<ListItemProps> = ({
  icon: Icon,
  children,
  active,
  className,
  disabled,
  onClick: onClickProps,
  ...props
}) => {
  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (...args) => {
      if (disabled) return;
      onClickProps?.(...args);
    },
    [disabled, onClickProps],
  );
  return (
    <button
      {...props}
      onClick={onClick}
      className={classNames(
        `${
          disabled
            ? 'text-gray-400'
            : active
              ? 'bg-neon-green-100 text-gray-900 dark:bg-black dark:text-white'
              : !className?.includes('text-') && 'text-gray-900 dark:text-white'
        } group flex items-center rounded-md px-2 py-2 text-sm my-1`,
        disabled ? 'cursor-not-allowed' : 'hover:bg-neon-green-300 dark:hover:bg-eerie-black',
        !className?.includes('w-') && 'w-full',
        className,
      )}
    >
      <Icon className={classNames('w-5 h-5 mr-2')} />
      {children}
    </button>
  );
};
export const ListItem = memo($ListItem);
