import { useQuery } from '@apollo/client';
import * as EventEmitter from 'eventemitter3';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PotreeSourcesContext } from '../../contexts/PotreeSourcesContext';
import { PROJECT } from '../../graphql/project';
import { ProjectByIdQuery, ProjectByIdQueryVariables } from '../../types/graphqlTypes';
import { useRerender } from '../useRerender';

export const emitter = new EventEmitter.EventEmitter();

export const useProject = () => {
  const { isLoaded: sourcesLoaded } = useContext(PotreeSourcesContext);
  const { projectId = '' } = useParams();
  const projectQuery = useQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(PROJECT, {
    variables: { projectId },
  });
  useEffect(() => {
    if (!projectQuery.loading && sourcesLoaded)
      // @ts-ignore
      Potree.stats.startRender = performance.now();
  }, [projectQuery.loading, sourcesLoaded]);
  const rerender = useRerender();
  useEffect(() => {
    emitter.on('RERENDER', rerender);
  }, [rerender]);

  return projectQuery;
};
