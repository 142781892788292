import { FormikConfig } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { useUserRoleTranslations } from '../../../hooks/translations/useUserRoleTranslations';
import { useT } from '../../../translation/src';
import { User, UserRole, useUserChangeRoleMutation } from '../../../types/graphqlTypes';

const useTranslations = () => {
  const manageAccess = useT('manage access', { swc: true });
  const projects = useT('projects', { swc: true });
  return { manageAccess, projects };
};

interface FormValues {
  role: UserRole;
}

interface ChangeRoleModalProps {
  onClose: () => void;
  open?: boolean;
  user?: Pick<User, 'id' | 'role'>;
}
const $ChangeRoleModal: React.FC2<ChangeRoleModalProps> = ({ onClose, open, user }) => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const [changeRole, { loading: isSubmitting }] = useUserChangeRoleMutation();

  const roleTranslations = useUserRoleTranslations();

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values) => {
      if (!user) return;
      changeRole({ variables: { organisationId, role: values.role, userId: user.id } });
      onClose();
    },
    [changeRole, onClose, organisationId, user]
  );

  const formik: FormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: { role: user?.role || UserRole.Guest },
      onSubmit,
    };
  }, [onSubmit, user?.role]);

  const roles = useMemo(() => {
    if (user?.role === UserRole.Guest) return Object.values(UserRole);
    return Object.values(UserRole).filter((role) => role !== UserRole.Guest);
  }, [user?.role]);

  if (!user) return null;

  return (
    <CreateModal<FormValues>
      open={open}
      onClose={onClose}
      title={translations.manageAccess}
      formik={formik}
      isSubmitting={isSubmitting}
      noOverflow
    >
      <FormikSelect name="role" label={translations.projects}>
        {roles.map((role) => (
          <Option key={role} value={role}>
            {roleTranslations[role.toLocaleLowerCase()]}
          </Option>
        ))}
      </FormikSelect>
    </CreateModal>
  );
};

export const ChangeRoleModal = memo($ChangeRoleModal);
