import { useCallback, useContext } from 'react';
import { useToggleHide } from './useToggleHide';
import { Project } from '../../../types/graphqlTypes';
import { RendererContext } from '../../../contexts/RendererContext';

export const useToggleHideMap = ({ project }: { project?: Pick<Project, 'mapVisible'> }) => {
  const { toggleHide } = useToggleHide();
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;

  const onToggleHideMap = useCallback(() => {
    toggleHide({ identifiers: ['map'], visible: !project?.mapVisible });
    if (project?.mapVisible) {
      viewer?.hideMap();
    } else {
      viewer?.showMap();
    }
  }, [project?.mapVisible, toggleHide, viewer]);

  return [project?.mapVisible || false, onToggleHideMap] as const;
};
