import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { fixOrderIndices } from './fixOrderIndices';
import { isAnnotationItem } from '../types';
import type { Annotation } from '@pointorama/database';

const isAnnotationArray = (items: any): items is Annotation[] => items.every((item: any) => isAnnotationItem(item));

export const addOrderedIdentifier = (
  state: PointCloudCommandManagerState,
  {
    groupIdentifier,
    identifier,
    insertAtIndex,
    itemIdentifierKey,
    orderedIdentifiersKey,
  }: {
    groupIdentifier?: string | null;
    identifier: string;
    insertAtIndex?: number;
    itemIdentifierKey: 'annotations' | 'wmsLayers';
    orderedIdentifiersKey: 'orderedIdentifiers' | 'wmsLayerOrderedIdentifiers';
  }
) => {
  if (insertAtIndex) {
    state[orderedIdentifiersKey] = state[orderedIdentifiersKey]
      .concat({ identifier: identifier, index: insertAtIndex })
      .reverse();
    fixOrderIndices(state);
    return;
  }
  let index = state[itemIdentifierKey].length - 1;
  const items = state[itemIdentifierKey];
  if (groupIdentifier && isAnnotationArray(items))
    index = items.filter((item) => item && item.groupIdentifier === groupIdentifier).length;
  state[orderedIdentifiersKey] = state[orderedIdentifiersKey].concat({ identifier: identifier, index }).reverse();
};
