import classNames from 'classnames';
import React, { memo } from 'react';

export type TextAreaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
const $TextArea: React.FC<TextAreaProps> = ({ className, ...props }) => {
  return (
    <textarea
      rows={3}
      className={classNames(
        'dark:text-white border-gray-300 dark:bg-[#313131] focus:ring-transparent focus:border-neon-green-600 dark:focus:border-neon-green-300',
        'w-full block rounded-md transition focus:ring focus:ring-opacity-50',
        className
      )}
      {...props}
    />
  );
};

export const TextArea = memo($TextArea) as typeof $TextArea;
