import { T } from '../../../translation/src';
import { toggleSelectedAnnotation } from "../../../../src/redux/rendererReducer";
import { useAnnotations } from '../../../../src/hooks/potree/useRenderer';
import { useCalculationTranslations } from '.';
import { useDispatch } from 'react-redux';
import { memo } from 'react';
import { Calculations } from '../../../../src/contexts/AnnotationContext';

interface CalculationListProps {
  title: string;
  listedCalculations: Calculations;
}

const $CalculationList: React.FC2<CalculationListProps> = ({
    title,
    listedCalculations,
  }) => {
    const [{ annotationsByIdentifier }] = useAnnotations();
    const calculationTranslations = useCalculationTranslations();
    const dispatch = useDispatch();
    const uniqueCalculations = Array.from(new Set(listedCalculations.map(c => c.annotationIdentifier))).map(id => {
      return listedCalculations.find(c => c.annotationIdentifier === id);
    });
    return (
      <div className="flex flex-col">
        <div className="my-4 text-sm font-bold">
          <T _str={title} />
        </div>
        {uniqueCalculations.map((calculation) => {
          if (!calculation) return null;
          const annotation = annotationsByIdentifier[calculation.annotationIdentifier];
          if (!annotation) return null;
          const calculationDescription = calculationTranslations[calculation.__typename as 'VolumeCalculation'];

          return (
            <div
              key={`${calculation.annotationIdentifier}`}
              className="flex items-center justify-center p-3 my-2 font-semibold border rounded-full cursor-pointer dark:text-neon-green-300 border-eerie-black dark:border-neon-green-300 hover:bg-neon-green-300 dark:hover:text-black"
              onClick={() =>
                dispatch(toggleSelectedAnnotation({ identifier: calculation.annotationIdentifier, clearOtherSelections: true, type: 'annotation' }))
              }
            >
              {annotation.name} - {calculationDescription}
            </div>
          );
        })}
      </div>
    );
  };
  
  export const CalculationList = memo($CalculationList);