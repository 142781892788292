import { UserContext } from '../../../contexts/UserContext';
import { UserRole } from '../../../types/graphqlTypes';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useUserRole = () => {
  const currentUser = useContext(UserContext);
  const { organisationId } = useParams();
  const role = useMemo(() => {
    return currentUser.rolesByOrganisation.find((role) => role.organisationId === organisationId)?.role as UserRole;
  }, [currentUser, organisationId]);

  return role;
};
