import { AddPointClusterActionValue, PointCluster } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { AddAnnotationAction } from './AddAnnotationAction';

export class AddPointClusterAction extends AddAnnotationAction<AddPointClusterActionValue> {
  override execute(state: PointCloudCommandManagerState) {
    const annotation = {
      ...this.value,
      type: 'POINTCLUSTER' as const,
      identifier: this.value.annotationIdentifier,
    } as PointCluster;
    state.annotations.push(annotation);
    super.execute(state);
  }
}
