import { Position } from '../types/graphqlTypes';
import * as THREE from 'three';

export const useMeasurements = () => {
  const heightMeasurement = (points: Position[]) => {
    let height = 0;
    points.forEach((beginPoint, index) => {
      const endPoint = points[index + 1];
      if (!endPoint) return;
      const min = Math.min(beginPoint.z, endPoint.z);
      const max = Math.max(beginPoint.z, endPoint.z);
      height += max - min;
    });
    // default meter, use this for now
    return height;
  };

  const slopeDistanceMeasurement = (points: Position[]) => {
    let distance = 0;
    points.forEach((beginPoint, index) => {
      const endPoint = points[index + 1];
      if (!endPoint) return;
      distance += new THREE.Vector3(beginPoint.x, beginPoint.y, beginPoint.z).distanceTo(
        new THREE.Vector3(endPoint.x, endPoint.y, endPoint.z)
      );
    });
    // default meter, use this for now
    return distance;
  };

  const perimeterMeasurement = (points: Position[]) => {
    let perimeter = 0;
    let j = points.length - 1;
    for (let i = 0; i < points.length; i++) {
      const p1 = points[i];
      const p2 = points[j];
      perimeter += Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2) + Math.pow(p2.z - p1.z, 2));
      j = i;
    }
    return perimeter;
  };

  const areaMeasurement = (points: Position[]) => {
    let area = 0;
    let j = points.length - 1;
    for (let i = 0; i < points.length; i++) {
      const p1 = points[i];
      const p2 = points[j];
      area += (p2.x + p1.x) * (p1.y - p2.y);
      j = i;
    }
    area = Math.abs(area / 2);
    area = (area / Math.pow(1, 2)) * Math.pow(1, 2); //convert to square meters then to the square display unit
    return area;
  };

  return { heightMeasurement, slopeDistanceMeasurement, perimeterMeasurement, areaMeasurement };
};
