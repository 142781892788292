import { useMemo } from "react";
import { AnnotationContextGroup } from "../../contexts/AnnotationContext";
import { useCustomSelector } from "../../redux/store";
import { Annotation } from "../../types/graphqlTypes";
import { useAnnotations } from "./useRenderer";

export const useSelectedAnnotations = () => {
  const { selectedAnnotations } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedAnnotations']
  );

  const [{ annotationsByIdentifier, annotations }] = useAnnotations();

  const allSelectedAnnotations = useMemo(() => {
    return selectedAnnotations.reduce((result: Annotation[], selectionItem) => {
      if (selectionItem.type === 'annotation') {
        const annotation = annotationsByIdentifier[selectionItem.identifier];
        if (annotation) {
          return [...result, annotation];
        }
      } else if (selectionItem.type === 'group') {
        const group = annotations.find((annotation) => annotation.identifier === selectionItem.identifier) as AnnotationContextGroup;
        if (group) {
          return [...result, ...group.annotations]
        }
      }
      return result;
    }, []);
  }, [annotations, annotationsByIdentifier, selectedAnnotations])

  return { allSelectedAnnotations };
}