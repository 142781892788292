import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import * as uuid from 'uuid';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikCADFileInput } from '../../../components/formik/FormFileInput';
import { T } from '../../../translation/src';
import { InputObjectGroup } from '../../../types/graphqlTypes';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { GeoJSONParser, KMLFileParser } from '../../../utils/CADFileParser';
import { getFileExtension } from '../../../utils/getFileExtension';
import { ProjectionSystems, isValidProjectionSystem } from '../ProjectProjectionSystemModal';

interface FormValues {
  files?: FileList;
}

interface AddCADLayerModalProps {
  onClose: () => void;
  open?: boolean;
  projectionSystem: string;
}
const $AddCADLayerModal: React.FC2<AddCADLayerModalProps> = ({ onClose, open, projectionSystem }) => {
  const [loading, setLoading] = useState(false);
  const [executeAction] = useExecuteAction();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
        setLoading(false);
      }, 250);
    },
    [onClose]
  );

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async ({ files }, helpers) => {
      setLoading(true);
      const cadLayerId = uuid.v4();
      const file = files?.[0];
      if (!file) return;
      const fileExtension = getFileExtension(file.name).toLowerCase();
      let cadObjectGroups: InputObjectGroup[] = [];
      if (!isValidProjectionSystem(projectionSystem)) throw new Error('Invalid projection system');
      const projectionSystemDefinition = ProjectionSystems[projectionSystem];
      if (fileExtension === 'kml') {
        const parser = new KMLFileParser({ file, cadLayerId, projectionSystem: projectionSystemDefinition });
        cadObjectGroups = await parser.parse();
      } else if (fileExtension === 'geojson') {
        const parser = new GeoJSONParser({ file, cadLayerId, projectionSystem: projectionSystemDefinition });
        cadObjectGroups = await parser.parse();
      }
      executeAction({
        type: 'ADD_CAD_LAYER',
        action: {
          cadLayerId,
          name: 'CAD layer',
          cadObjectGroups: cadObjectGroups,
        },
      });
      onSuccess({ helpers });
    },
    [executeAction, onSuccess, projectionSystem]
  );

  const formik: FormikConfig<FormValues> = useMemo(() => ({ initialValues: {}, onSubmit }), [onSubmit]);

  return (
    <CreateModal
      title={<T _str="Add a new CAD layer" swc />}
      createButtonTitle={<T _str="upload" swc />}
      formik={formik}
      onCancel={onCancel}
      open={open}
      isSubmitting={loading}
      alwaysCancellable
    >
      <FormikCADFileInput name="files" />
    </CreateModal>
  );
};

export const AddCADLayerModal = memo($AddCADLayerModal);
