import { useCallback, useContext, useEffect, useMemo } from 'react';
import { AnnotationContext } from '../../contexts/AnnotationContext';
import { RendererContext } from '../../contexts/RendererContext';
import { useRerender } from '../useRerender';
import { AnnotationsWithIndices } from '../../modules/renderer/RendererLeftPanel/Annotations/utils/getConvertedIndices';
import { CadObjectContext } from '../../contexts/CadLayersContext';
import { keyBy } from 'lodash/fp';
import { DecompressedCadLayer } from '../../pages/projects/helpers/decompressCadLayers';
import { emitter } from './useProject';

export enum RendererToolType {
  SELECT = 'SELECT',
  CLUSTER = 'CLUSTER',
  POINT = 'POINT',
  DISTANCE = 'DISTANCE',
  AREA = 'AREA',
  BOX = 'BOX',
}

export interface IClassification {
  identifier: string;
  name: string;
  code: number;
  color: [number, number, number, number];
  custom: boolean;
}

export const useClassifications = () => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const rerender = useRerender();

  useEffect(() => {
    emitter.on('CLASSIFICATIONS_UPDATES', rerender);
  }, [rerender]);

  return [
    {
      classifications: viewer?.classificationsInfo.classes || [],
      hiddenClassesByPointCloudId: viewer?.classificationsInfo.hiddenClassesByPointCloud || {},
    },
  ];
};

export const useAnnotations = () => {
  const annotations = useContext(AnnotationContext);
  const indexedAnnotations = useMemo(() => {
    let index = 0;
    return annotations.annotations.map((annotation) => {
      const result = {
        ...annotation,
        index,
        ...(annotation.__typename === 'AnnotationGroup' && {
          annotations: annotation.annotations.map((annotation, idx) => ({ ...annotation, index: index + idx + 1 })),
        }),
      };
      index = annotation.__typename === 'AnnotationGroup' ? index + annotation.annotations.length + 1 : index + 1;
      return result;
    }) as AnnotationsWithIndices;
  }, [annotations.annotations]);

  return [{ ...annotations, indexedAnnotations }];
};

export const getCadObjectsByIdentifier = (cadLayers?: DecompressedCadLayer[]) => {
  const cadObjectsByIdentifier = cadLayers?.flatMap((cadLayer) =>
    cadLayer.cadObjectGroups.flatMap((cadObjectGroup) => cadObjectGroup.cadObjects)
  );
  return keyBy('identifier', cadObjectsByIdentifier);
};

export const useCADObjects = () => {
  const cadObjects = useContext(CadObjectContext);
  const cadObjectsByIdentifier = useMemo(() => {
    return getCadObjectsByIdentifier(cadObjects.cadLayers);
  }, [cadObjects.cadLayers]);
  return [{ ...cadObjects, cadObjectsByIdentifier }];
};

export const useRendererTools = () => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const rerender = useRerender();

  const selectedTool = (() => {
    if (!viewer) return;
    if (viewer.measuringTool.currentTool) return viewer.measuringTool.currentTool;
    if (viewer.volumeTool.currentVolume) return RendererToolType.BOX;
    if (viewer.selectionTool.active) return RendererToolType.SELECT;
    if (viewer.clusterTool.active) return RendererToolType.CLUSTER;
    return;
  })() as RendererToolType;

  const changeTool = useCallback(
    ({ type }: { type: RendererToolType }) => {
      if (!viewer) return;
      viewer.measuringTool.setCurrentTool(undefined);
      viewer.volumeTool.deactivate();
      viewer.selectionTool.deactivate();
      viewer.clusterTool.deactivate();
      if (type === RendererToolType.SELECT) viewer.selectionTool.activate();
      if (type === RendererToolType.CLUSTER) {
        viewer.clusterTool.activate();
      }
      if (type === RendererToolType.POINT) viewer.measuringTool.setCurrentTool('POINT' as any);
      if (type === RendererToolType.DISTANCE) viewer.measuringTool.setCurrentTool('DISTANCE' as any);
      if (type === RendererToolType.AREA) viewer.measuringTool.setCurrentTool('AREA' as any);
      if (type === RendererToolType.BOX) viewer.volumeTool.activate();
    },
    [viewer]
  );

  useEffect(() => {
    if (!viewer) return;
    viewer.addEventListener('controls_changed', rerender);
    viewer.addEventListener('measure_tool_changed', rerender);
    return () => {
      viewer.removeEventListener('controls_changed', rerender);
      viewer.removeEventListener('measure_tool_changed', rerender);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!viewer]);

  const destroyRenderer = useCallback(() => {
    if (!viewer) return;
    viewer?.toggleMapOverlay();
  }, [viewer]);

  const actions = useMemo(() => {
    return { changeTool, destroyRenderer };
  }, [changeTool, destroyRenderer]);

  const states = useMemo(() => {
    return { selectedTool };
  }, [selectedTool]);

  return [states, actions] as [typeof states, typeof actions];
};

export const useRenderer = () => {
  const [toolActions, toolState] = useRendererTools();

  const actions = useMemo(() => {
    return { ...toolActions };
  }, [toolActions]);

  const states = useMemo(() => {
    return { ...toolState };
  }, [toolState]);

  return [states, actions];
};
