import { UpdatePositionActionValue } from '@pointorama/database';
import { keyBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { updateOrderedIdentifiers } from '../helpers/updateOrderedIdentifiers';

export class UpdatePositionAction implements IAction<UpdatePositionActionValue> {
  public identifier: string;
  _previousGroupIdentifier: string | undefined | null;
  _previousOrderedIdentifiers: { identifier: string; index: number }[] = [];
  constructor(public value: UpdatePositionActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotationsByIdentifier = keyBy(state.annotations, 'identifier');
    const annotation = annotationsByIdentifier[this.value.identifiers[0].identifier];
    if (annotation) this._previousGroupIdentifier = annotation.groupIdentifier;
    this.value.identifiers.forEach(({ identifier, type }) => {
      const currentOrderedIdentifier = state.orderedIdentifiers.find((info) => info.identifier === identifier);
      if (!currentOrderedIdentifier) return;
      this._previousOrderedIdentifiers.push({ ...currentOrderedIdentifier });
      updateOrderedIdentifiers(state, {
        ...this.value,
        itemIdentifier: type === 'annotation' ? identifier : undefined,
        groupIdentifier: type === 'group' ? identifier : undefined,
        itemIdentifierKey: 'annotations',
        groupIdentifierKey: 'groups',
        orderedIdentifiersKey: 'orderedIdentifiers',
      });
    });
  }
  undo(state: PointCloudCommandManagerState) {
    const identifiers = this.value.identifiers.map(({ identifier }) => identifier);
    state.annotations.forEach((annotation) => {
      if (identifiers.includes(annotation.identifier)) annotation.groupIdentifier = this._previousGroupIdentifier;
    });
    this.value.identifiers.forEach(({ identifier, type }) => {
      const previousOrderedIdentifier = this._previousOrderedIdentifiers.find((od) => od.identifier === identifier);
      if (!previousOrderedIdentifier) return;
      updateOrderedIdentifiers(state, {
        toGroupIdentifier: this._previousGroupIdentifier,
        toIndex: previousOrderedIdentifier.index,
        itemIdentifier: type === 'annotation' ? identifier : undefined,
        groupIdentifier: type === 'group' ? identifier : undefined,
        itemIdentifierKey: 'annotations',
        groupIdentifierKey: 'groups',
        orderedIdentifiersKey: 'orderedIdentifiers',
      });
    });
    this._previousOrderedIdentifiers = [];
    this._previousGroupIdentifier = undefined;
  }
}
