import { AddDistanceActionValue, DistanceAnnotation } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { AddAnnotationAction } from './AddAnnotationAction';

export class AddDistanceAction extends AddAnnotationAction<AddDistanceActionValue> {
  override execute(state: PointCloudCommandManagerState) {
    const annotation = {
      ...this.value,
      type: 'DISTANCE',
      identifier: this.value.annotationIdentifier,
    } as DistanceAnnotation;
    state.annotations.push(annotation);
    super.execute(state);
  }
}
