import { ClipMethods, BoxAnnotation, useProjectUpdateClippingMethodMutation } from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { T } from '../../../translation/src';
import { ConvertType, useFormatNumber } from '../../../hooks/useFormatNumber';
import { EditableColorProperty } from './ColorProperties';
import { memo, useCallback } from 'react';
import { SelectProps } from '../../../components/inputs/Select';
import { ClipMethodProperty } from './ClipMethodProperties';

interface BoxPropertiesProps {
  projectId: string;
  annotation: BoxAnnotation;
}
const $BoxProperties: React.FC2<BoxPropertiesProps> = ({ projectId, annotation }) => {
  const { formatNumber } = useFormatNumber();

  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();

  const clipMethod = annotation.annotationFilter?.clipMethod || ClipMethods.None;
  const onChangeClippingMethod: SelectProps<ClipMethods>['onChange'] = useCallback(
    (event) => {
      updateClippingMethod({
        variables: { projectId, annotationIdentifiers: [annotation.identifier], clipMethod: event.target.value },
      });
    },
    [updateClippingMethod, projectId, annotation]
  );

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="box" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={<T _str="length" swc />}>
          {formatNumber(annotation.scale.x, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
        <Property title={<T _str="width" swc />}>
          {formatNumber(annotation.scale.y, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
        <Property title={<T _str="height" swc />}>
          {formatNumber(annotation.scale.z, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <EditableColorProperty annotations={[annotation]} />
      </PropertiesSection>
      <PropertiesSection title={<T _str="filters" swc />}>
        <ClipMethodProperty onChange={onChangeClippingMethod} clipMethod={clipMethod}></ClipMethodProperty>
      </PropertiesSection>
    </div>
  );
};
export const BoxProperties = memo($BoxProperties);
