import { useApolloClient } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { NOTIFICATIONS } from '../../../graphql/notification';
import {
  NotificationsQuery,
  useNotificationsLazyQuery,
  useNotificationsMarkAsReadMutation,
} from '../../../types/graphqlTypes';
import { pusher } from '../../pusher';

export const useNotifications = () => {
  const { projectId = '' } = useParams();
  const [fetchNotifications] = useNotificationsLazyQuery();
  const [readNotification] = useNotificationsMarkAsReadMutation();
  const user = useContext(UserContext);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (!user.id) return;
    const channel = pusher.subscribe(`user-${user.id}`);
    channel.bind('notification', async () => {
      const notifications = await fetchNotifications({ fetchPolicy: 'no-cache' });
      const notificationsToRead: string[] = [];
      // Automatically read calculation notifications if focussed on project to prevent notification spam
      apolloClient.writeQuery<NotificationsQuery>({
        query: NOTIFICATIONS,
        data: {
          notifications:
            notifications.data?.notifications.map((notification) => {
              if (
                notification.__typename === 'PointCloudCalculationDoneNotification' &&
                notification.meta.projectId === projectId
              ) {
                if (!notification.isRead) notificationsToRead.push(notification.id);
                return { ...notification, isRead: true };
              }
              return notification;
            }) || [],
          __typename: 'Query',
        },
      });
      if (notificationsToRead.length > 0) await readNotification({ variables: { ids: notificationsToRead } });
    });
    return () => {
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, user.id]);
};
