import { PointCloudCommandManagerState } from './PointCloudCommandManager';
import type { Annotation, WmsLayer } from '@pointorama/database';

export const isWmsLayerItem = (item?: WmsLayer | Annotation): item is WmsLayer => {
  if (!item) return false;
  if ('layerName' in item && 'type' in item && (item.type === 'WMS' || item.type === 'WMTS')) return true;
  return false;
};

export const isAnnotationItem = (item?: WmsLayer | Annotation): item is Annotation => {
  if (!item) return false;
  return !!item.type && !isWmsLayerItem(item);
};

export interface IAction<Value> {
  value: Value;
  execute: (state: PointCloudCommandManagerState) => void;
  undo: (state: PointCloudCommandManagerState) => void;
}

export type NotNull<T> = T extends null ? never : T;
export type NotUndefined<T> = T extends undefined ? never : T;
export type NotNullOrUndefined<T> = NotNull<NotUndefined<T>>;

export function isNotNull<T>(input: T): input is NotNull<T> {
  return input !== null;
}

export function isNotUndefined<T>(value: T): value is NotUndefined<T> {
  return value !== undefined;
}

export function isNotNullOrUndefined<T>(value: T): value is NotNullOrUndefined<T> {
  return value != null;
}

export function isNullOrEmpty(value: Array<unknown> | null | undefined): value is null | undefined | [];
export function isNullOrEmpty(value: string | null | undefined): value is null | undefined | '';
export function isNullOrEmpty(value: Array<unknown> | string | null | undefined): value is null | undefined | '' | [] {
  return value == null || value.length === 0;
}
