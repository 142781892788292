import React, { ErrorInfo, ReactNode } from 'react';

import { Button } from '../../components/Button';
import { T, useT } from '../../translation/src';
import * as Sentry from '@sentry/browser';
import { useCurrentUserQuery } from '../../types/graphqlTypes';

const useTranslations = () => {
  const errorFallbackMessage = useT("It looks like we're having issues.");
  return { errorFallbackMessage };
};

export const ErrorFallback: React.FC2 = () => {
  const translations = useTranslations();
  const { data: userData } = useCurrentUserQuery();

  return (
    <div className="flex items-center justify-center dark:bg-[#313131] dark:text-white flex-col w-full h-full">
      <div className="mb-6 text-lg font-bold">{translations['errorFallbackMessage']}</div>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-3">
          {' '}
          <T _str="You can try reloading the page or send us more information about what might have gone wrong." />
        </div>
        <div className="flex space-x-2">
          <Button variant="primary" onClick={() => window.location.reload()}>
            <T _str="refresh the page" swc />{' '}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              // To test locally, make sure Sentry is initialised.
              Sentry.lastEventId() &&
                Sentry.showReportDialog({
                  user: { name: userData?.currentUser?.name, email: userData?.currentUser?.email },
                });
            }}
          >
            <T _str="send report" swc />{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.captureException(error, (scope) => {
      scope.setExtra('errorInfo', info);
      return scope;
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}
