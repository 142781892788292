import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { T } from '../../../translation/src';
import { Comment, useProjectDeleteCommentMutation } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

interface ProjectDeleteCommentModalProps {
  onClose: () => void;
  open?: boolean;
  comment?: Pick<Comment, 'id'>;
}
const $ProjectDeleteCommentModal: React.FC2<ProjectDeleteCommentModalProps> = ({ onClose, open, comment }) => {
  const { projectId = '' } = useParams();
  const [deleteComment, { loading: isSubmitting }] = useProjectDeleteCommentMutation();

  const onSubmit = useCallback(() => {
    if (!comment) return;
    deleteComment({
      variables: { projectId, commentId: comment.id },
      onCompleted: onClose,
    });
  }, [deleteComment, onClose, comment, projectId]);

  return (
    <SubmitModal
      title={<T _str="delete comment" swc />}
      actionButtonTitle={<T _str="delete comment" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="info"
        title={<T _str="This action cannot be undone." swc />}
        description={<T _str="Are you sure you want to delete this comment?" />}
      />
    </SubmitModal>
  );
};

export const ProjectDeleteCommentModal = memo($ProjectDeleteCommentModal);
