import { MouseEventHandler, memo, useCallback, useMemo } from 'react';
import LocationIcon from '../../../../assets/icons/location.svg?react';
import VectorLineIcon from '../../../../assets/icons/vector-line.svg?react';
import VectorPolygonIcon from '../../../../assets/icons/vector-polygon.svg?react';
import { CadLayer, CadObjectGroup, CadObject } from '../../../../types/graphqlTypes';
import { CadLayersContextType } from '../../../../contexts/CadLayersContext';
import { useCustomSelector } from '../../../../redux/store';
import { useSelectCadItem } from '../../../../hooks/potree/useSelectCadItem';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  FolderIcon,
  PencilIcon,
  TrashIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import LayersIcon from '../../../../assets/icons/layers.svg?react';
import useOpen from '../../../../hooks/useOpen';
import { AnnotationsIconsWrapper } from '../Annotations/AnnotationsIconsWrapper';
import { ContextMenu } from '../../../../components/ContextMenu';
import { T } from '../../../../translation/src';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import { v4 as uuid } from 'uuid';
import { useToggleHide } from '../../../../hooks/modules/project/useToggleHide';
import classNames from 'classnames';

const ICONS_BY_TYPE = {
  POINT: LocationIcon,
  LINE: VectorLineIcon,
  POLYGON: VectorPolygonIcon,
};

const $CadObjectComponent: React.FC2<{ cadObject: CadObject; parentSelected: boolean }> = ({
  cadObject,
  parentSelected,
}) => {
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);
  const selected = selectedCadItems?.some((cadItem) => cadItem.identifier === cadObject.identifier) || parentSelected;
  const { onClickCadItem } = useSelectCadItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickCadItem({ identifier: cadObject.identifier, type: 'cadObject' }, { isCtrl: false, isShift: false });
  }, [cadObject, onClickCadItem]);
  const [executeAction] = useExecuteAction();
  const { toggleHide } = useToggleHide();

  const onConvertCadObject = useCallback(() => {
    executeAction({
      type: 'CONVERT_CAD_OBJECT',
      action: {
        identifiers: [{ annotationIdentifier: uuid(), groupIdentifier: uuid() }],
        cadObjectIds: [cadObject.identifier],
      },
    });
    toggleHide({ identifiers: [cadObject.identifier], visible: false });
  }, [executeAction, cadObject.identifier, toggleHide]);

  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: cadObject.name,
    cadObjectIdentifier: cadObject.identifier,
  });
  const onDeleteCadObject = useCallback(() => {
    executeAction({ action: { cadObjectIdentifier: cadObject.identifier }, type: 'DELETE_CAD_OBJECT' });
  }, [executeAction, cadObject.identifier]);
  if (nameEditable) return nameInputComponent || null;
  return (
    <ContextMenu
      buttonChild={
        <SelectItemContent
          onClick={onClick}
          icon={ICONS_BY_TYPE[cadObject.type as keyof typeof ICONS_BY_TYPE]}
          active={selected}
          activeLevel={parentSelected ? 1 : 0}
          selectStyle="primary"
          idle={!cadObject.visible}
          className={'relative pl-24'}
          onDoubleClick={onToggleNameEditable}
        >
          <div className="overflow-hidden">{cadObject.name}</div>
          <AnnotationsIconsWrapper
            identifier={cadObject.identifier}
            visible={cadObject.visible}
            cadObject={cadObject}
          />
        </SelectItemContent>
      }
      panelClassName="divide-y"
    >
      <div className="flex flex-col">
        <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
          <T _str="rename" swc />
        </SelectItemContent>
      </div>
      <div className="flex flex-col">
        <SelectItemContent icon={TrashIcon} onClick={onDeleteCadObject}>
          <T _str="delete" swc />
        </SelectItemContent>
        <SelectItemContent icon={PlusCircleIcon} onClick={onConvertCadObject}>
          <T _str="Create annotation" swc />
        </SelectItemContent>
      </div>
    </ContextMenu>
  );
};
export const CadObjectComponent = memo($CadObjectComponent);

const $CadObjectGroupComponent: React.FC2<{ CadObjectGroup: CadObjectGroup; parentSelected: boolean }> = ({
  CadObjectGroup: cadObjectGroup,
  parentSelected,
}) => {
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);
  const selected =
    selectedCadItems?.some((cadItem) => cadItem.identifier === cadObjectGroup.identifier) || parentSelected;
  const { onClickCadItem } = useSelectCadItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickCadItem(
      { identifier: cadObjectGroup.identifier, type: 'cadObjectGroup' },
      { isCtrl: false, isShift: false }
    );
  }, [cadObjectGroup, onClickCadItem]);
  const { open: folderOpen, onOpen: onOpenFolder, onClose: onCloseFolder } = useOpen(false);
  const onToggleFolderOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (folderOpen) return onCloseFolder();
      return onOpenFolder();
    },
    [folderOpen, onCloseFolder, onOpenFolder]
  );
  const visible = cadObjectGroup.cadObjects.some((cadObject) => cadObject.visible);
  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: cadObjectGroup.name,
    cadObjectGroupIdentifier: cadObjectGroup.identifier,
  });
  const [executeAction] = useExecuteAction();
  const onDeleteCadObjectGroup = useCallback(() => {
    executeAction({ action: { cadObjectGroupIdentifier: cadObjectGroup.identifier }, type: 'DELETE_CAD_OBJECT_GROUP' });
  }, [executeAction, cadObjectGroup.identifier]);
  return (
    <>
      {nameEditable ? (
        nameInputComponent
      ) : (
        <ContextMenu
          buttonChild={
            <SelectItemContent
              onClick={onClick}
              icon={FolderIcon}
              active={selected || parentSelected}
              activeLevel={parentSelected ? 1 : 0}
              selectStyle="primary"
              idle={!visible}
              className={'relative pl-16'}
              onDoubleClick={onToggleNameEditable}
            >
              {
                <div
                  className="absolute flex items-center justify-center w-5 h-5 rounded left-9 hover:text-neon-green-400"
                  onClick={onToggleFolderOpen}
                >
                  {folderOpen ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
                </div>
              }
              <div className="overflow-hidden">{cadObjectGroup.name}</div>
              <AnnotationsIconsWrapper
                identifier={cadObjectGroup.identifier}
                visible={visible}
                cadObjectGroup={cadObjectGroup}
              />
            </SelectItemContent>
          }
          panelClassName="divide-y"
        >
          <div className="flex flex-col">
            <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
              <T _str="rename" swc />
            </SelectItemContent>
          </div>
          <div className="flex flex-col">
            <SelectItemContent icon={TrashIcon} onClick={onDeleteCadObjectGroup}>
              <T _str="delete" swc />
            </SelectItemContent>
          </div>
        </ContextMenu>
      )}
      {folderOpen &&
        cadObjectGroup.cadObjects.map((cadObject) => (
          <CadObjectComponent cadObject={cadObject} parentSelected={selected} key={cadObject.identifier} />
        ))}
    </>
  );
};
export const CadObjectGroupComponent = memo($CadObjectGroupComponent);

const $CadLayerComponent: React.FC2<{ cadLayer: CadLayer }> = ({ cadLayer }) => {
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);
  const selected = useMemo(
    () => selectedCadItems?.some((cadItem) => cadItem.identifier === cadLayer.identifier) || false,
    [selectedCadItems, cadLayer.identifier]
  );
  const { onClickCadItem } = useSelectCadItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickCadItem({ identifier: cadLayer.identifier, type: 'cadLayer' }, { isCtrl: false, isShift: false });
  }, [cadLayer, onClickCadItem]);
  const { open: folderOpen, onOpen: onOpenFolder, onClose: onCloseFolder } = useOpen(false);
  const onToggleFolderOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (folderOpen) return onCloseFolder();
      return onOpenFolder();
    },
    [folderOpen, onCloseFolder, onOpenFolder]
  );
  const visible = useMemo(() => {
    return cadLayer.cadObjectGroups.some((cadObjectGroup) =>
      cadObjectGroup.cadObjects.some((cadObject) => cadObject.visible)
    );
  }, [cadLayer]);
  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: cadLayer.name,
    cadLayerIdentifier: cadLayer.identifier,
  });
  const [executeAction] = useExecuteAction();
  const onDeleteCadLayer = useCallback(() => {
    executeAction({ action: { cadLayerIdentifier: cadLayer.identifier }, type: 'DELETE_CAD_LAYER' });
  }, [executeAction, cadLayer.identifier]);
  return (
    <>
      {nameEditable ? (
        nameInputComponent
      ) : (
        <ContextMenu
          buttonChild={
            <SelectItemContent
              onClick={onClick}
              icon={LayersIcon}
              active={selected}
              activeLevel={0}
              selectStyle="primary"
              idle={!visible}
              className={'relative pl-8'}
              onDoubleClick={onToggleNameEditable}
            >
              {
                <div
                  className="absolute flex items-center justify-center w-5 h-5 rounded left-1 hover:text-neon-green-400"
                  onClick={onToggleFolderOpen}
                >
                  {folderOpen ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
                </div>
              }
              <div className="overflow-hidden">{cadLayer.name}</div>
              <AnnotationsIconsWrapper identifier={cadLayer.identifier} visible={visible} cadLayer={cadLayer} />
            </SelectItemContent>
          }
          panelClassName="divide-y"
        >
          <div className="flex flex-col">
            <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
              <T _str="rename" swc />
            </SelectItemContent>
          </div>
          <div className="flex flex-col">
            <SelectItemContent icon={TrashIcon} onClick={onDeleteCadLayer}>
              <T _str="delete" swc />
            </SelectItemContent>
          </div>
        </ContextMenu>
      )}
      {folderOpen &&
        cadLayer.cadObjectGroups.map((cadObjectGroup) => (
          <CadObjectGroupComponent
            CadObjectGroup={cadObjectGroup}
            parentSelected={selected}
            key={cadObjectGroup.identifier}
          />
        ))}
    </>
  );
};
export const CadLayerComponent = memo($CadLayerComponent);

interface CadLayersProps {
  className?: string;
  cadLayers: CadLayersContextType['cadLayers'];
}

const $CadLayers: React.FC2<CadLayersProps> = ({ className, cadLayers }) => {
  return (
    <div className={classNames(className, 'py-2')}>
      {cadLayers?.map((cadLayer: CadLayer) => {
        return <CadLayerComponent cadLayer={cadLayer} key={cadLayer.identifier} />;
      })}
    </div>
  );
};
export const CadLayers = memo($CadLayers);
