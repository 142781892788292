import { memo } from 'react';
import useT, { useTArgs } from '../hooks/useT';

const T = ({ _str, ...args }: { _str: string } & useTArgs) => {
  const translation = useT(_str, args);

  return <>{translation}</>;
};

export default memo(T);
