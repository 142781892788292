import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { memo, MouseEventHandler, useCallback } from 'react';

export interface HideSelectorProps {
  hidden?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, visible: boolean) => void;
}
const $HideSelect: React.FC2<HideSelectorProps> = ({ hidden, onClick: onClickProps }) => {
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => onClickProps?.(event, !hidden),
    [hidden, onClickProps]
  );

  return (
    <div
      onClick={onClick}
      className="flex items-center justify-center w-6 h-6 text-[#6F7173] rounded-sm cursor-pointer hover:text-black dark:hover:text-neon-green-300"
    >
      {hidden ? <EyeSlashIcon className="w-4 h-4" /> : <EyeIcon className="w-4 h-4" />}
    </div>
  );
};

export const HideSelect = memo($HideSelect);
