import React, { useEffect } from 'react';

const WATest: React.FC = () => {
  useEffect(() => {
    // const importObject = {
    //   cwrap: {
    //     // @ts-ignore
    //     test: (arg) => {
    //       console.log(arg);
    //     },
    //   },
    // };

    // WebAssembly.instantiateStreaming(fetch('http://localhost:4200/assets/a.out.wasm'), importObject).then((obj) => {
    //   console.log('obj', obj);
    //   // @ts-ignore
    //   obj.instance.exports.test();
    // });

    // @ts-ignore
    console.log('into 1');
    const api = {
      // @ts-ignore
      test: Module.cwrap('test', 'number', ['number']),
    };
    console.log('api', api);
    // @ts-ignore
    console.log('here', api.test(4));

    // WebAssembly.instantiateStreaming(fetch('http://localhost:4200/assets/a.out.wasm'), {
    //   imports: {
    //     cwrap: () => {
    //       console.log('ok');
    //     },
    //   },
    // }).then((obj) => {
    //   console.log('obj', obj);
    // });
  });

  return <div>wa</div>;
};

export default WATest;
