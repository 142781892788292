import { MoveAnnotationsToGroupActionValue } from '@pointorama/database';
import { keyBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { fixOrderIndices } from '../helpers/fixOrderIndices';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class MoveAnnotationsToGroupAction implements IAction<MoveAnnotationsToGroupActionValue> {
  public identifier: string;
  private _previousIndices: { [key: string]: number } = {};
  private _previousGroupIdentifiers: { [key: string]: string | undefined | null } = {};
  constructor(public value: MoveAnnotationsToGroupActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotationsByIdentifier = keyBy(state.annotations, 'identifier');
    const groupsByIdentifier = keyBy(state.groups, 'identifier');
    const currentOrderedIdentifiers = state.orderedIdentifiers
      .filter((value) => {
        if (this.value.groupIdentifier)
          return annotationsByIdentifier[value.identifier]?.groupIdentifier === this.value.groupIdentifier;
        return !annotationsByIdentifier[value.identifier]?.groupIdentifier || !!groupsByIdentifier[value.identifier];
      })
      .map((value) => value.index);
    let annotationIndex = currentOrderedIdentifiers.length ? Math.max(...currentOrderedIdentifiers) + 1 : 0;
    this.value.annotationIdentifiers.forEach((annotationIdentifier) => {
      const annotation = state.annotations.find((annotation) => annotation.identifier === annotationIdentifier);
      if (!annotation) return;
      const orderedIdentifier = state.orderedIdentifiers.find((value) => value.identifier === annotationIdentifier);
      if (!orderedIdentifier) return;
      this._previousIndices[annotationIdentifier] = orderedIdentifier.index;
      this._previousGroupIdentifiers[annotationIdentifier] = annotation.groupIdentifier;
      annotation.groupIdentifier = this.value.groupIdentifier;
      orderedIdentifier.index = annotationIndex;
      annotationIndex++;
    });
    fixOrderIndices(state);
  }
  undo(state: PointCloudCommandManagerState) {
    this.value.annotationIdentifiers.forEach((annotationIdentifier) => {
      const annotation = state.annotations.find((annotation) => annotation.identifier === annotationIdentifier);
      if (!annotation) return;
      annotation.groupIdentifier = this._previousGroupIdentifiers[annotationIdentifier];
    });
    state.orderedIdentifiers = state.orderedIdentifiers.map((orderedIdentifier) => {
      if (!this._previousIndices[orderedIdentifier.identifier]) return orderedIdentifier;
      return { ...orderedIdentifier, index: this._previousIndices[orderedIdentifier.identifier] };
    });
    fixOrderIndices(state);
    this._previousIndices = {};
    this._previousGroupIdentifiers = {};
  }
}
