import { MenuItem } from '@headlessui/react';
import classNames from 'classnames';
import { FunctionComponent, MouseEventHandler, SVGProps, memo, useMemo } from 'react';
import { HeroIcon } from '../../types';

interface CommonProps {
  icon:
    | HeroIcon
    | FunctionComponent<
        SVGProps<SVGSVGElement> & {
          title?: string;
        }
      >;
  idle?: boolean;
  selectStyle?: 'primary' | 'secondary';
  activeLevel?: number;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
}

const $SelectItemContent: React.FC2<CommonProps & { active?: boolean; idle?: boolean }> = ({
  icon: Icon,
  children,
  active,
  activeLevel,
  selectStyle,
  idle,
  className: propsClassName,
  disabled,
  ...props
}) => {
  const className = useMemo(() => {
    if (selectStyle === 'secondary')
      return classNames({
        'border border-eerie-black bg-neon-green-300 dark:border-black dark:bg-eerie-black dark:text-white':
          active && activeLevel !== 1,
        'bg-neon-green-200 dark:bg-eerie-black dark:text-white': active && activeLevel === 1,
        'text-gray-500': idle || disabled,
        'cursor-not-allowed': disabled,
        'group hover:border border:eerie-black hover:bg-neon-green-300 dark:hover:bg-eerie-black hover:border-black dark:text-white':
          !disabled,
      });
    return classNames({
      'bg-neon-green-300 dark:bg-black': active && activeLevel !== 1,
      'bg-neon-green-200 dark:bg-eerie-black': active && activeLevel === 1,
      'text-gray-500': idle || disabled,
      'cursor-not-allowed': disabled,
      'hover:bg-neon-green-300 dark:hover:bg-black group': !disabled,
    });
  }, [active, activeLevel, disabled, idle, selectStyle]);

  return (
    <div
      className={classNames(
        `flex w-full items-center rounded-md px-2 py-2 text-sm border border-transparent cursor-pointer select-none`,
        className,
        propsClassName
      )}
      {...props}
    >
      <Icon
        className={'w-5 h-5 mr-2 group-hover:text-dark-green-800 dark:group-hover:text-neon-green-300 flex-shrink-0'}
      />
      {children}
    </div>
  );
};
export const SelectItemContent = memo($SelectItemContent);

const $SelectItem: React.FC2<CommonProps> = ({ icon, children, idle, activeLevel, ...props }) => {
  return (
    <MenuItem {...props}>
      {({ focus }) => (
        <SelectItemContent icon={icon} active={focus} activeLevel={activeLevel} idle={idle}>
          {children}
        </SelectItemContent>
      )}
    </MenuItem>
  );
};
export const SelectItem = memo($SelectItem);
