import { FormikInput } from '../../../components/formik/FormInput';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import classNames from 'classnames';
import { FormikConfig, FormikHelpers, useField } from 'formik';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { WmsType } from '../../../types/graphqlTypes';
import CheckMarkIcon from '../../../assets/icons/checkmark.svg?react';
import CrossIcon from '../../../assets/icons/cross.svg?react';

const useTranslations = () => {
  const namePlaceholder = useT('enter a layer name', { swc: true });
  const urlPlaceholder = useT('enter a url', { swc: true });
  const layerNamePlaceholder = useT('Select a layer', { swc: true });
  const usernamePlaceholder = useT('enter a username', { swc: true });
  const passwordPlaceholder = useT('enter a password', { swc: true });
  const invalidWmsUrl = useT(
    `invalid WM(T)S Url. Please check the url again or make sure the username and password are correct if needed.`,
    { swc: true }
  );
  return {
    namePlaceholder,
    urlPlaceholder,
    layerNamePlaceholder,
    usernamePlaceholder,
    passwordPlaceholder,
    invalidWmsUrl,
  };
};

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(translations.isRequired).max(120, translations.maxLength120),
        url: yup.string().url().trim().required(translations.invalidUrl),
        layerName: yup.string().trim().required(translations.isRequired),
        username: yup.string(),
        password: yup.string(),
      }),
    [translations]
  );

  return schema;
};

const getRawCapabilities = async (url: string, fetchOptions: { headers: { Authorization: string } } | undefined) => {
  const rawCapabilities = await new Promise<string>((resolve, reject) =>
    fetch(url, fetchOptions)
      .then((response) => resolve(response.text()))
      .catch(reject)
  );
  return rawCapabilities;
};

const convertUrl = (url: string) => {
  // Try to convert https://geo.api.vlaanderen.be/GRB/wmts to https://geo.api.vlaanderen.be/GRB/wmts?request=getcapabilities for example
  return url.includes('?') ? `${url}&request=getcapabilities` : `${url}?request=getcapabilities`;
};

const CapabilitiesHandler: React.FC<{
  onChangeCapabilities: (capabilities: Capabilities | null) => void;
  onChangeConvertedUrl: (url: string) => void;
  onError: (error: string) => void;
}> = ({ onChangeCapabilities, onChangeConvertedUrl, onError }) => {
  const [{ value: username }] = useField('username');
  const [{ value: password }] = useField('password');
  const [{ value: url }] = useField<string>('url');
  const translations = useTranslations();

  useEffect(() => {
    const handleCapabilities = async () => {
      if (!url) return;
      let authorization = '';
      if (username || password) {
        authorization = `Basic ${btoa(`${username}:${password}`)}`;
      }
      const fetchOptions = authorization ? { headers: { Authorization: authorization } } : undefined;
      let convertedUrl = url;
      try {
        let rawCapabilities = await getRawCapabilities(url, fetchOptions);
        const shouldTryConvert = !url.includes('?') || url.slice(url.lastIndexOf('?') + 1).length === 0;
        const isWMTSUrl = url.toLowerCase().includes('wmts');
        if (isWMTSUrl) {
          let capabilities = getWMTSCapabilities(rawCapabilities);
          if (!getWMTSLayers(capabilities).length && shouldTryConvert) {
            convertedUrl = convertUrl(url);
            rawCapabilities = await getRawCapabilities(convertedUrl, fetchOptions);
            capabilities = getWMTSCapabilities(rawCapabilities);
            if (!getWMTSLayers(capabilities).length) convertedUrl = url;
          }
          onChangeCapabilities({ type: 'WMTS', value: getWMTSCapabilities(rawCapabilities) });
        } else {
          let capabilities = getWMSCapabilities(rawCapabilities);
          if (!getWMSLayers(capabilities).length && shouldTryConvert) {
            convertedUrl = convertUrl(url);
            rawCapabilities = await getRawCapabilities(convertedUrl, fetchOptions);
            capabilities = getWMSCapabilities(rawCapabilities);
            if (!getWMSLayers(capabilities).length) convertedUrl = url;
            onChangeCapabilities({ type: 'WMS', value: capabilities });
          }
        }
        onChangeConvertedUrl(convertedUrl);
        onError('');
      } catch (error) {
        onError(translations.invalidWmsUrl);
        onChangeCapabilities(null);
      }
    };
    handleCapabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password, url, onChangeConvertedUrl]);

  return null;
};

const WMTSValidationMessage: React.FC<{ isValid?: boolean }> = ({ isValid }) => {
  const translations = useTranslations();
  const [{ value: url }, { touched: urlFieldTouched, error: urlFieldError }] = useField('url');

  if (!urlFieldTouched || urlFieldError || !url) return null;

  return !isValid ? (
    <div className="flex items-center text-red-500">
      <CrossIcon className="flex-shrink-0 w-4 h-4 mr-2" />
      {translations.invalidWmsUrl}
    </div>
  ) : (
    <div className="flex items-center text-green-600 ">
      <CheckMarkIcon className="flex-shrink-0 w-4 h-4 mr-2" />
      <T _str="detected a valid WM(T)S url" swc />!
    </div>
  );
};

interface FormValues {
  name: string;
  url: string;
  layerName: string;
  username: string;
  password: string;
}

interface WMTSCapabilities {
  Contents: {
    Layer: { Title: string; Identifier: string }[];
  };
}

interface WMSCapabilities {
  Capability: {
    Layer: {
      Layer: { Title: string; Name: string }[];
    };
    Service: {
      OnlineResource: string;
    };
  };
}

const getWMTSCapabilities = (rawCapabilities: string) => {
  const parser = new ol.format.WMTSCapabilities();
  const capabilities = parser.read(rawCapabilities);
  return capabilities as WMTSCapabilities;
};
const getWMSCapabilities = (rawCapabilities: string) => {
  const parser = new ol.format.WMSCapabilities();
  const capabilities = parser.read(rawCapabilities);
  return capabilities as WMSCapabilities;
};

type Capabilities = { type: 'WMTS'; value: WMTSCapabilities } | { type: 'WMS'; value: WMSCapabilities };

const getWMTSLayers = (capabilities: WMTSCapabilities) => capabilities.Contents?.Layer || [];
const getWMSLayers = (capabilities: WMSCapabilities) => capabilities.Capability?.Layer?.Layer || [];

const getLayerOptions = ({ capabilities }: { capabilities: Capabilities | null }) => {
  if (!capabilities) return [];
  if (capabilities.type === 'WMTS')
    return getWMTSLayers(capabilities.value).map((layer) => ({ label: layer.Title, value: layer.Identifier })) || [];
  return getWMSLayers(capabilities.value).map((layer) => ({ label: layer.Title, value: layer.Name })) || [];
};

export interface WMSLayerModalProps {
  onClose: () => void;
  open?: boolean;
  initialValues?: FormValues & { layerId: string };
}
const $WMSLayerModal: React.FC2<WMSLayerModalProps> = ({ onClose, open, initialValues }) => {
  const isUpdate = !!initialValues;
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const [capabilities, setCapabilities] = useState<Capabilities | null>(null);
  const [convertedUrl, setConvertedUrl] = useState<string>('');
  const [executeAction] = useExecuteAction();
  const [wmsError, setWmsError] = useState('');

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose]
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    (values, helpers) => {
      if (!capabilities) return;
      const actionValues = {
        name: values.name,
        url: convertedUrl,
        layerName: values.layerName,
        username: values.username,
        password: values.password,
        type: capabilities.type === 'WMTS' ? WmsType.Wmts : WmsType.Wms,
      };
      if (!isUpdate) {
        executeAction({
          type: 'ADD_WMS_LAYER',
          action: { ...actionValues, layerId: uuid() },
        });
      } else {
        executeAction({
          type: 'UPDATE_WMS_LAYER',
          action: { ...actionValues, layerId: initialValues.layerId },
        });
      }
      onSuccess({ helpers });
    },
    [capabilities, convertedUrl, isUpdate, onSuccess, executeAction, initialValues?.layerId]
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: {
        name: initialValues?.name || '',
        url: initialValues?.url || '',
        layerName: initialValues?.layerName || '',
        username: initialValues?.username || '',
        password: initialValues?.password || '',
      },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema, initialValues]
  );

  return (
    <CreateModal
      title={isUpdate ? <T _str="update WM(T)S layer" swc /> : <T _str="create WM(T)S layer" swc />}
      createButtonTitle={isUpdate ? <T _str="update layer" swc /> : <T _str="create layer" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      noOverflow
    >
      <CapabilitiesHandler
        onChangeCapabilities={setCapabilities}
        onChangeConvertedUrl={setConvertedUrl}
        onError={setWmsError}
      />
      <div className={classNames('flex flex-col')}>
        <FormikInput
          autoFocus
          required
          name="name"
          label={<T _str="name" swc />}
          placeholder={translations.namePlaceholder}
        />
        <FormikInput required name="url" label={<T _str="url" swc />} placeholder={translations.urlPlaceholder} />
        <FormikInput name="username" label={<T _str="username" swc />} placeholder={translations.usernamePlaceholder} />
        <FormikInput name="password" label={<T _str="password" swc />} placeholder={translations.passwordPlaceholder} />
        <FormikSelect
          name="layerName"
          label={<T _str="layer name" swc />}
          placeholder={translations.layerNamePlaceholder}
        >
          {getLayerOptions({ capabilities }).map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </FormikSelect>
      </div>
      <WMTSValidationMessage isValid={!wmsError && !!capabilities} />
    </CreateModal>
  );
};

export const WMSLayerModal = memo($WMSLayerModal);
