import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { memo } from 'react';
import { Popover, PopoverProps } from '.';

const $DotsPopover: React.FC2<Partial<PopoverProps>> = ({ children }) => {
  return (
    <Popover
      className="absolute right-2 top-2"
      buttonChild={<EllipsisHorizontalIcon className="w-5 text-gray-600 dark:text-white " />}
      buttonClassName="absolute flex items-center justify-center px-1 rounded right-1 top-1 hover:bg-white bg-gray-200 dark:bg-[#313131] dark:hover:bg-black"
    >
      {children}
    </Popover>
  );
};

export const DotsPopover = memo($DotsPopover);
