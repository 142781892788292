import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import useQueryParams from '../../hooks/useQueryParams';
import { T, useT } from '../../translation/src';

const useTranslations = () => {
  const INVALID_PROJECT_ID = useT('It seems that this project does not exist (anymore).');
  return { INVALID_PROJECT_ID };
};

export const InvalidProject: React.FC2 = () => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const { reason = '' } = useQueryParams(['reason']);
  const navigate = useNavigate();

  const reasonDescription = translations[reason as keyof typeof translations];
  const action = useMemo(() => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="mb-3">
          {' '}
          <T _str="Please open a different project." />
        </div>
        <div>
          <Button variant="primary" onClick={() => navigate(`/organisations/${organisationId}/projects`)}>
            <T _str="go to project overview" swc />{' '}
          </Button>
        </div>
      </div>
    );
  }, [navigate, organisationId]);

  return (
    <div className="flex items-center justify-center dark:bg-[#313131] dark:text-white flex-col w-full h-full">
      <div className="mb-6 text-lg font-bold">{reasonDescription}</div>
      {action}
    </div>
  );
};
