import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { T } from '../../../translation/src';
import { User, useUserDeleteFromOrganisationMutation } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

interface DeleteUserFromOrganisationModalProps {
  onClose: () => void;
  open?: boolean;
  user?: Pick<User, 'id' | 'name'>;
}
const $DeleteUserFromOrganisationModal: React.FC2<DeleteUserFromOrganisationModalProps> = ({ onClose, open, user }) => {
  const [deleteUser, { loading: isSubmitting }] = useUserDeleteFromOrganisationMutation();
  const { organisationId = '' } = useParams();

  const onSubmit = useCallback(() => {
    if (!user) return;
    deleteUser({
      variables: { userId: user?.id, organisationId },
      onCompleted: onClose,
      refetchQueries: ['currentUser', 'organisations'],
      update: (cache) => cache.evict({ id: `User:${user?.id}` }),
    });
  }, [deleteUser, onClose, organisationId, user]);

  return (
    <SubmitModal
      title={<T _str="remove {name} from organisation" swc args={{ name: user?.name || '' }} />}
      actionButtonTitle={<T _str="delete user" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="warning-red"
        title={<T _str="This action cannot be undone!" swc />}
        description={
          <T _str="Deleting this user can not be undone. Are you sure you want to remove this user from the organisation?" />
        }
      />
    </SubmitModal>
  );
};

export const DeleteUserFromOrganisationModal = memo($DeleteUserFromOrganisationModal);
