import { keyBy } from 'lodash/fp';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { isAnnotationItem, isWmsLayerItem } from '../types';
import { getItemsByIdentifier } from './getItemsByIdentifier';

const fixOrderedIdentifiers = (
  state: PointCloudCommandManagerState,
  value: {
    itemIdentifierKey: 'annotations' | 'wmsLayers';
    groupIdentifierKey?: 'groups';
    orderedIdentifiersKey: 'orderedIdentifiers' | 'wmsLayerOrderedIdentifiers';
  }
) => {
  const itemIdentifierKey = value.itemIdentifierKey;
  const groupIdentifierKey = value.groupIdentifierKey;
  const orderedIdentifiersKey = value.orderedIdentifiersKey;
  const itemsByIdentifier = getItemsByIdentifier({ state, itemIdentifierKey });
  const groupsByIdentifier = groupIdentifierKey ? keyBy('identifier', state[groupIdentifierKey]) : {};
  const orderedIdentifiersByGroup: { [key: string]: { identifier: string; index: number }[] } = { root: [] };
  /*
   * First, we add all items and groups without a group identifier to the root level.
   * Then, we add all items and groups with a group identifier to their respective group.
   */
  state[orderedIdentifiersKey].forEach((orderInfo) => {
    const item = itemsByIdentifier[orderInfo.identifier];
    const group = groupsByIdentifier[orderInfo.identifier];
    if (!item && !group) return;
    if (item && !isAnnotationItem(item)) return orderedIdentifiersByGroup['root'].push(orderInfo);
    if ((!isWmsLayerItem(item) && !item?.groupIdentifier) || group)
      return orderedIdentifiersByGroup['root'].push(orderInfo);
  });
  state[orderedIdentifiersKey].forEach((orderInfo) => {
    const item = itemsByIdentifier[orderInfo.identifier];
    const group = groupsByIdentifier[orderInfo.identifier];
    if (!item && !group) return;
    if (!isAnnotationItem(item)) return;
    if (!item?.groupIdentifier || group) return;
    if (orderedIdentifiersByGroup[item.groupIdentifier])
      return orderedIdentifiersByGroup[item.groupIdentifier].push(orderInfo);
    orderedIdentifiersByGroup[item.groupIdentifier] = [orderInfo];
    return;
  });
  state[orderedIdentifiersKey] = Object.values(orderedIdentifiersByGroup).reduce((result, list) => {
    return [...result, ...list.sort((a, b) => a.index - b.index).map((orderInfo, index) => ({ ...orderInfo, index }))];
  }, []);
};

export const fixOrderIndices = (state: PointCloudCommandManagerState) => {
  fixOrderedIdentifiers(state, {
    itemIdentifierKey: 'annotations',
    groupIdentifierKey: 'groups',
    orderedIdentifiersKey: 'orderedIdentifiers',
  });
  fixOrderedIdentifiers(state, { itemIdentifierKey: 'wmsLayers', orderedIdentifiersKey: 'wmsLayerOrderedIdentifiers' });
};
