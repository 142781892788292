import { DeleteWmsLayerActionValue, WmsLayer } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { removeOrderedIdentifier } from '../helpers/removeOrderedIdentifier';

export class DeleteWmsLayerAction implements IAction<DeleteWmsLayerActionValue> {
  public identifier: string;
  constructor(public value: DeleteWmsLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const wmsLayerIdentifier = this.value.wmsLayerIdentifier;
    state.wmsLayers = state.wmsLayers.filter((layer) => layer.identifier !== wmsLayerIdentifier);
    removeOrderedIdentifier(state, {
      identifier: wmsLayerIdentifier,
      orderedIdentifiersKey: 'wmsLayerOrderedIdentifiers',
    });
  }
  undo() {
    return;
  }
}
