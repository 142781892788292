import { useApolloClient, useMutation } from '@apollo/client';
import {
  AddAreaAction,
  AddDistanceAction,
  AddGroupAction,
  AddPointAction,
  DeleteAnnotationAction,
  DeleteAnnotationMultiAction,
  DeleteGroupAction,
  MoveAnnotationsToGroupAction,
  MovePointAction,
  AddAdditionalPointAction,
  DeletePointAction,
  UnGroupAction,
  UpdateNameAction,
  UpdatePositionAction,
  AddCadLayerAction,
  DeleteCadObjectGroupAction,
  DeleteCadObjectAction,
  DeleteCadItemMultiAction,
  ConvertCadObjectAction,
  DeleteCadLayerAction,
  AddBoxAction,
  EditBoxAction,
  AddPointClusterAction,
  EditPointClusterAction,
} from '@pointorama/pointcloud-commander';
import { keyBy } from 'lodash';
import { memo, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Action } from '.';
import RedoIcon from '../../../assets/icons/redo.svg?react';
import UndoIcon from '../../../assets/icons/undo.svg?react';
import { Tooltip } from '../../../components/Tooltip';
import { CommandManagerContext } from '../../../contexts/CommandManagerContext';
import { PROJECT_FRAGMENT, PROJECT_UNDO_ACTION } from '../../../graphql/project';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { emitter } from '../../../hooks/potree/useProject';
import { useRerender } from '../../../hooks/useRerender';
import { T } from '../../../translation/src';
import {
  ProjectFragment,
  ProjectUndoActionMutation,
  ProjectUndoActionMutationVariables,
  WmsType,
} from '../../../types/graphqlTypes';
import { compressData } from '../../../pages/projects/helpers/decompressCadLayers';

interface UndoRedoProps {}
const $UndoRedo: React.FC2<UndoRedoProps> = () => {
  const rerender = useRerender();
  const { projectId = '' } = useParams();
  const [undo] = useMutation<ProjectUndoActionMutation, ProjectUndoActionMutationVariables>(PROJECT_UNDO_ACTION);
  const commandManagerContext = useContext(CommandManagerContext);
  const { commander } = commandManagerContext;
  const [executeAction] = useExecuteAction();
  const apolloClient = useApolloClient();
  useEffect(() => {
    commander.on('ACTIONS_CHANGED', rerender);
  }, [commander, rerender]);

  const onUndo = useCallback(() => {
    const project = apolloClient.readFragment<ProjectFragment>({
      fragment: PROJECT_FRAGMENT,
      id: `Project:${projectId}`,
      fragmentName: 'Project',
    });
    if (!project) return;
    const annotationsByIdentifier = keyBy(project.state.annotations, 'identifier');
    const cadObjectsFlat = project.state.cadLayers?.flatMap((cadLayer) =>
      cadLayer.cadObjectGroups.flatMap((cadObjectGroup) => cadObjectGroup.cadObjects)
    );
    const cadObjectsByIdentifier = keyBy(cadObjectsFlat, 'identifier');
    const wmsLayersByIdentifier = keyBy(project.state.wmsLayers, 'identifier');
    const action = commander.undo();
    const newState = { ...commander.getState() };
    const pointCloudsStateById = keyBy(newState.pointClouds, 'identifier');
    undo({
      variables: { projectId, identifier: action?.identifier || '' },
      optimisticResponse: {
        __typename: 'Mutation',
        projectUndoAction: {
          id: projectId,
          __typename: 'Project',
          state: {
            ...newState,
            annotations: newState.annotations.map((annotation) => {
              const cacheAnnotation = annotationsByIdentifier[annotation.identifier];
              return {
                ...annotation,
                visible: cacheAnnotation?.visible || false,
                hiddenLabels: cacheAnnotation?.hiddenLabels || [],
              };
            }),
            groups: newState.groups.map((annotation) => {
              return { ...annotation };
            }),
            cadLayers: newState.cadLayers.map((layer) => {
              return {
                ...layer,
                cadObjectGroups: layer.cadObjectGroups.map((group) => {
                  return {
                    ...group,
                    cadObjects: group.cadObjects.map((object) => {
                      const cacheCadObject = cadObjectsByIdentifier[object.identifier];
                      const commonProperties = {
                        visible: cacheCadObject ? cacheCadObject.visible : true,
                      };
                      if (object.type === 'LINE')
                        return {
                          ...object,
                          ...commonProperties,
                          pointsCompressed:
                            cacheCadObject && cacheCadObject.__typename === 'CadLine'
                              ? cacheCadObject.pointsCompressed
                              : compressData(object.points),
                          __typename: 'CadLine',
                        };
                      if (object.type === 'POLYGON')
                        return {
                          ...object,
                          ...commonProperties,
                          pointsCompressed:
                            cacheCadObject && cacheCadObject.__typename === 'CadPolygon'
                              ? cacheCadObject.pointsCompressed
                              : compressData(object.points),
                          __typename: 'CadPolygon',
                        };
                      if (object.type === 'POINT')
                        return {
                          ...object,
                          ...commonProperties,
                          __typename: 'CadPoint',
                        };
                      return object;
                    }),
                  };
                }),
              };
            }),
            wmsLayers: (newState.wmsLayers || []).map((layer) => {
              const cacheWmsLayer = wmsLayersByIdentifier[layer.identifier];
              return {
                ...layer,
                type: layer.type === 'WMS' ? WmsType.Wms : WmsType.Wmts,
                visible: cacheWmsLayer ? cacheWmsLayer.visible : true,
              };
            }),
          },
          pointClouds: project.pointClouds.map((pointCloud) => ({
            ...pointCloud,
            displayName: pointCloudsStateById[pointCloud.id]?.name,
          })),
          calculations: project.calculations,
        },
      },
    });
    // @TODO: investigate why project does not render automatically in this case...
    emitter.emit('RERENDER');
  }, [apolloClient, commander, projectId, undo]);

  const redo = useCallback(() => {
    const action = commander.redo();

    if (action instanceof AddPointAction) executeAction({ action: action.value, type: 'POINT' }, { redo: true });
    if (action instanceof AddDistanceAction) executeAction({ action: action.value, type: 'DISTANCE' }, { redo: true });
    if (action instanceof AddAreaAction) executeAction({ action: action.value, type: 'AREA' }, { redo: true });
    if (action instanceof MovePointAction) executeAction({ action: action.value, type: 'MOVE_POINT' }, { redo: true });
    if (action instanceof AddAdditionalPointAction)
      executeAction({ action: action.value, type: 'ADD_ADDITIONAL_POINT' }, { redo: true });
    if (action instanceof DeletePointAction)
      executeAction({ action: action.value, type: 'DELETE_POINT' }, { redo: true });
    if (action instanceof AddGroupAction) executeAction({ action: action.value, type: 'ADD_GROUP' }, { redo: true });
    if (action instanceof DeleteAnnotationAction)
      executeAction({ action: action.value, type: 'DELETE_ANNOTATION' }, { redo: true });
    if (action instanceof DeleteGroupAction)
      executeAction({ action: action.value, type: 'DELETE_GROUP' }, { redo: true });
    if (action instanceof MoveAnnotationsToGroupAction)
      executeAction({ action: action.value, type: 'MOVE_ANNOTATIONS_TO_GROUP' }, { redo: true });
    if (action instanceof UnGroupAction) executeAction({ action: action.value, type: 'UN_GROUP' }, { redo: true });
    if (action instanceof UpdateNameAction)
      executeAction({ action: action.value, type: 'UPDATE_NAME' }, { redo: true });
    if (action instanceof UpdatePositionAction)
      executeAction({ action: action.value, type: 'UPDATE_POSITION' }, { redo: true });
    if (action instanceof DeleteAnnotationMultiAction)
      executeAction({ action: action.value, type: 'DELETE_ANNOTATION_MULTI' }, { redo: true });
    if (action instanceof AddCadLayerAction)
      executeAction({ action: action.value, type: 'ADD_CAD_LAYER' }, { redo: true });
    if (action instanceof DeleteCadObjectAction)
      executeAction({ action: action.value, type: 'DELETE_CAD_OBJECT' }, { redo: true });
    if (action instanceof DeleteCadObjectGroupAction)
      executeAction({ action: action.value, type: 'DELETE_CAD_OBJECT_GROUP' }, { redo: true });
    if (action instanceof DeleteCadLayerAction)
      executeAction({ action: action.value, type: 'DELETE_CAD_LAYER' }, { redo: true });
    if (action instanceof ConvertCadObjectAction)
      executeAction({ action: action.value, type: 'CONVERT_CAD_OBJECT' }, { redo: true });
    if (action instanceof AddBoxAction) executeAction({ action: action.value, type: 'BOX' }, { redo: true });
    if (action instanceof EditBoxAction) executeAction({ action: action.value, type: 'EDIT_BOX' }, { redo: true });
    if (action instanceof DeleteCadItemMultiAction)
      executeAction({ action: action.value, type: 'DELETE_CAD_OBJECT_MULTI' }, { redo: true });
    if (action instanceof AddPointClusterAction)
      executeAction({ action: action.value, type: 'POINTCLUSTER' }, { redo: true });
    if (action instanceof EditPointClusterAction)
      executeAction({ action: action.value, type: 'EDIT_POINTCLUSTER' }, { redo: true });
  }, [commander, executeAction]);

  return (
    <div className="flex">
      <Tooltip message={<T _str="undo" swc />} delay={0}>
        <Action icon={UndoIcon} disabled={!commander.getActionsNormal().length} onClick={onUndo} />
      </Tooltip>
      <Tooltip message={<T _str="redo" swc />} delay={0}>
        <Action icon={RedoIcon} disabled={!commander.getActionsReverse().length} onClick={redo} />
      </Tooltip>
    </div>
  );
};

export const UndoRedo = memo($UndoRedo);
