import { UpdateWmsLayerActionValue } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';

export class UpdateWmsLayerAction implements IAction<UpdateWmsLayerActionValue> {
  public identifier: string;
  constructor(public value: UpdateWmsLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const layerIndex = state.wmsLayers.findIndex((layer) => layer.identifier === this.value.layerId);
    const existingLayer = state.wmsLayers[layerIndex];
    state.wmsLayers[layerIndex] = { ...existingLayer, ...this.value, identifier: this.value.layerId };
  }
  undo() {
    return;
  }
}
