import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { removeOrderedIdentifier } from './removeOrderedIdentifier';

export const deleteAnnotation = (
  { annotationIdentifier }: { annotationIdentifier: string },
  { state }: { state: PointCloudCommandManagerState }
) => {
  const removedAnnotation = state.annotations.find((annotation) => annotation.identifier === annotationIdentifier);
  if (!removedAnnotation) return { removedAnnotation: undefined, removedAnnotationIndex: -1 };
  state.annotations = state.annotations.filter((annotation) => annotation.identifier !== annotationIdentifier);
  const removedAnnotationIndex = removeOrderedIdentifier(state, {
    identifier: annotationIdentifier,
    orderedIdentifiersKey: 'orderedIdentifiers',
  });
  return { removedAnnotation, removedAnnotationIndex };
};
