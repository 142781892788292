// @ts-nocheck
import { useLazyQuery } from '@apollo/client';
import React, { useContext, useLayoutEffect } from 'react';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { PotreeSourcesContext } from '../../contexts/PotreeSourcesContext';
import { REQUEST_READ_SAS_TOKEN } from '../../graphql/project';
import { ProjectRequestReadSasTokenQuery, ProjectRequestReadSasTokenQueryVariables } from '../../types/graphqlTypes';

const PotreeRenderer: React.FC = () => {
  const { isLoaded: sourcesLoaded } = useContext(PotreeSourcesContext);
  const [projectRequestReadSASToken] = useLazyQuery<
    ProjectRequestReadSasTokenQuery,
    ProjectRequestReadSasTokenQueryVariables
  >(REQUEST_READ_SAS_TOKEN, { fetchPolicy: 'network-only' });

  const projectId = '123';

  useLayoutEffect(() => {
    if (!sourcesLoaded) return;
    const urlParams = new URLSearchParams(window.location.search);
    const pointCloudName = urlParams.get('pc');
    if (!pointCloudName) return;

    const loadPointCloudHeaderFiles = async () => {
      console.log('HERE -- loading header files');
      const SASUrl = await projectRequestReadSASToken({ variables: { projectId } });
      const SAStoken = SASUrl.data?.projectRequestReadSASToken || '';
      localStorage.setItem('SASToken_' + projectId, SAStoken);
      const viewer = window.viewer;
      Potree.loadPointCloud(`/${projectId}/${pointCloudName}.ppch?${SAStoken}`, `${pointCloudName}`, (e) => {
        e.pointcloud.projection = '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';
        viewer.scene.addPointCloud(e.pointcloud);
        const material = e.pointcloud.material;
        material.size = 1;
        material.pointSizeType = Potree.PointSizeType.ADAPTIVE;

        // viewer.scene.view.position.set(4821071.257615091, 588230.5064585616, 0);
        // viewer.scene.view.lookAt(4821071.257615091, 588230.5064585616, 40);

        viewer.fitToScreen();
      });
    };

    const initializeViewer = (pointCloudName = '') => {
      const viewer = new Potree.Viewer(document.getElementById('potree_render_area'), { useDefaultRenderLoop: false });
      window.viewer = viewer;

      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(3 * 1000 * 1000);
      viewer.loadSettingsFromURL();
      viewer.setDescription(pointCloudName);
      viewer.loadGUI(() => {
        viewer.setLanguage('en');
        // $('#menu_analytics').next().show();
        // $('#menu_layers').next().show();
        viewer.toggleSidebar();
      });
      loadPointCloudHeaderFiles();
    };

    initializeViewer(pointCloudName);
  }, [projectRequestReadSASToken, sourcesLoaded]);

  return (
    <>
      {<EarthLoader />}
      <div className="relative w-full h-full">
        <div className="absolute top-0 left-0 w-full h-full potree_container">
          <div id="potree_render_area">
            <div
              id="olContainer"
              style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'green' }}
            ></div>
          </div>
          <div id="potree_sidebar_container"></div>
        </div>
      </div>
    </>
  );
};

export default PotreeRenderer;
