import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ObjectsIcon from '../../../assets/icons/annotations.svg?react';
import LayersIcon from '../../../assets/icons/layers.svg?react';
import { TabPane, TabsContainer } from '../../../components/Tabs';
import { T } from '../../../translation/src';
import { PointCloud, Project } from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import useQueryParams from '../../../hooks/useQueryParams';
import { Annotations } from './Annotations';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { Layers } from './Layers';

enum Tabs {
  OBJECTS = 'OBJECTS',
  LAYERS = 'LAYERS',
}

interface RendererLeftPanelProps {
  project?: Pick<Project, 'settings' | 'id' | 'mapVisible'> & {
    pointClouds: Pick<
      PointCloud,
      'cloudName' | 'id' | 'displayName' | 'uploadStatus' | 'visible' | 'availableClasses'
    >[];
  };
  closePanel: () => void;
  openPanel: () => void;
  panelOpen: boolean;
  hideArrow?: boolean;
}
const $RendererLeftPanel: React.FC2<RendererLeftPanelProps> = ({
  project,
  closePanel,
  openPanel,
  panelOpen,
  hideArrow,
}) => {
  const { annotationIdentifier: annotationIdParam = '' } = useQueryParams(['annotationIdentifier']);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.OBJECTS);
  const { smDevice } = useDeviceSize();

  const onChangeTab = useCallback(
    ({ key }: { key: string }) => {
      if (!panelOpen) openPanel();
      setSelectedTab(key as typeof selectedTab);
    },
    [panelOpen, openPanel]
  );

  return (
    <div
      className={classNames(
        'relative z-10 flex flex-col flex-shrink-0 h-full px-3 py-3 dark:bg-[#242424] dark:text-white border-t border-gray-200',
        panelOpen ? (smDevice ? 'w-64' : 'w-96') : 'w-20'
      )}
    >
      {!hideArrow && (
        <div className="absolute z-40 flex items-center justify-center w-8 h-8 transform -translate-y-1/2 rounded-full cursor-pointer text-neon-green-400 dark:text-black bg-eerie-black dark:bg-neon-green-300 top-1/2 -right-4">
          {panelOpen ? (
            <ChevronLeftIcon className="w-4 h-4" onClick={closePanel} />
          ) : (
            <ChevronRightIcon className="w-4 h-4" onClick={openPanel} />
          )}
        </div>
      )}
      {panelOpen && (
        <>
          <TabsContainer
            onChange={onChangeTab}
            defaultActiveKey={selectedTab || Tabs.OBJECTS}
            tabWidth={smDevice ? '7.66rem' : '11.25rem'}
          >
            <TabPane key={Tabs.OBJECTS}>
              <div className="inline-flex items-center space-x-2 select-none">
                <ObjectsIcon />{' '}
                <div>
                  <T _str="objects" swc />
                </div>
              </div>
            </TabPane>
            <TabPane key={Tabs.LAYERS}>
              <div className="inline-flex items-center space-x-2 select-none">
                <LayersIcon />{' '}
                <div>
                  <T _str="layers" swc />
                </div>
              </div>
            </TabPane>
          </TabsContainer>
          {selectedTab === Tabs.OBJECTS && <Annotations defaultSelectedAnnotationId={annotationIdParam} />}
          {selectedTab === Tabs.LAYERS && <Layers project={project} />}
        </>
      )}
      {!panelOpen && (
        <div className="flex flex-col w-full h-full">
          <div
            className={classNames(
              'flex items-center justify-center cursor-pointer p-4 rounded-lg',
              selectedTab === Tabs.OBJECTS && 'bg-neon-green-300 dark:bg-black'
            )}
            onClick={() => onChangeTab({ key: Tabs.OBJECTS })}
          >
            <ObjectsIcon />
          </div>
          <div
            className={classNames(
              'flex items-center justify-center cursor-pointer p-4 rounded-lg',
              selectedTab === Tabs.LAYERS && 'bg-neon-green-300 dark:bg-black'
            )}
            onClick={() => onChangeTab({ key: Tabs.LAYERS })}
          >
            <LayersIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export const RendererLeftPanel = memo($RendererLeftPanel);
