import classNames from 'classnames';
import { memo } from 'react';
import { T } from '../../translation/src';
import { Button } from '../Button';

interface CardProps {
  className?: string;
  titleClassName?: string;
  title?: React.ReactNode;
  onSubmit?: () => void;
  submitButtonTitle?: string;
  isSubmitting?: boolean;
  noPadding?: boolean;
  noOverflow?: boolean;
}
const $Card: React.FC2<CardProps> = ({
  className,
  title,
  children,
  titleClassName,
  onSubmit,
  submitButtonTitle,
  isSubmitting,
  noPadding,
  noOverflow,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col bg-white border shadow rounded dark:bg-[#242424] dark:text-white',
        !noOverflow && 'overflow-hidden',
        className
      )}
    >
      {title && <div className={classNames('mb-4 text-lg font-bold px-4 pt-4', titleClassName)}>{title}</div>}
      <div className={classNames('w-full h-full', !noPadding && 'px-4 mb-4')}>{children}</div>
      {(onSubmit || submitButtonTitle) && (
        <div className="flex justify-end p-4 bg-gray-50 dark:bg-gray-900">
          <Button variant="primary" type="submit" size="xs" onClick={onSubmit} loading={isSubmitting}>
            {submitButtonTitle || <T _str="save" swc />}{' '}
          </Button>
        </div>
      )}
    </div>
  );
};

export const Card = memo($Card);
