import {
  CloudIcon,
  ArrowDownTrayIcon,
  ExclamationCircleIcon,
  PencilIcon,
  ArrowPathIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { MouseEventHandler, memo, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CircleSpinner } from '../../../../components/Button';
import { ContextMenu } from '../../../../components/ContextMenu';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import useOpen from '../../../../hooks/useOpen';
import { selectPointCloud } from '../../../../redux/rendererReducer';
import { T, useT } from '../../../../translation/src';
import { PointCloud, UploadStatus } from '../../../../types/graphqlTypes';
import { PointCloudDeleteModal } from '../../../project/PointCloudDeleteModal';
import { AnnotationsIconsWrapper } from '../Annotations/AnnotationsIconsWrapper';
import { ExportModal, ExportModalProps } from '../../../project/ExportModal';
import { useProjectExportPointcloudMutation } from '../../../../types/graphqlTypes';
import { UserContext } from '../../../../contexts/UserContext';
import { DATA_DEPTH } from '../../../../hooks/potree/usePointCloudProperties';

interface PointCloudLayerProps {
  pointCloud: Pick<PointCloud, 'id' | 'cloudName' | 'visible' | 'displayName' | 'uploadStatus' | 'availableClasses'>;
  active?: boolean;
  rootActive?: boolean;
  className?: string;
  groupIdentifier?: string;
  refreshSuggested?: boolean;
  groupHidden?: boolean;
  isLastPointCloud?: boolean;
}

const useTranslations = () => {
  const exportTitle = useT('export', { swc: true });
  return { exportTitle };
};

const $PointCloudLayer: React.FC2<PointCloudLayerProps> = ({
  active,
  pointCloud,
  className,
  rootActive,
  refreshSuggested,
  groupIdentifier,
  groupHidden,
  isLastPointCloud,
}) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { open: deleteModalOpen, onClose: closeDeleteModal, onOpen: openDeleteModal } = useOpen();
  const { onOpen: openExportModal, onClose: closeExportModal, open: exportModalOpen } = useOpen();
  const translations = useTranslations();
  const [exportPointcloud] = useProjectExportPointcloudMutation();
  const { organisationId = '' } = useParams();
  const currentUser = useContext(UserContext);

  const isFreeTrial = useMemo(() => {
    const organisation = currentUser.organisations.find((organisation) => organisation.id === organisationId);
    return organisation?.subscription.isFreeTrial;
  }, [currentUser.organisations, organisationId]);

  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: pointCloud.displayName,
    pointCloudIdentifier: pointCloud.id,
  });

  const onSubmitExport: ExportModalProps['onSubmit'] = useCallback(
    async (pointClouds, extension) => {
      if (!projectId) return;
      pointClouds.forEach((p) => {
        exportPointcloud({
          variables: {
            projectId,
            pointCloudId: p.id,
            fileType: extension,
            classificationFilters:
              p.filters?.classifications && p.filters?.classifications.length > 0
                ? p.filters?.classifications.map((cl) => +cl.code)
                : undefined,
            ...(p.filters?.heightFilter && {
              heightFilters: [p.filters?.heightFilter.min, p.filters?.heightFilter.max],
            }),
            ...(p.filters?.intensityFilter && {
              intensityFilters: [p.filters?.intensityFilter.min, p.filters?.intensityFilter.max],
            }),
            ...(p.filters?.resolutionFilter && {
              resolutionFilter: p.filters.resolutionFilter.resolutionLevel + DATA_DEPTH,
            }),
            ...(p.filters?.clippingFilter && {
              clippingFilter:
                p.filters.clippingFilter.nrOfClippingBoxes + p.filters.clippingFilter.nrOfClippingClusters,
            }),
          },
        });
      });
    },
    [projectId, exportPointcloud],
  );

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (pointCloud.uploadStatus !== UploadStatus.Success || refreshSuggested) return;
      event.preventDefault();
      event.stopPropagation();
      dispatch(selectPointCloud({ name: pointCloud.cloudName }));
    },
    [dispatch, pointCloud.cloudName, pointCloud.uploadStatus, refreshSuggested],
  );

  const actions = useMemo(() => {
    if (pointCloud.uploadStatus === UploadStatus.Success)
      if (!refreshSuggested)
        return <AnnotationsIconsWrapper identifier={pointCloud.id} visible={pointCloud.visible} pointCloud={true} />;
      else
        return (
          <div
            className="flex items-center justify-center w-6 h-6 ml-auto rounded-sm cursor-pointer text-[#6F7173] hover:text-neon-green-300"
            onClick={() => window.location.reload()}
          >
            <ArrowPathIcon className="w-4 h-4" />
          </div>
        );
    if (pointCloud.uploadStatus === UploadStatus.Progress)
      return (
        <div className="flex items-center justify-center w-6 h-6 ml-auto" title="Progress">
          <CircleSpinner className="w-4 h-4 text-neon-green-300" />
        </div>
      );
    return (
      <div className="flex items-center justify-center w-6 h-6 ml-auto" title="Upload Failed">
        <ExclamationCircleIcon className="w-4 h-4 text-red-500" />
      </div>
    );
    // return null
  }, [pointCloud.id, pointCloud.uploadStatus, pointCloud.visible, refreshSuggested]);

  if (nameEditable) return <>{nameInputComponent}</>;

  return (
    <>
      <ContextMenu
        buttonChild={
          <SelectItemContent
            key={pointCloud.id}
            icon={CloudIcon}
            selectStyle="primary"
            idle={!pointCloud.visible || pointCloud.uploadStatus !== UploadStatus.Success}
            onClick={onClick}
            active={rootActive || active}
            activeLevel={rootActive ? 1 : 0}
            className={classNames(!className?.includes('pl-') && 'pl-8', className)}
            onDoubleClick={onToggleNameEditable}
          >
            <div className="pr-1 overflow-hidden truncate">{pointCloud.displayName}</div>
            {actions}
          </SelectItemContent>
        }
        panelClassName="divide-y"
      >
        {pointCloud.uploadStatus === UploadStatus.Success && (
          <div className="flex flex-col">
            <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
              <T _str="rename" swc />
            </SelectItemContent>
          </div>
        )}
        <div className="flex flex-col">
          {(!isFreeTrial || currentUser.isSuperAdmin) && (
            <SelectItemContent
              icon={ArrowDownTrayIcon}
              onClick={openExportModal}
              className={isFreeTrial ? 'bg-error-red-500 text-black dark:hover:text-white' : ''}
            >
              <T _str="export" swc />
            </SelectItemContent>
          )}
          <SelectItemContent icon={TrashIcon} onClick={openDeleteModal}>
            <T _str="delete" swc />
          </SelectItemContent>
        </div>
      </ContextMenu>
      <PointCloudDeleteModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        pointCloudId={pointCloud.id}
        isLastPointCloud={isLastPointCloud}
      />
      <ExportModal
        pointClouds={[pointCloud]}
        onClose={closeExportModal}
        open={exportModalOpen}
        title={translations.exportTitle}
        onSubmit={onSubmitExport}
        multiplePointCloudsSupported={false}
      />
    </>
  );
};
export const PointCloudLayer = memo($PointCloudLayer);
