import { memo, useCallback, useContext } from 'react';
import * as THREE from 'three';
import Focus from '../../../../assets/icons/focus.svg?react';
import {
  CadObject,
  CadLayer,
  CadObjectGroup,
  Annotation as TypeAnnotation,
  useProjectUpdateClippingMethodMutation,
  ClipMethods,
  ProjectFragment,
} from '../../../../types/graphqlTypes';
import { RendererContext } from '../../../../contexts/RendererContext';
import classNames from 'classnames';
import { HideSelect, HideSelectorProps } from '../../../../components/HideSelector';
import { useToggleHide } from '../../../../hooks/modules/project/useToggleHide';
import { Icon } from '../../../../components/Icon';
import { Measure } from '../../../../../public/potree/potree/utils/Measure';
import { useAnnotations } from '../../../../hooks/potree/useRenderer';
import { AnnotationContextGroup } from '../../../../contexts/AnnotationContext';
import { useParams } from 'react-router-dom';
import { apolloClient } from '../../../../apollo';
import { PROJECT_FRAGMENT } from '../../../../graphql/project';

interface AnnotationsIconWrapperProps {
  identifier: string;
  visible?: boolean;
  disabled?: boolean;
  annotation?: Pick<TypeAnnotation, 'identifier' | 'groupIdentifier' | 'name'> & {
    type?: TypeAnnotation['type'];
    visible?: boolean;
  };
  pointCloud?: boolean;
  group?: AnnotationContextGroup;
  cadObject?: CadObject;
  cadObjectGroup?: CadObjectGroup;
  cadLayer?: CadLayer;
}

const $AnnotationsIconsWrapper: React.FC2<AnnotationsIconWrapperProps> = ({
  identifier,
  visible,
  disabled,
  annotation,
  pointCloud,
  group,
  cadObject,
  cadObjectGroup,
  cadLayer,
}) => {
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();
  const { projectId = '' } = useParams();
  const { toggleHide } = useToggleHide();
  const project = apolloClient.readFragment<ProjectFragment>({
    fragment: PROJECT_FRAGMENT,
    id: `Project:${projectId}`,
    fragmentName: 'Project',
  });

  const onToggleHideCluster = useCallback(() => {
    if (!annotation || !project) return;
    if (annotation.type !== 'POINTCLUSTER') return;
    updateClippingMethod({
      variables: { projectId, annotationIdentifiers: [annotation.identifier], clipMethod: ClipMethods.None },
      optimisticResponse: {
        __typename: 'Mutation',
        projectUpdateClippingMethod: {
          id: projectId,
          state: {
            annotations: project.state.annotations.map((someAnnotation) => {
              if (someAnnotation.identifier === annotation.identifier && someAnnotation.__typename === 'PointCluster')
                return {
                  ...someAnnotation,
                  annotationFilter: { ...someAnnotation.annotationFilter, clipMethod: ClipMethods.None },
                };
              return someAnnotation;
            }),
          },
        },
      },
    });
  }, [annotation, projectId, updateClippingMethod, project]);

  const onToggleHide: Required<HideSelectorProps>['onClick'] = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      let identifiers;
      if (group) {
        identifiers = group.annotations.map((annotation) => annotation.identifier);
      } else if (cadObjectGroup) {
        identifiers = cadObjectGroup.cadObjects.map((cadObject) => cadObject.identifier);
      } else if (cadLayer) {
        identifiers = cadLayer.cadObjectGroups.flatMap((cadObjectGroup) =>
          cadObjectGroup.cadObjects.map((cadObject) => cadObject.identifier)
        );
      } else {
        identifiers = [identifier];
      }
      toggleHide({ identifiers, visible: !visible });

      if (annotation?.type === 'POINTCLUSTER') onToggleHideCluster();
    },
    [identifier, group, toggleHide, visible, cadObjectGroup, cadLayer, annotation, onToggleHideCluster]
  );

  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;

  const [{ annotations }] = useAnnotations();

  const onFocusAnnotation = useCallback(() => {
    // We choose to select the annotation when using the focus tool.
    if (!viewer) return;

    if (annotation?.type === 'BOX') {
      const box = viewer.scene.volumes.find((volume) => volume.identifier === identifier);
      viewer.zoomTo(box, 1, 500);
    } else if (annotation || cadObject) {
      const identifier = annotation?.identifier || cadObject?.identifier;
      const measurement = viewer.scene.measurements.find((measure) => measure.identifier === identifier);
      if (!measurement) return;

      const node: ({ boundingBox?: THREE.Box3 } & Measure) | undefined = viewer.scene.measurements.find(
        (measure) => measure.identifier === identifier
      );
      if (!node) return;
      const points = measurement.points.map(
        (p) =>
          new THREE.Vector3(
            p.position.x - node.position.x,
            p.position.y - node.position.y,
            p.position.z - node.position.z
          )
      );

      if (measurement.type === 'POINT') {
        // expand is done to correct PointMeasure zoom behavior.
        node.boundingBox = new THREE.Box3().setFromPoints(points).expandByVector(new THREE.Vector3(10.0, 10.0, 0.0));
      } else {
        node.boundingBox = new THREE.Box3().setFromPoints(points);
      }

      viewer.zoomTo(node, 1, 500);
    } else if (cadObjectGroup || cadLayer) {
      const elements =
        cadLayer?.cadObjectGroups.flatMap((cadObjectGroup) =>
          cadObjectGroup.cadObjects.flatMap((cadObject) => cadObject)
        ) || cadObjectGroup?.cadObjects.flatMap((cadObject) => cadObject);
      const node: { boundingBox?: THREE.Box3 } & THREE.Group = new THREE.Group();
      node.boundingBox = new THREE.Box3();

      elements?.forEach((cadObject) => {
        if (cadObject.visible) {
          const anNode = viewer.scene.measurements.find((measure) => measure.identifier === cadObject.identifier);
          if (anNode) {
            node.add(anNode.clone());
            anNode.points.forEach((point) => {
              node.boundingBox?.expandByPoint(point.position);
            });
          }
        }
      });
      if (node.boundingBox.isEmpty()) return;
      node.boundingBox.expandByVector(new THREE.Vector3(10.0, 10.0, 0.0));
      viewer.zoomTo(node, 1, 500);
    } else if (pointCloud) {
      const node = viewer.scene.pointclouds.find((node) => node.identifier === identifier);
      if (!node) return;
      viewer.zoomTo(node, 1, 500);
    } else {
      const annotationGroup = annotations.find(
        (annotation) => annotation.identifier === identifier
      ) as AnnotationContextGroup;
      const node: { boundingBox?: THREE.Box3 } & THREE.Group = new THREE.Group();
      node.boundingBox = new THREE.Box3();

      annotationGroup.annotations.forEach((annotation) => {
        if (annotation.visible) {
          const anNode = viewer.scene.measurements.find((measure) => measure.identifier === annotation.identifier);
          if (anNode) {
            node.add(anNode.clone());
            anNode.points.forEach((point) => {
              node.boundingBox?.expandByPoint(point.position);
            });
          }
          const boxNode = viewer.scene.volumes.find((volume) => volume.identifier === annotation.identifier);
          if (boxNode) {
            node.add(boxNode.clone());
            node.boundingBox?.expandByPoint(boxNode.position);
          }
        }
      });
      if (node.boundingBox.isEmpty()) return;
      node.boundingBox.expandByVector(new THREE.Vector3(10.0, 10.0, 0.0));
      viewer.zoomTo(node, 1, 500);
    }
  }, [annotation, annotations, identifier, viewer, pointCloud, cadObject, cadObjectGroup, cadLayer]);

  return (
    <div className="flex justify-end flex-grow">
      <Icon
        className={classNames(
          'flex items-center justify-center w-6 h-6 stroke-current rounded-sm',
          disabled || !visible
            ? 'cursor-not-allowed text-gray-400 dark:text-[#6f717357]'
            : 'cursor-pointer text-[#6F7173] hover:text-black dark:hover:text-neon-green-300'
        )}
        icon={Focus}
        disabled={disabled || !visible}
        onClick={onFocusAnnotation}
      />
      <HideSelect onClick={onToggleHide} hidden={!visible} />
    </div>
  );
};
export const AnnotationsIconsWrapper = memo($AnnotationsIconsWrapper);
