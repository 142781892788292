import { Annotation } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { fixOrderIndices } from './fixOrderIndices';

export const removeGroup = (state: PointCloudCommandManagerState, { identifier }: { identifier: string }) => {
  const removedGroup = state.groups.find((group) => group.identifier === identifier);
  state.groups = state.groups.filter((group) => group.identifier !== identifier);
  const removedGroupAnnotations: Annotation[] = [];
  state.annotations = state.annotations.filter((annotation) => {
    if (annotation.groupIdentifier === identifier) return false;
    return true;
  });
  const groupAnnotationIdentifiers = removedGroupAnnotations.map((annotation) => annotation.identifier);
  const removedOrderedIdentifiers = state.orderedIdentifiers.filter(
    (orderInfo) => !(identifier !== orderInfo.identifier && !groupAnnotationIdentifiers.includes(orderInfo.identifier))
  );
  state.orderedIdentifiers = state.orderedIdentifiers.filter(
    (orderInfo) => identifier !== orderInfo.identifier && !groupAnnotationIdentifiers.includes(orderInfo.identifier)
  );
  fixOrderIndices(state);
  return { removedGroupAnnotations, removedOrderedIdentifiers, removedGroup };
};
