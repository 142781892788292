import { useT } from '../../translation/src';

export const useUserRoleTranslations = () => {
  const all = useT('all', { swc: true });
  const admin = useT('admin', { swc: true });
  const member = useT('member', { swc: true });
  const guest = useT('guest', { swc: true });

  return {
    all,
    admin,
    member,
    guest,
  } as { [key: string]: string };
};
