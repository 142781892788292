import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUserAction } from '../../../redux/store';

export const useLogin = () => {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const login = useCallback(
    async (values: { email: string; password: string; remember: boolean }) => {
      const response = await fetch(`${import.meta.env.VITE_NX_SERVER}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
        credentials: 'include',
      });
      if (response.status === 200) {
        // Make sure to start with fresh state
        dispatch(logoutUserAction({}));
        apolloClient.resetStore();
        Sentry.setUser({ email: values.email });
      }
      return response;
    },
    [apolloClient, dispatch]
  );

  return login;
};
