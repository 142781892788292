import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { EnumAppearanceTypes } from '../../potree/usePointCloudProperties';

type State = {
  resolution: number | undefined;
  appearance: EnumAppearanceTypes;
  gradient: string;
  classifications: { code: number; visible: boolean }[];
  heightMin: number | undefined;
  heightMax: number | undefined;
  intensityMin: number | undefined;
  intensityMax: number | undefined;
};
export const useDefaultPointCloudState = () => {
  const [defaultState, setDefaultState] = useLocalStorage<{ [key: string]: State }>('pcStates', {});

  const setDefaultStateForPointCloud = useCallback(
    (id: string, state: State) => {
      setDefaultState((prev) => ({ ...prev, [id]: state }));
    },
    [setDefaultState]
  );

  const setDefaultStateProperty = useCallback(
    (id: string, property: keyof State, value: State[keyof State]) => {
      setDefaultState((prev) => ({ ...prev, [id]: { ...(prev[id] || {}), [property]: value } }));
    },
    [setDefaultState]
  );

  return [defaultState, setDefaultState, { setDefaultStateForPointCloud, setDefaultStateProperty }] as const;
};
