import { AddGroupActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { addOrderedIdentifier } from '../helpers/addOrderedIdentifier';
import { removeGroup } from '../helpers/removeGroup';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class AddGroupAction implements IAction<AddGroupActionValue> {
  public identifier: string;
  constructor(public value: AddGroupActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    state.groups = state.groups.concat({ identifier: this.value.groupIdentifier, name: this.value.name });
    state.annotations = state.annotations.map((annotation) => {
      if (this.value.annotationIdentifiers.includes(annotation.identifier))
        return { ...annotation, groupIdentifier: this.value.groupIdentifier };
      return annotation;
    });
    addOrderedIdentifier(state, {
      identifier: this.value.groupIdentifier,
      orderedIdentifiersKey: 'orderedIdentifiers',
      itemIdentifierKey: 'annotations',
    });
  }
  undo(state: PointCloudCommandManagerState) {
    removeGroup(state, { identifier: this.value.groupIdentifier });
  }
}
