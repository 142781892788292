import * as togeojson from '@tmcw/togeojson';
import { InputCadObject, InputObjectGroup } from '../types/graphqlTypes';
import * as uuid from 'uuid';
import type { Geometry, Feature } from 'geojson';
import { isNotNullOrUndefined } from './isNullOrEmpty';
import hexToRgba from 'hex-rgb';

const isCADType = (type: string): type is CadObjectType => ['POINT', 'LINE', 'POLYGON'].includes(type);
const isPointGeometry = (geometry: Geometry): geometry is GeoJSON.Point => geometry.type === 'Point';
const isLineGeometry = (geometry: Geometry): geometry is GeoJSON.LineString => geometry.type === 'LineString';
const isPolygonGeometry = (geometry: Geometry): geometry is GeoJSON.Polygon => geometry.type === 'Polygon';

const COLOR_GOLD = { r: 255, g: 215, b: 0, a: 1 };

const parseColor = (color?: string | { r: number; g: number; b: number; a: number }) => {
  if (!color) return COLOR_GOLD;
  if (typeof color === 'string' && color.startsWith('#')) {
    const { red, green, blue, alpha } = hexToRgba(color);
    return { r: red, g: green, b: blue, a: alpha };
  }
  if (typeof color === 'string' && color.startsWith('rgb')) {
    const [r, g, b, a] = color
      .replace('rgb(', '')
      .replace('rgba(', '')
      .replace(')', '')
      .split(',')
      .map((v) => parseInt(v.trim()));
    return { r, g, b, a: a || 1 };
  }
  if (typeof color === 'string') throw new Error(`invalid color: ${color}`);
  return color;
};

class CADFileParser {
  file: File;
  cadLayerId: string;
  projectionSystem: string;
  constructor({ file, cadLayerId, projectionSystem }: { file: File; cadLayerId: string; projectionSystem: string }) {
    this.file = file;
    this.cadLayerId = cadLayerId;
    this.projectionSystem = projectionSystem;
  }
  private async getFileContent(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target?.result;
        if (!contents) return reject('file has no contents');
        if (typeof contents !== 'string') return reject('file contents is not a string');
        resolve(contents);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  protected parseCoordinates(coordinates: number[]) {
    const parsedCoordinates = proj4('EPSG:4326', this.projectionSystem, coordinates);
    return { x: parsedCoordinates[0], y: parsedCoordinates[1], z: parsedCoordinates[2] || 0 };
  }

  protected async getXMLDom(file: File) {
    const data = await this.getFileContent(file);

    const kmlDom = new DOMParser().parseFromString(data, 'text/xml');
    return kmlDom;
  }

  protected async getJSON(file: File) {
    const data = await this.getFileContent(file);

    return JSON.parse(data);
  }

  async parse(): Promise<InputObjectGroup[]> {
    throw new Error('not implemented');
  }
}

type CadObjectType = 'POINT' | 'LINE' | 'POLYGON';

export class KMLFileParser extends CADFileParser {
  private parseFeature({
    feature,
    cadObjectGroupId,
  }: {
    feature: Feature<Geometry | null>;
    cadObjectGroupId: string;
  }): InputCadObject | null {
    if (!feature.geometry) return null;
    if (!feature.geometry.type) return null;
    let CADType = feature.geometry.type.toUpperCase();
    if (CADType === 'LINESTRING') CADType = 'LINE';
    if (!isCADType(CADType)) return null;
    const commonValues = {
      identifier: uuid.v4(),
      cadLayerId: this.cadLayerId,
      type: CADType,
      name: feature.properties?.name || CADType,
      color: parseColor(feature.properties?.color),
      cadObjectGroupId,
    };
    if (isPointGeometry(feature.geometry)) {
      return {
        ...commonValues,
        type: 'POINT',
        position: this.parseCoordinates(feature.geometry.coordinates),
      };
    }
    if (isLineGeometry(feature.geometry)) {
      return {
        ...commonValues,
        type: 'LINE',
        points: feature.geometry.coordinates.map((coord) => this.parseCoordinates(coord)),
      };
    }
    if (isPolygonGeometry(feature.geometry)) {
      return {
        ...commonValues,
        type: 'POLYGON',
        points: feature.geometry.coordinates[0].map((coord) => this.parseCoordinates(coord)),
      };
    }
    return null;
  }

  private parseFolder({ folder }: { folder: togeojson.Folder }): InputObjectGroup | null {
    const folderId = uuid.v4();
    const folderObject = {
      identifier: folderId,
      cadLayerId: this.cadLayerId,
      name: typeof folder.meta.name === 'string' ? folder.meta.name : 'Folder',
    };
    const cadObjects = folder.children
      .map((child) => {
        if (child.type === 'folder') {
          return this.parseFolder({ folder: child })?.cadObjects;
        }
        return this.parseFeature({ feature: child, cadObjectGroupId: folderId });
      })
      .flat()
      .filter(isNotNullOrUndefined);
    return { ...folderObject, cadObjects };
  }

  async parse() {
    const kmlDom = await this.getXMLDom(this.file);
    const geojson = togeojson.kmlWithFolders(kmlDom);

    let cadObjectGroups: InputObjectGroup[] = [
      {
        identifier: uuid.v4(),
        cadLayerId: this.cadLayerId,
        name: 'Root',
        cadObjects: [],
      },
    ];

    geojson.children.forEach((child) => {
      if (child.type === 'folder') {
        const group = this.parseFolder({ folder: child });
        if (group) cadObjectGroups.push(group);
        return;
      }
      const cadObject = this.parseFeature({ feature: child, cadObjectGroupId: cadObjectGroups[0].identifier });
      if (cadObject) cadObjectGroups[0].cadObjects.push(cadObject);
    });

    if (!cadObjectGroups[0].cadObjects.length) cadObjectGroups = cadObjectGroups.slice(1);

    return cadObjectGroups;
  }
}

const isGeoJSON = (json: any): json is GeoJSON.FeatureCollection => {
  if (json.type !== 'FeatureCollection') return false;
  if (!Array.isArray(json.features)) return false;
  return true;
};
export class GeoJSONParser extends CADFileParser {
  private parseFeature({
    feature,
    cadObjectGroupId,
  }: {
    feature: Feature<Geometry | null>;
    cadObjectGroupId: string;
  }): InputCadObject | null {
    if (!feature.geometry) return null;
    if (!feature.geometry.type) return null;
    let CADType = feature.geometry.type.toUpperCase();
    if (CADType === 'LINESTRING') CADType = 'LINE';
    if (!isCADType(CADType)) return null;
    const commonValues = {
      identifier: uuid.v4(),
      cadLayerId: this.cadLayerId,
      type: CADType,
      name: feature.properties?.name || CADType,
      color: feature.properties?.color || COLOR_GOLD,
      cadObjectGroupId,
    };
    if (isPointGeometry(feature.geometry)) {
      return {
        ...commonValues,
        type: 'POINT',
        position: this.parseCoordinates(feature.geometry.coordinates),
      };
    }
    if (isLineGeometry(feature.geometry)) {
      return {
        ...commonValues,
        type: 'LINE',
        points: feature.geometry.coordinates.map((coord) => this.parseCoordinates(coord)),
      };
    }
    if (isPolygonGeometry(feature.geometry)) {
      return {
        ...commonValues,
        type: 'POLYGON',
        points: feature.geometry.coordinates[0].map((coord) => this.parseCoordinates(coord)),
      };
    }
    return null;
  }

  async parse() {
    const json = await this.getJSON(this.file);
    if (!isGeoJSON(json)) throw new Error('invalid GeoJSON');

    const cadObjectGroups: InputObjectGroup[] = [
      {
        identifier: uuid.v4(),
        cadLayerId: this.cadLayerId,
        name: 'Root',
        cadObjects: [],
      },
    ];

    json.features.forEach((child) => {
      const cadObject = this.parseFeature({ feature: child, cadObjectGroupId: cadObjectGroups[0].identifier });
      if (cadObject) cadObjectGroups[0].cadObjects.push(cadObject);
    });

    return cadObjectGroups;
  }
}
