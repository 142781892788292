import { useCallback, useState } from 'react';

const useOpen = <Value extends any | undefined = any>(
  state = false,
  { stopPropagationOnClick } = { stopPropagationOnClick: false }
) => {
  const [open, setIsOpen] = useState(state);
  const [value, setValue] = useState<Value>();
  const onOpen = useCallback(
    (event?: any) => {
      if (stopPropagationOnClick) {
        event.preventDefault();
        event.stopPropagation();
      }
      setIsOpen(true);
    },
    [stopPropagationOnClick]
  );
  const onOpenWithValue = useCallback((...args: Value extends any ? [] | [arg1: any] : [arg1: Value]) => {
    const value = args[0];
    setIsOpen(true);
    setValue(value);
  }, []);
  const onClose = useCallback(() => {
    setIsOpen(false);
    setValue(undefined);
  }, []);

  return { open, onOpen, onClose, value, onOpenWithValue };
};

export default useOpen;
