import { Annotation, AnnotationGroup, UnGroupActionValue } from '@pointorama/database';
import { keyBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { fixOrderIndices } from '../helpers/fixOrderIndices';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class UnGroupAction implements IAction<UnGroupActionValue> {
  public identifier: string;
  private _previousIndices: { [key: string]: number } = {};
  private _movedAnnotations: Annotation[] = [];
  private _removedGroup: AnnotationGroup | undefined | null;
  constructor(public value: UnGroupActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotationsByIdentifier = keyBy(state.annotations, 'identifier');
    const currentOrderedIdentifiers = state.orderedIdentifiers
      .filter((value) => {
        return annotationsByIdentifier[value.identifier]?.groupIdentifier !== this.value.groupIdentifier;
      })
      .map((value) => value.index);
    let annotationIndex = currentOrderedIdentifiers.length ? Math.max(...currentOrderedIdentifiers) + 1 : 0;
    const annotations = state.annotations.filter(
      (annotation) => annotationsByIdentifier[annotation.identifier]?.groupIdentifier === this.value.groupIdentifier
    );
    annotations.forEach((annotation) => {
      const orderedIdentifier = state.orderedIdentifiers.find((value) => value.identifier === annotation.identifier);
      if (!orderedIdentifier) return;
      this._previousIndices[annotation.identifier] = orderedIdentifier.index;
      this._movedAnnotations.push(annotation);
      annotation.groupIdentifier = undefined;
      orderedIdentifier.index = annotationIndex;
      annotationIndex++;
    });
    this._removedGroup = state.groups.find((group) => group.identifier === this.value.groupIdentifier);
    state.groups = state.groups.filter((group) => group.identifier !== this.value.groupIdentifier);
    fixOrderIndices(state);
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedGroup) return;
    const movedAnnotationIdentifiers = this._movedAnnotations.map((annotation) => annotation.identifier);
    state.annotations = state.annotations.map((annotation) => {
      if (!movedAnnotationIdentifiers.includes(annotation.identifier)) return annotation;
      return { ...annotation, groupIdentifier: this._removedGroup?.identifier };
    });
    state.groups.push(this._removedGroup);
    state.orderedIdentifiers = state.orderedIdentifiers.map((orderedIdentifier) => {
      if (!this._previousIndices[orderedIdentifier.identifier]) return orderedIdentifier;
      return { ...orderedIdentifier, index: this._previousIndices[orderedIdentifier.identifier] };
    });
    fixOrderIndices(state);
    (this._previousIndices = {}), (this._movedAnnotations = []), (this._removedGroup = undefined);
  }
}
