import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSelectedWmsItem } from '../../redux/rendererReducer';

type onClickWmsItemFn = (args1: { identifier: string }) => void;

export const useSelectedWmsItem = () => {
  const dispatch = useDispatch();

  const onClickWmsItem: onClickWmsItemFn = useCallback(
    (selectedWmsItem) => dispatch(toggleSelectedWmsItem({ ...selectedWmsItem })),
    [dispatch]
  );
  return { onClickWmsItem };
};
