import { Annotation, AnnotationGroup } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';

export const undoGroupAction = (
  {
    removedOrderedIdentifiers,
    removedAnnotations,
    removedGroup,
  }: {
    removedOrderedIdentifiers: { identifier: string; index: number }[];
    removedAnnotations: Annotation[];
    removedGroup: AnnotationGroup;
  },
  { state }: { state: PointCloudCommandManagerState }
) => {
  state.groups = state.groups.concat(removedGroup);
  state.annotations = state.annotations.concat(removedAnnotations);
  state.orderedIdentifiers = state.orderedIdentifiers.concat(...removedOrderedIdentifiers);
};
