import { useMemo } from 'react';

const useQueryParams = <
  Key extends string = string,
  Types extends { [key in Key]: 'string' | 'number' | undefined } = { [key in Key]: 'string' | 'number' | undefined }
>(
  keys: Key[],
  options?: { types?: Types }
): { [key in Key]?: Types[key] extends 'number' ? number : string } => {
  const search = window.location.search;
  const result = useMemo(() => {
    const params = new URLSearchParams(search);
    const result: any = {};
    keys.forEach((key) => {
      const value = params.get(key);
      if (value === undefined || value === null) return (result[key] = undefined);
      let parsedValue: any = value;
      const type = options?.types && options.types[key];
      if (type === 'number') parsedValue = +value;
      result[key] = parsedValue;
      return;
    });
    return result;
  }, [search, keys, options?.types]);

  return result;
};

export default useQueryParams;
