import { Popover as HSPopover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { EventEmitter } from 'eventemitter3';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { v4 as uuid } from 'uuid';
const emitter = new EventEmitter();

export const CONTEXT_MENU_TRANSITION_LEAVE_TIME = 150;
interface ContextMenuProps {
  buttonChild: React.ReactNode;
  buttonClassName?: string;
  panelClassName?: string;
  popoverMenuDisabled?: boolean;
}
const $ContextMenu: React.FC2<ContextMenuProps> = ({
  buttonChild,
  buttonClassName,
  panelClassName,
  children,
  className,
  popoverMenuDisabled,
  ...props
}) => {
  const id = useMemo(() => uuid(), []);
  const containerRef = useRef(null);
  const [state, setState] = useState<{ show: boolean }>({ show: false });
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }],
  });
  const onClose = useCallback(() => setState((state) => ({ ...state, show: false })), []);

  const onCloseRemainingContextMenus = useCallback(
    (clickedId: string) => {
      if (clickedId !== id) onClose();
    },
    [id, onClose]
  );
  useEffect(() => {
    emitter.on('CLOSE_REMAINING_CONTEXT_MENUS', onCloseRemainingContextMenus);
    return () => {
      emitter.off('CLOSE_REMAINING_CONTEXT_MENUS', onCloseRemainingContextMenus);
    };
  }, [id, onCloseRemainingContextMenus]);
  useEffect(() => {
    window.addEventListener('click', onClose);
    return () => {
      window.removeEventListener('click', onClose);
    };
  }, [onClose]);

  if (popoverMenuDisabled)
    return (
      <div
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {buttonChild}
      </div>
    );

  return (
    <HSPopover className={className} {...props} ref={containerRef}>
      <>
        <div
          ref={setReferenceElement}
          onContextMenu={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setState({ show: true });
            emitter.emit('CLOSE_REMAINING_CONTEXT_MENUS', id);
          }}
          className={buttonClassName}
        >
          {buttonChild}
        </div>
        <Transition
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave={`transition ease-in duration-${CONTEXT_MENU_TRANSITION_LEAVE_TIME}`}
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={state.show}
        >
          <HSPopover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={classNames(
              panelClassName,
              'absolute z-20 bg-white dark:bg-[#242424] p-2 rounded-md flex flex-col w-48 border border-gray-300 shadow-sm'
            )}
            onClick={onClose}
          >
            {children}
          </HSPopover.Panel>
        </Transition>
      </>
    </HSPopover>
  );
};

export const ContextMenu = memo($ContextMenu);
