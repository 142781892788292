import {
  ClipMethods,
  PointCluster,
  useProjectUpdateClippingMethodMutation,
  useProjectEditAnnotationClassMutation,
} from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { T } from '../../../translation/src';
import { memo, useCallback } from 'react';
import { ClipMethodProperty } from './ClipMethodProperties';
import { SelectProps } from '../../../components/inputs/Select';
import { ClassifyProperty } from './ClassificationProperties';
import { IClassification } from '../../../hooks/potree/useRenderer';

interface PointClusterPropertiesProps {
  projectId: string;
  pointCluster: PointCluster;
}

const $PointClusterProperties: React.FC2<PointClusterPropertiesProps> = ({ projectId, pointCluster }) => {
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();
  const [editAnnotationClass] = useProjectEditAnnotationClassMutation();

  const clipMethod = pointCluster.annotationFilter?.clipMethod || ClipMethods.None;
  const onChangeClippingMethod: SelectProps<ClipMethods>['onChange'] = useCallback(
    (event) => {
      updateClippingMethod({
        variables: { projectId, annotationIdentifiers: [pointCluster.identifier], clipMethod: event.target.value },
      });
    },
    [updateClippingMethod, projectId, pointCluster]
  );

  const classification = pointCluster.annotationClass || 0;
  const onChangeClassification: SelectProps<IClassification>['onChange'] = useCallback(
    (event) => {
      editAnnotationClass({
        variables: {
          annotationIds: [pointCluster.identifier],
          projectId: projectId,
          newClass: Number(event.target.value.code),
        },
      });
    },
    [pointCluster.identifier, editAnnotationClass, projectId]
  );

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="point cluster" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="filters" swc />}>
        <ClipMethodProperty onChange={onChangeClippingMethod} clipMethod={clipMethod}></ClipMethodProperty>
        <ClassifyProperty
          onChange={onChangeClassification}
          classification={classification}
          projectId={projectId}
        ></ClassifyProperty>
      </PropertiesSection>
    </div>
  );
};
export const PointClusterProperties = memo($PointClusterProperties);
