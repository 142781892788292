import { useMemo } from 'react';
import { useT } from '../../translation/src';

export const useSubscriptionTypeTranslations = () => {
  const FREE = useT('free', { swc: true });
  const LAUNCH = useT('launch', { swc: true });
  const GROW = useT('grow', { swc: true });
  const SCALE = useT('scale', { swc: true });
  const CUSTOM = useT('custom', { swc: true });

  const translations = useMemo(() => {
    return { FREE, LAUNCH, GROW, SCALE, CUSTOM };
  }, [FREE, LAUNCH, GROW, SCALE, CUSTOM]);

  return translations;
};
