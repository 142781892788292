import { Body } from './Body';
import { Container } from './Container';
import { Footer } from './Footer';
import { Header } from './Header';

export const Modal = {
  Container,
  Footer,
  Body,
  Header,
};
