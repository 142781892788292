import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
const stripePromise = loadStripe(`${import.meta.env.VITE_STRIPE_TOKEN}`, { locale: 'en' });

export const useStripeModule = () => {
  return stripePromise;
};

export const useStripeAppearance = () => {
  const currentUser = useContext(UserContext);
  return {
    theme: currentUser.darkMode ? 'night' : 'stripe',
    rules: {
      '.Input': {
        boxShadow: 'none',
        border: currentUser.darkMode ? '1px solid rgb(209,213,219)' : '1px solid #C2C6C8',
      },
      '.Input--focus, .Input:focus': {
        boxShadow: 'none',
        border: currentUser.darkMode ? '1px solid #B5FFA1' : '1px solid #C2C6C8',
        borderColor: currentUser.darkMode ? '#B5FFA1' : '#C2C6C8',
      },
    },
    variables: {
      fontFamily: 'IBM Plex Sans, ui-sans-serif, sans-serif',
      colorText: currentUser.darkMode ? 'white' : 'black',
      colorBackground: currentUser.darkMode ? '#313131' : 'white',
      fontLineHeight: '1rem',
      focusBoxShadow: '',
      focusOutline: '',
    },
  } as StripeElementsOptions['appearance'];
};
