import { AnnotationContextGroup, Annotations as TypeAnnotations } from '../../../../../contexts/AnnotationContext';

export type AnnotationsWithIndicesGroup = Omit<AnnotationContextGroup, 'annotations'> & {
  annotations: (AnnotationContextGroup['annotations'][0] & { index: number })[];
};
export type AnnotationsWithIndices = ((AnnotationsWithIndicesGroup | TypeAnnotations[0]) & { index: number })[];

const getAnnotationByIndex = ({
  annotations,
  index,
  rootIndex,
}: {
  annotations: AnnotationsWithIndices;
  index: number;
  rootIndex?: number;
}) => {
  let result: {
    identifier: string;
    groupIdentifier?: string;
    type: 'annotation' | 'group';
    isLastOfGroup?: boolean;
    rootIndex?: number;
  } = {
    identifier: '',
    type: 'annotation',
  };
  annotations.some((annotation, annotationIndex) => {
    if (annotation.__typename !== 'AnnotationGroup') {
      if (annotation.index === index) {
        result = {
          identifier: annotation.identifier,
          groupIdentifier: (annotation as any).groupIdentifier,
          type: 'annotation',
          isLastOfGroup: (annotation as any).groupIdentifier && annotationIndex === annotations.length - 1,
          rootIndex,
        };
        return true;
      }
      return false;
    }
    if (annotation.index === index) {
      result = { identifier: annotation.identifier, groupIdentifier: undefined, type: 'group' };
      return true;
    }
    if (annotation.__typename === 'AnnotationGroup') {
      result = getAnnotationByIndex({ annotations: annotation.annotations, index, rootIndex: annotationIndex });
      if (result.identifier) return true;
    }
    return false;
  });
  return result;
};

export const getConvertedIndices = ({
  result,
  annotations,
  selectedIdentifiers,
}: {
  result: {
    draggableId: string;
    destination?: { index?: number | null } | null;
    source?: { index?: number | null } | null;
  };
  annotations: AnnotationsWithIndices;
  selectedIdentifiers: { type: string; identifier: string }[];
}) => {
  const [__, _, fromGroupIdentifier] = result.draggableId.split('_');
  const destinationIndex = result.destination?.index || 0;
  const destinationAnnotation = getAnnotationByIndex({ annotations, index: destinationIndex });
  const annotationBeforeDestinationIndex = getAnnotationByIndex({ annotations, index: destinationIndex - 1 });
  const sourceIndex = result.source?.index || 0;
  let toGroupIdentifier: string | undefined;
  let toIndex = Number.MAX_SAFE_INTEGER;
  const fromRootToGroup =
    !fromGroupIdentifier &&
    ((destinationAnnotation?.groupIdentifier &&
      (!destinationAnnotation.isLastOfGroup || destinationIndex < sourceIndex)) ||
      (destinationIndex > sourceIndex && destinationAnnotation.type === 'group'));
  const fromRootToRoot =
    !fromGroupIdentifier &&
    (!destinationAnnotation?.groupIdentifier || destinationAnnotation.isLastOfGroup) &&
    !fromRootToGroup;
  const fromGroupToSameGroup = fromGroupIdentifier === destinationAnnotation?.groupIdentifier;
  const fromGroupToGroupCondition1 =
    destinationAnnotation?.groupIdentifier && fromGroupIdentifier !== destinationAnnotation?.groupIdentifier;
  const fromGroupToGroupCondition2 =
    destinationAnnotation.type === 'group' &&
    destinationAnnotation.identifier !== fromGroupIdentifier &&
    destinationIndex > sourceIndex;
  const fromGroupToDifferentGroupCondition3 =
    destinationIndex < sourceIndex &&
    annotationBeforeDestinationIndex?.groupIdentifier &&
    annotationBeforeDestinationIndex?.groupIdentifier !== fromGroupIdentifier;
  const fromGroupToDifferentGroup =
    fromGroupIdentifier &&
    (fromGroupToGroupCondition1 || fromGroupToGroupCondition2 || fromGroupToDifferentGroupCondition3);
  const fromGroupToRoot = fromGroupIdentifier && !destinationAnnotation?.groupIdentifier;

  if (fromRootToGroup && selectedIdentifiers.some(({ type }) => type === 'group')) throw new Error('NOT_SUPPORTED');

  if (fromRootToRoot) {
    if (destinationAnnotation.isLastOfGroup) toIndex = destinationAnnotation.rootIndex || 0;
    else
      annotations.some((annotation, rootIndex) => {
        if (annotation.index === destinationIndex) {
          toIndex = rootIndex;
          return true;
        }
        return false;
      });
  } else if (fromGroupToSameGroup || fromGroupToDifferentGroup) {
    if (fromGroupToDifferentGroup && destinationIndex < sourceIndex && annotationBeforeDestinationIndex.isLastOfGroup) {
      toGroupIdentifier = annotationBeforeDestinationIndex?.groupIdentifier;
      const groupAnnotations = (annotations.find((annotation) => annotation.identifier === toGroupIdentifier) as any)
        ?.annotations;
      toIndex = groupAnnotations.length;
    } else {
      toGroupIdentifier = fromGroupIdentifier;
      if (fromGroupToDifferentGroup || fromRootToGroup)
        toGroupIdentifier = destinationAnnotation?.groupIdentifier || destinationAnnotation.identifier;
      annotations.some((annotation) => {
        if (annotation.__typename !== 'AnnotationGroup') return false;
        if (annotation.index === destinationIndex) {
          toIndex = 0;
          return true;
        }
        return annotation.annotations.some((annotation, childIndex) => {
          if (annotation.index === destinationIndex) {
            if (fromGroupToSameGroup) toIndex = childIndex;
            else toIndex = childIndex + (destinationIndex > sourceIndex ? 1 : 0);
            return true;
          }
          return false;
        });
      });
    }
  } else if (fromRootToGroup) {
    toGroupIdentifier = destinationAnnotation?.groupIdentifier || destinationAnnotation.identifier;
    if (!destinationAnnotation.groupIdentifier) {
      toIndex = 0;
    } else {
      annotations.some((annotation) => {
        if (annotation.__typename !== 'AnnotationGroup') return false;
        if (annotation.index === destinationIndex) {
          toIndex = 0;
          return true;
        }
        return annotation.annotations.some((annotation, childIndex) => {
          if (annotation.index === destinationIndex) {
            toIndex = childIndex + (destinationIndex > sourceIndex ? 1 : 0);
            return true;
          }
          return false;
        });
      });
    }
  } else if (fromGroupToRoot) {
    annotations.some((annotation, rootIndex) => {
      if (annotation.index === destinationIndex) {
        toIndex = rootIndex;
        return true;
      }
      return false;
    });
  }
  console.log({ toGroupIdentifier, toIndex });

  return { toIndex, toGroupIdentifier };
};
