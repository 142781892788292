import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { SelectItemContent } from '../../../components/selects/SelectItem';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { T } from '../../../translation/src';

export const useUpdatePosition = ({
  annotationIdentifier,
  groupIdentifier,
  downDisabled,
  upDisabled,
}: {
  annotationIdentifier?: string;
  groupIdentifier?: string;
  downDisabled?: boolean;
  upDisabled?: boolean;
}) => {
  const [executeAction] = useExecuteAction();

  const UpdatePositionItems = useCallback(
    ({ index }: { index: number }) => {
      const onMoveUp = () =>
        executeAction({
          type: 'UPDATE_POSITION',
          action: {
            toIndex: index - 1,
            identifiers: [
              {
                identifier: annotationIdentifier || groupIdentifier || '',
                type: annotationIdentifier ? 'annotation' : 'group',
              },
            ],
          },
        });
      const onMoveDown = () =>
        executeAction({
          type: 'UPDATE_POSITION',
          action: {
            toIndex: index + 1,
            identifiers: [
              {
                identifier: annotationIdentifier || groupIdentifier || '',
                type: annotationIdentifier ? 'annotation' : 'group',
              },
            ],
          },
        });
      return (
        <>
          <SelectItemContent icon={ArrowUpIcon} onClick={onMoveUp} disabled={upDisabled}>
            <T _str="move up" swc />
          </SelectItemContent>
          <SelectItemContent icon={ArrowDownIcon} onClick={onMoveDown} disabled={downDisabled}>
            <T _str="move down" swc />
          </SelectItemContent>
        </>
      );
    },
    [annotationIdentifier, downDisabled, executeAction, groupIdentifier, upDisabled]
  );

  return [{ UpdatePositionItems }];
};
