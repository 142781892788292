import { useParams } from 'react-router-dom';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { BillingHistory } from '../../modules/billing/BillingHistory';
import { T } from '../../translation/src';
import { useBillingInvoicesQuery } from '../../types/graphqlTypes';

export const PaymentHistory: React.FC2 = () => {
  const { organisationId = '' } = useParams();
  const { loading: billingInvoicesLoading } = useBillingInvoicesQuery({ variables: { organisationId } });
  return (
    <div className="flex flex-col w-full h-full pb-4 overflow-auto px-14 dark:bg-[#313131] dark:text-white">
      <div className="z-20 w-full pt-8 text-xl">
        <div>
          <T _str="billing history" swc />
        </div>
      </div>
      <div className="flex w-full h-full space-y-10 overflow-auto">
        {billingInvoicesLoading ? (
          <EarthLoader />
        ) : (
          <div className="flex flex-col w-full pt-4 overflow-auto">
            <BillingHistory />
          </div>
        )}
      </div>
    </div>
  );
};