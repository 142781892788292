import { Annotation } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { addOrderedIdentifier } from './addOrderedIdentifier';

export const undoDeleteAnnotation = (
  { removedAnnotation, removedAnnotationIndex }: { removedAnnotation: Annotation; removedAnnotationIndex: number },
  { state }: { state: PointCloudCommandManagerState }
) => {
  state.annotations = state.annotations.concat(removedAnnotation);
  addOrderedIdentifier(state, {
    groupIdentifier: removedAnnotation.groupIdentifier,
    identifier: removedAnnotation.identifier,
    insertAtIndex: removedAnnotationIndex,
    itemIdentifierKey: 'annotations',
    orderedIdentifiersKey: 'orderedIdentifiers',
  });
};
