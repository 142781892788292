import { createContext } from 'react';
import { AnnotationGroup, ProjectByIdQuery } from '../types/graphqlTypes';

// @TOOD: replace by useFragment when not experimental anymore https://www.apollographql.com/docs/react/api/react/hooks-experimental
export type Annotations = ProjectByIdQuery['projectById']['state']['annotations'];
export type Calculations = ProjectByIdQuery['projectById']['calculations'];
export type VolumeCalculation = Extract<Required<Calculations[0]>, { __typename: 'VolumeCalculation' }>;
export type ProjectionCalculation = Extract<Required<Calculations[0]>, { __typename: 'ProjectionCalculation' }>;
export type AnnotationContextGroup = AnnotationGroup & {
  annotations: Annotations;
};
export type AnnotationContextType = {
  annotations: (AnnotationContextGroup | Annotations[0])[];
  annotationsByIdentifier: { [key: string]: Annotations[0] };
  calculationsByIdentifier: { [key: string]: Calculations };
};
export const AnnotationContext = createContext<AnnotationContextType>({
  annotations: [],
  annotationsByIdentifier: {},
  calculationsByIdentifier: {},
});
