import { AddAdditionalPointActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class AddAdditionalPointAction implements IAction<AddAdditionalPointActionValue> {
  public identifier: string;
  _insertedAtIndex: number | undefined;
  constructor(public value: AddAdditionalPointActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation) return;
    if (annotation.type === 'POINT') return;
    else if (annotation.type === 'AREA' || annotation.type === 'DISTANCE') {
      annotation.points.splice(this.value.pointIndex, 0, {pointCloudId: this.value.pointCloudId, position: this.value.position});
      this._insertedAtIndex = this.value.pointIndex;
    }
    return;
  }
  undo(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier
    );
    if (!annotation) return;
    if (!this._insertedAtIndex) return;
    if (annotation.type === 'POINT') return;
    else if (annotation.type === 'AREA' || annotation.type === 'DISTANCE')
      return (annotation.points.splice(this._insertedAtIndex, 1));
    this._insertedAtIndex = undefined;
    return;
  }
}
